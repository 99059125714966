import {
  ALL_PRODUCT_REQUIEST,
  ALL_PRODUCT_SUCCESS,
  ALL_PRODUCT_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_REQUIEST,
  PRODUCT_DETAILS_FAIL,
  ADMIN_PRODUCT_REQUIEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  CLEAR_ERROR,
  // REVIEW_REQUIEST,
  // REVIEW_SUCCESS,
  // REVIEW_FAIL,
  // REVIEW_RESET,
  CREATE_NEW_PRODUCT_REQUIEST,
  CREATE_NEW_PRODUCT_SUCCESS,
  CREATE_NEW_PRODUCT_RESET,
  CREATE_NEW_PRODUCT_FAIL,
  PRODUCT_DELETE_REQUIEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_RESET,
  GET_PRODUCT_BY_SLUG_DETAILS_REQUIEST,
  GET_PRODUCT_BY_SLUG_DETAILS_SUCCESS,
  GET_PRODUCT_BY_SLUG_DETAILS_FAIL,
  UPDATE_PRODUCT_REQUIEST,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  UPDATE_PRODUCT_SUCCESS,
  PRODUCT_WISHLIST_REQUEST,
  PRODUCT_WISHLIST_FAIL,
  PRODUCT_WISHLIST_SUCCESS,
  DELETE_PRODUCT_WISHLIST_REQUEST,
  DELETE_PRODUCT_WISHLIST_SUCCESS,
  DELETE_PRODUCT_WISHLIST_FAIL,
  PRODUCT_WISHLIST_RESET,
  DELETE_PRODUCT_WISHLIST_RESET,
  SEARCH_PRODUCT_REQUEST,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAIL,
  LIKE_PRODUCT_REVIEW_REQUEST,
  LIKE_PRODUCT_REVIEW_SUCEESS,
  LIKE_PRODUCT_REVIEW_FAIL,
  DISLIKE_PRODUCT_REVIEW_REQUEST,
  DISLIKE_PRODUCT_REVIEW_SUCEESS,
  DISLIKE_PRODUCT_REVIEW_FAIL,
  // GET_REVIEW_BY_ID_SUCCESS,
  // GET_REVIEW_BY_ID_REQUEST,
  // GET_REVIEW_BY_ID_FAIL,
  // GET_ALL_PRODUCTS_REVIEWS_REQUEST,
  // GET_ALL_PRODUCTS_REVIEWS_SUCCESS,
  // GET_ALL_PRODUCTS_REVIEWS_FAIL,
  // REVIEW_VERIFY_REQUEST,
  // REVIEW_VERIFY_SUCCESS,
  // REVIEW_VERIFY_FAIL,
  FILTER_PRODUCT_REQUIEST,
  FILTER_PRODUCT_SUCCESS,
  FILTER_PRODUCT_FAIL,
} from "../constants/productConstant";

export const productReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case ALL_PRODUCT_REQUIEST:
    case ADMIN_PRODUCT_REQUIEST:
      case FILTER_PRODUCT_REQUIEST:
      return {
        ...state,
        loading: true,
        products: [],
      };

    case ALL_PRODUCT_SUCCESS:
      const priceFilter = action.payload.products.map((item) => item.price);
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        productsCount: action.payload.productsCount,
        filterProduct: action.payload.filterProductCount,
        priceFilter: priceFilter,
      };
    case ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
      };

      case FILTER_PRODUCT_SUCCESS:
        return{
          ...state,
          loading: false,
          filterProducts: action.payload
        }

    case ALL_PRODUCT_FAIL:
    case ADMIN_PRODUCT_FAIL:
      case FILTER_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


// cleate new product ----Admin
export const createProductReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case CREATE_NEW_PRODUCT_REQUIEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_NEW_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        product: action.payload.product,
      };

    case CREATE_NEW_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_NEW_PRODUCT_RESET:
      return {
        ...state,
        success: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUIEST:
      return {
        loading: true,
        ...state,
      };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        product: action.payload.product,
      };

    case PRODUCT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// search product
export const getSearchProductsReducers = (
  state = {
    loading: false,
    searchProduct: [],
    priceFilter: [],
    // filterProduct: 0,
    productCount: 0,
    resultPerPage: 20,
  },
  action
) => {
  switch (action.type) {
    case SEARCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_PRODUCT_SUCCESS:
      // let searchProductArray = [];

      // if (Array.isArray(action.payload.searchProduct)) {
      //   // Check if searchProduct is an array
      //   searchProductArray = action.payload.searchProduct;
      // }

      // const priceFilter = searchProductArray.map((item) => item.price);
      return {
        ...state,
        loading: false,
        searchProduct: action.payload || [], // Ensure it's an array
        productsCount: action.payload.productCount || 0,
        resultPerPage: action.payload,
        // filterProduct: action.payload.filterProductCount || 0,
        priceFilter: action.payload.searchProduct
          ? action.payload.searchProduct.map((item) => item.price)
          : [],
      };
    case SEARCH_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// get product slug
export const getAllProductBySlug = (state = { product: [] }, action) => {
  switch (action.type) {
    case GET_PRODUCT_BY_SLUG_DETAILS_REQUIEST:
      return {
        loading: true,
        ...state,
      };
    case GET_PRODUCT_BY_SLUG_DETAILS_SUCCESS:
      return {
        loading: false,
        product: action.payload.product,
        productByPrice: action.payload.productByPrice,
      };

    case GET_PRODUCT_BY_SLUG_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// export const reviewReducers = (
//   state = { reviews: null, loading: false, error: null, success: false },
//   action
// ) => {
//   switch (action.type) {
//     case REVIEW_REQUIEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case REVIEW_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         success: action.payload,
//       };
//     case REVIEW_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };

//     case REVIEW_RESET:
//       return {
//         ...state,
//         success: false,
//       };

//     case CLEAR_ERROR:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };

// export const getAllProductReview = (state = {reviews:[],loading:false,error:null}, action) => {
//   switch (action.type) {
//     // case GET_ALL_PRODUCTS_REVIEWS_REQUEST:
//     //   return {
//     //     ...state,
//     //     loading: true,
//     //   };

//     case GET_ALL_PRODUCTS_REVIEWS_SUCCESS:
//       // console.log("GET_ALL_PRODUCTS_REVIEWS_SUCCESS", action.payload);
//       return {
//         ...state,
//         loading: false,
//         reviews: action.payload,
//         error: null
//       };
//     case GET_ALL_PRODUCTS_REVIEWS_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export const getReviewByIdReducers = (state = { review: [] }, action) => {
//   switch (action.type) {
//     case GET_REVIEW_BY_ID_REQUEST:
//       case REVIEW_VERIFY_REQUEST:
//       return {
//         loading: true,
//         ...state,
//       };
//     case GET_REVIEW_BY_ID_SUCCESS:
//       return {
//         loading: false,
//         review: action.payload,
//       };

//       case REVIEW_VERIFY_SUCCESS:
//         return{
//           ...state,
//           loading: false,
//           success: action.payload
//         }

//     case GET_REVIEW_BY_ID_FAIL:
//       case REVIEW_VERIFY_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };

//     case CLEAR_ERROR:
//       return {
//         ...state,
//         error: null,
//       };

//     default:
//       return state;
//   }
// };

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUIEST:
    case UPDATE_PRODUCT_REQUIEST:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

    case PRODUCT_DELETE_FAIL:
    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCT_DELETE_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case UPDATE_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    default:
      return state;
  }
};

export const likeDislikeProduct = (
  state = {
    loading: false,
    liked: false,
    disliked: false,
    error: null,
    likeColor: "#FF6347",
    dislikeColor: "#6495ED",
  },
  action
) => {
  switch (action.type) {
    case LIKE_PRODUCT_REVIEW_REQUEST:
    case DISLIKE_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIKE_PRODUCT_REVIEW_SUCEESS:
      return {
        ...state,
        loading: false,
        liked: true,
        disliked: false,
        error: null,
      };
    case DISLIKE_PRODUCT_REVIEW_SUCEESS:
      return {
        ...state,
        loading: false,
        liked: false,
        disliked: true,
        error: null,
      };

    case LIKE_PRODUCT_REVIEW_FAIL:
    case DISLIKE_PRODUCT_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// export const previousSearchSave = (state = {previousSearch: {}},action) => {
//   switch (action.type) {
//     case ADD_PREVIOUS_SEARCH:
//        return {
//         ...state,
//         previousSearch:action.payload
//        }
//     default:
//       return state
//   }
// }

export const wishListForProduct = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_WISHLIST_REQUEST:
    case DELETE_PRODUCT_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        wishList: action.payload,
      };

    case DELETE_PRODUCT_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case PRODUCT_WISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCT_WISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case PRODUCT_WISHLIST_RESET:
      return {
        ...state,
        wishList: false,
      };

    case DELETE_PRODUCT_WISHLIST_RESET:
      return {
        ...state,
        wishList: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
