import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router";

const ProtectedRoutes = ({ children }) => {
  let { loading, isAuthenticated=true } = useSelector((state) => state.user);
  // if(JSON.parse(localStorage.getItem('user_info')).isAuthenticated) {
  //   isAuthenticated = JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  // console.log(isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  }
  return <Fragment>{!loading && children ? children : <Outlet />}</Fragment>;


     
};

export default ProtectedRoutes;
