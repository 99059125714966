import React, { useEffect, useState } from "react";
import { clearErrors, orderDetailsById, upadteOrders } from "../../actions/orderActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { UPDATE_ORDERS_RESET } from "../../constants/orderConstant";

const OrderUpdate = () => {
    const [status, setStatus] = useState("");
    const {loading, order } = useSelector((state) => state.orderDetails);
    const { error: updateError, isUpdate } = useSelector((state) => state.order);
    console.log(order);
    const dispatch = useDispatch();
    const { id } = useParams();
    // console.log(id)
  
    const onHandleStatus = (e) => {
      e.preventDefault();
      // const myUpdateForm = new FormData();
      // myUpdateForm.append("status", status);
      const currentDate = new Date().toISOString();
      
      let updatedStatus = status;
  
      // If updating from "Processing" to "Shipped", capture the shippedAt date
      if (order?.orderStatus === "Processing" && status === "Shipped") {
        updatedStatus = status;
        dispatch(
          upadteOrders(id, { status: updatedStatus, shippedAt: currentDate })
        );
      }
      // If updating from "Shipped" to "Delivered", capture the deliveredAt date
      else if (order?.orderStatus === "Shipped" && status === "Delivered") {
        updatedStatus = status;
        dispatch(
          upadteOrders(id, { status: updatedStatus, deliveredAt: currentDate })
        );
      }
      // If updating from "Shipped" to "Processing" or any other status change, don't capture date
      else {
        dispatch(upadteOrders(id, { status: updatedStatus }));
      }
      // dispatch(upadteOrders(id, {status}));
    };
  
    useEffect(() => {
      if (updateError) {
        toast.error(updateError);
        dispatch(clearErrors());
      }
  
      if (isUpdate) {
        toast.success("order Updated successfull");
        dispatch({ type: UPDATE_ORDERS_RESET });
      }
  
      dispatch(orderDetailsById(id));
    }, [dispatch, id, isUpdate, updateError]);
  

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="pt-10 pl-3 md:pl-10 pr-3 md:pr-10 pb-52 bg-gray-light font-roboto">
          <div className="md:w-3/6">
            <div className="flex bg-gray pt-2 pb-2 rounded ">
              <h1 className="font-semibold text-base text-white pl-2">
                {order?.shippingInfo?.name}
              </h1>
              <p className="font-semibold text-lg ml-5 text-white">
                {order?.shippingInfo?.phoneNo}
              </p>
            </div>
            <div className="flex flex-wrap">
              <h1 className="font-medium text-base m-1">
                {order?.shippingInfo?.address}
              </h1>
              ,
              <h1 className="font-medium text-base m-1">
                {order?.shippingInfo?.landmark}
              </h1>
              ,
              <h1 className="font-medium text-base m-1">
                {order?.shippingInfo?.locality}
              </h1>
              ,
              <h1 className="font-medium text-base m-1">
                {order?.shippingInfo?.city}
              </h1>
              ,
              <h1 className="font-medium text-base m-1">
                {order?.shippingInfo?.state}
              </h1>
              ,
              <h1 className="font-medium text-base m-1">
                {order?.shippingInfo?.country}
              </h1>
            </div>
            <h1 className="font-medium text-base m-1">
              Pincode : {order?.shippingInfo?.pinCode}
            </h1>
          </div>

          {order?.orderStatus === "Delivered" ? (
            <div>
              <h1 className="text-green">
                Item {order?.orderStatus} successfully .
              </h1>
              <div className="flex items-center">
                <h1 className="font-medium text-base">Delivered At -</h1>
                <div className="flex space-x-3 ml-2">
                  <h1>{new Date(order.deliveredAt).toLocaleDateString()}</h1>
                  <h1> {new Date(order.deliveredAt).toLocaleTimeString()}</h1>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={onHandleStatus} className="sm:w-3/6 md:w-1/6 mt-5">
                <h1 className="font-semibold text-lg">Update order Status</h1>
              <select
                className="border border-1 mt-2 h-10 rounded w-full"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value="">Processing</option>
                {order?.orderStatus === "Processing" && (
                  <option value="Shipped">Shipped</option>
                )}

                {order?.orderStatus === "Shipped" && (
                  <option value="Delivered">Delivered</option>
                )}
              </select>
              <button
                type="submit"
                className="pl-1 pr-1 pt-2 pb-2 uppercase rounded mt-5 w-full bg-blue text-white"
              >
                update
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderUpdate;
