import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { Rating } from "@mui/material";
// import { AiFillStar } from "react-icons/ai";
import "./ProductsCard.css";
import { IoMdHeart } from "react-icons/io";
import { clearErrors, productWishList } from "../../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import "./ProductsCard.css";

const ProductsList = ({ product }) => {
  const [currentImage, setCurrentImage] = useState(
    product.images && product.images[0]?.url
  );
  console.log(currentImage)

  
  const [isHovered, setIsHovered] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [wishListColor, setWishListColor] = useState(false);
  console.log(wishListColor)

  // whish list
  const { wishlist, error } = useSelector((state) => state.getwishList);
  // console.log(wishlist)
  const isWishlistProduct =
    wishlist && wishlist?.some((item) => item._id === product?._id);

  const onHoverImage = () => {
    const leaveImage = product?.images && product?.images[1]?.url;
    setCurrentImage(leaveImage);
    setIsHovered(true);
  };

  const onMouseoutImage = () => {
    const leaveImage = product?.images && product?.images[0]?.url;
    setCurrentImage(leaveImage);
    setIsHovered(false);
  };

  const showMoreText = (item) => {
    if (item.length <= 60) return item;

    if (item.length > 60) {
      return (
        <>
          <p>{item.slice(0, 60)} ...</p>
        </>
      );
    }
  };

  // const productPrice = (product?.price * product.gst) / 100;
  // const InclusiveAllTax = product?.price + productPrice;
  // // console.log(InclusiveAllTax)

  // // // cutoffprice with gst
  // const cutoffprice = (product.cutoffprice * product.gst) / 100;
  // const InclusiveTax = product.cutoffprice + cutoffprice;

  // discount percentage
  const discountPercentage = product.cutoffprice - product?.price;
  const cutoffPercentage = discountPercentage / product.cutoffprice;
  const actuallPercentage = cutoffPercentage * 100;

  useEffect(() => {
    setWishListColor(isWishlistProduct);
  }, [isWishlistProduct]);



  const onHandleChange = (id) => {
    dispatch(productWishList(id));
    setWishListColor(!isWishlistProduct);
  };

  // wish list
  useEffect(() => {
    if(error){
      dispatch(clearErrors())
    }
  }, [dispatch, id,wishlist,error]);

  return (
    <Fragment>
      <div>
        <div className="flex items-center justify-center font-roboto">
          <div className="flex flex-wrap space-x-1  flex justify-center  w-full">
            <Link
              className="w-90 md:w-64 h-[350px] ml-1 mr-1 mt-1 rounded cursor-pointer shadow 	bg-white flex flex-col items-center justify-between relative overflow-hidden"
              to={`/product/${product._id}`}
            >
              {/* <div className="absolute right-2 top-2  z-10 shadow-2xl rounded-full w-7 h-7  cursor-pointer flex items-center justify-center" onClick={()=>onHandleChange(product._id)}>
                  <IoMdHeart size={20} color="gray"/>
                 </div> */}
              <div
                className="absolute right-2 top-2 shadow-xl bg-white  shadow-xl rounded-full p-1 cursor-pointer z-20"
                onClick={() => {
                  onHandleChange(product?._id);
                  setWishListColor(!isWishlistProduct);
                }}
              >
                <IoMdHeart
                  size={20}
                  style={{ color: isWishlistProduct ? "red" : "gray" }}
                />
              </div>
              <div
                className={`image-container hover:scale-110 ease-out duration-300 w-60 h-60 p-5 overflow-hidden flex items-center justify-center ${
                  isHovered ? "hovered" : ""
                } `}
                onMouseOver={onHoverImage}
                onMouseOut={onMouseoutImage}
              >
                <img
                  className="first-image rounded-t font-medium text-base"
                  src={product?.images[0]?.url}
                  alt={product.name}
                />
                <img
                  className="second-image rounded-t font-medium text-base"
                  src={product?.images[1]?.url}
                  alt={product.name}
                />
              </div>

              <div className="p-2">
                <h1 className=" md:font-medium text-sm capitalize">
                  {showMoreText(product.name)}
                </h1>
                {/* <div className="flex items-center justify-between mt-2 mb-2">
                <div className="flex  items-center">
                  <h1 className="bg-[#388E3C] text-white pl-1 pr-1 flex items-center rounded h-[20px] mr-2">
                    {product?.ratings?.toFixed()}{" "}
                    <span className="ml-1">
                      <AiFillStar />
                    </span>
                  </h1>
                  <h1 className="font-medium text-sm md:text-base opacity-75">
                    ({`${product.ratings * product.numOfReviews}`})
                  </h1>
                </div>
              </div> */}

                <div className="flex items-center mt-3">
                  <h1 className="font-bold text-sm md:text-base ">
                    ₹ {product.price.toLocaleString()}
                  </h1>
                  <h1 className="text-bold text-sm md:text-base ml-2 md:ml-5 opacity-75 ">
                    <s>₹ {product.cutoffprice.toLocaleString()}</s>
                  </h1>
                </div>
              </div>
              <div className="ml-2 md:ml-5 bg-[#388E3C] absolute left-0 ">
                <h1 className="font-medium md:font-medium text-sm md:text-base text-white pl-1 pr-1">
                  {actuallPercentage?.toFixed()} % off
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsList;
