export const CREATE_ORDER_REQUIEST = "CREATE_ORDER_REQUIEST"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL"


export const MY_ORDER_REQUIEST = "MY_ORDER_REQUIEST"
export const MY_ORDER_SUCCESS = "MY_ORDER_SUCCESS"
export const MY_ORDER_FAIL = "MY_ORDER_FAIL"

export const ORDER_DETAILS_REQUIEST = "ORDER_DETAILS_REQUIEST"
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS"
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL"

// All orders list --- Admin
export const ALL_ORDERS_REQUIEST = "ALL_ORDERS_REQUIEST"
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS"
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL"

// All delivered order ---admin
export const GET_ORDER_DELIVERED_REQUEST = "GET_ORDER_DELIVERED_REQUEST"
export const GET_ORDER_DELIVERED_SUCCESS = "GET_ORDER_DELIVERED_SUCCESS"
export const GET_ORDER_DELIVERED_FAIL = "GET_ORDER_DELIVERED_FAIL"

// update order ---Admin
export const UPDATE_ORDERS_REQUIEST = "UPDATE_ORDERS_REQUIEST"
export const UPDATE_ORDERS_SUCCESS = "UPDATE_ORDERS_SUCCESS"
export const UPDATE_ORDERS_FAIL = "UPDATE_ORDERS_FAIL"
export const UPDATE_ORDERS_RESET = "UPDATE_ORDERS_RESET"

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"
export const DELETE_ORDER_RESET = "DELETE_ORDER_RESET"


// delivery order 
export const DELIVERY_ORDER_REQUEST = "DELIVERY_ORDER_REQUEST"
export const DELIVERY_ORDER_SUCCCESS =  "DELIVERY_ORDER_SUCCCESS"
export const DELIVERY_ORDER_FAIL = "DELIVERY_ORDER_FAIL"


export const CLEAR_ERROR = "CLEAR_ERROR"
