import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { createBrand } from "../../../actions/BrandAction";
import { toast } from "react-toastify";

const CreateBrand = () => {
  const { error,success} = useSelector((state) => state.brand);
  const [brands, setBrands] = useState("");
  const dispatch = useDispatch() 
  console.log(brands)

  const onHandleSubmit = (e) => {
    e.preventDefault()

    
    const myForm = new FormData()
    myForm.append("name",brands)
    dispatch(createBrand(myForm))
  };

  useEffect(() => {
    if(error){
        toast.error(error)
    }

    if(success){
        toast.success("brand created successfull")
    }

  }, [dispatch,error,success]);

  return (
    <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className=" font-roboto bg-white w-full h-[700px] rounded  overflow-scroll p-5">
            <h1>CREATE BRANDS</h1>
          <form
            action=""
            className="flex flex-col space-y-3 w-3/6 mt-5"
            onSubmit={onHandleSubmit}
          >
            <input
              value={brands}
              type="text"
              className="w-full h-10 p-2 rounded border"
              placeholder="Create Brand Name"
              onChange={(e) => setBrands(e.target.value)}
            />
            <button
              type="submit"
              className="text-white bg-blue pt-2 pb-2 rounded"
            >
              Create Brand
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateBrand;
