import axios from "axios";
import {ADD_TO_CART, REMOVE_CART_ITEM, SHIPPING_INFO} from "../constants/CartConstant"

export const addToCartItems = (id,quantity) => async(dispatch,getState) =>{
    const {data} = await axios.get(`/api/v1/product/${id}`);

    dispatch({
        type: ADD_TO_CART,
        payload: {
            product: data.product._id, 
            name: data.product.name,
            price: data.product.price ,
            cutoffprice: data.product.cutoffprice, 
            image: data.product.images[0].url,
            stock: data.product.stock,
            gst: data.product.gst,
            quantity,
        }
    })  

    localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems))

}

export const saveShippingInfo = (data) =>async (dispatch) => {
    dispatch({
        type: SHIPPING_INFO,
        payload:data
    })

    localStorage.setItem("shippingInfo",JSON.stringify(data))
}


export const removeItemsToCart = (id) =>async (dispatch,getState) => {
    dispatch({
        type: REMOVE_CART_ITEM,
        payload:id
    })

    localStorage.setItem("cartItems",JSON.stringify(getState().cart.cartItems))
}