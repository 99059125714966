import {
  CREATE_BLOG_FAIL,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_REQUEST,
  GET_BLOG_BY_ID_REQUEST,
  GET_BLOG_BY_ID_SUCCESS,
  GET_BLOG_BY_ID_FAIL,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOG_FAIL,
} from "../constants/blogContant";

export const createBlogReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BLOG_REQUEST:
        case GET_ALL_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        blog: action.payload.blog,
      };

      case GET_ALL_BLOGS_SUCCESS:
        return {
            ...state,
            loading: false,
            Allblog: action.payload.allBlog,
          };

    case CREATE_BLOG_FAIL:
        case GET_ALL_BLOG_FAIL:
     
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const getBlogDetailsReducer = (state={},action)=>{
    switch (action.type) {
        case GET_BLOG_BY_ID_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case GET_BLOG_BY_ID_SUCCESS:
          return {
            ...state,
            loading: false,
            blogId: action.payload.blogById,
          };
    
        case GET_BLOG_BY_ID_FAIL:
         
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        default:
          return state;
      }
}


