import axios from "axios";

import {
  ALL_PRODUCT_REQUIEST,
  ALL_PRODUCT_SUCCESS,
  ALL_PRODUCT_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_REQUIEST,
  PRODUCT_DETAILS_FAIL,
  ADMIN_PRODUCT_REQUIEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  CLEAR_ERROR,
  CREATE_NEW_PRODUCT_REQUIEST,
  CREATE_NEW_PRODUCT_SUCCESS,
  CREATE_NEW_PRODUCT_FAIL,
  PRODUCT_DELETE_REQUIEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  GET_PRODUCT_BY_SLUG_DETAILS_FAIL,
  GET_PRODUCT_BY_SLUG_DETAILS_REQUIEST,
  GET_PRODUCT_BY_SLUG_DETAILS_SUCCESS,
  UPDATE_PRODUCT_REQUIEST,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  PRODUCT_WISHLIST_REQUEST,
  PRODUCT_WISHLIST_SUCCESS,
  PRODUCT_WISHLIST_FAIL,
  DELETE_PRODUCT_WISHLIST_REQUEST,
  DELETE_PRODUCT_WISHLIST_SUCCESS,
  DELETE_PRODUCT_WISHLIST_FAIL,
  SEARCH_PRODUCT_REQUEST,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAIL,
  LIKE_PRODUCT_REVIEW_REQUEST,
  LIKE_PRODUCT_REVIEW_SUCEESS,
  LIKE_PRODUCT_REVIEW_FAIL,
  DISLIKE_PRODUCT_REVIEW_REQUEST,
  DISLIKE_PRODUCT_REVIEW_SUCEESS,
  DISLIKE_PRODUCT_REVIEW_FAIL,
  FILTER_PRODUCT_FAIL,
  FILTER_PRODUCT_REQUIEST,
  FILTER_PRODUCT_SUCCESS,
} from "../constants/productConstant";

export const getProduct =
  (
    keyword = "",
    currentPage = 1,
    price = [0, 50000],
    selectedCategory,
    rating = 0,
    brands
  ) =>
  async (dispatch) => {
    // console.log(selectedCategory)
    try {
      dispatch({ type: ALL_PRODUCT_REQUIEST });

      let url = `/api/v1/products`;

      // if (selectedCategory) {
      //   url = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&category=${selectedCategory}&ratings[gte]=${rating}`;
      // }

      // if (brands) {
      //   url = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${rating}&brands=${brands}`;
      // }

      const response = await axios.get(url);
      // console.log(response)

      // if(response && response.status === 200  && response.data){
      dispatch({
        type: ALL_PRODUCT_SUCCESS,
        payload: response.data,
      });
      // }else{
      //   dispatch({
      //     type: ALL_PRODUCT_FAIL,
      //     payload: "No data received from the server",
      //   });
      // }

      // console.log(data)
    } catch (error) {
      // console.log(error.response.data.message)
      dispatch({
        type: ALL_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
  };


// get filter product bt parent id
export const FilterProducts = (id,categoryId,brandId,rating) =>async(dispatch)=>{
  // console.log(categoryId)
  try {
    dispatch({
      type: FILTER_PRODUCT_REQUIEST
    })

    let url = `/api/v1/products?parentId=${id}`

    if(categoryId){
      url = `/api/v1/products?parentId=${id}&categoryId=${categoryId}`
    }

    if(brandId){
      url = `/api/v1/products?parentId=${id}&categoryId=${categoryId}&brandId=${brandId}`
    }

    if(rating){
      url = `/api/v1/products?parentId=${id}&categoryId=${categoryId}&brandId=${brandId}&rating=${rating}`
    }

    

   
    const {data} = await axios.get(`${url}`)
    dispatch({
      type: FILTER_PRODUCT_SUCCESS,
      payload: data
    })
    // console.log(data)
    
  } catch (error) {
    dispatch({
      type: FILTER_PRODUCT_FAIL,
      payload: error.response.data.message
    })
  }
}

// get user products details
export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUIEST });

    const { data } = await axios.get(`/api/v1/product/${id}`);
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// search product
export const getSearchProducts =
  (
    keyword = "",
    currentPage = 0,
    price = [0, 50000],
    rating = 0,
    Category,
    brands
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SEARCH_PRODUCT_REQUEST,
      });

      let url = `/api/v1/products/search?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${rating}`;
      // const brandQuery = brands ? `&brands=${brands.join(',')}` : '';
      // if (brands) {
      //   url = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${rating}${brandQuery}`;
      // }

      if (Category) {
        url = `/api/v1/products/search?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&category=${Category}&ratings[gte]=${rating}`;
      }

      if (brands) {
        url = `/api/v1/products/search?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${rating}&brands=${brands}`;
      }

      // if(selectedCategory){
      //  url = `/api/v1/products/search?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&category=${selectedCategory}&ratings[gte]=${rating}`
      // }

      // if (selectedCategory) {
      //   url += `&category=${selectedCategory}`;
      // }

      const { data } = await axios.get(url);
      // console.log(data)
      dispatch({
        type: SEARCH_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// get product by slug
export const getProductBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_BY_SLUG_DETAILS_REQUIEST,
    });

    const { data } = await axios.get(`/api/v1/products/${slug}`);
    dispatch({
      type: GET_PRODUCT_BY_SLUG_DETAILS_SUCCESS,
      payload: data,
    });
    // console.log(data)
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_BY_SLUG_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all product --Admin
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PRODUCT_REQUIEST,
    });
    const { data } = await axios.get("/api/v1/admin/products", {
      withCredentials: true,
    });
    dispatch({
      type: ADMIN_PRODUCT_SUCCESS,
      payload: data,
    });
    // console.log(data);
  } catch (error) {
    // console.log(error.response.data.data.message)
    dispatch({
      type: ADMIN_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// create new product ---admin
export const createNewProduct = (newProduct) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_NEW_PRODUCT_REQUIEST });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      "/api/v1/admin/product/new",
      newProduct,
      config
    );

    dispatch({ type: CREATE_NEW_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update product --- Admin
export const updateProduct = (id, product) => async (dispatch) => {
  console.log(id);
  try {
    dispatch({
      type: UPDATE_PRODUCT_REQUIEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(
      `/api/v1/admin/product/${id}`,
      product,
      config
    );
    // console.log(data);

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// new review
// export const newReview = (review) => async (dispatch) => {
//   try {
//     dispatch({ type: REVIEW_REQUIEST });
//     const config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     };

//     const { data } = await axios.put(`/api/v1/review`, review, config);
//     dispatch({
//       type: REVIEW_SUCCESS,
//       payload: data.success,
//     });

//     // console.log(data);
//   } catch (error) {
//     // console.log(error)
//     dispatch({
//       type: REVIEW_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };

// admin
// export const getAllProductsReviews = () => async (dispatch) => {
//   try {
//     // dispatch({
//     //   type: GET_ALL_PRODUCTS_REVIEWS_REQUEST,
//     // });

//     const { data } = await axios.get("/api/v1/allproducts/reviews");
//     console.log(data.reviews);
//     if (data && data.reviews) {
//       dispatch({
//         type: GET_ALL_PRODUCTS_REVIEWS_SUCCESS,
//         payload: data.reviews,
//       });
//     } else {
//       dispatch({
//         type: GET_ALL_PRODUCTS_REVIEWS_FAIL,
//         payload: "Invalid data received from the server",
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: GET_ALL_PRODUCTS_REVIEWS_FAIL,
//       payload: error.response ? error.response.data.message : error.message,
//     });
//   }
// };

// review verification
// export const reviewVerify = (review) => async (dispatch) => {
//   try {
//     dispatch({
//       type: REVIEW_VERIFY_REQUEST,
//     });
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const { data } = await axios.put("/api/v1/review/verify", review, config);
//     console.log(data)
//     dispatch({
//       type: REVIEW_VERIFY_SUCCESS,
//       payload: data.verify,
//     });
//   } catch (error) {
//     dispatch({
//       type: REVIEW_VERIFY_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };

// export const getReviewById = (id) => async (dispatch) => {
//   try {
//     dispatch({ type: GET_REVIEW_BY_ID_REQUEST });

//     const { data } = await axios.get(`/api/v1/reviews?id=${id}`);
//     dispatch({
//       type: GET_REVIEW_BY_ID_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: GET_REVIEW_BY_ID_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REQUIEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(`/api/v1/admin/product/${id}`, config);
    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// export const savePreviousSearch = (search) => (dispatch,getState) =>{
//   dispatch({
//     type: ADD_PREVIOUS_SEARCH,
//     payload:search
// })

// localStorage.setItem("previousSearch",JSON.stringify(getState().previousSearch.previousSearch))
// }

// like product review
export const likeProductReview = (productId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: LIKE_PRODUCT_REVIEW_REQUEST,
    });

    const { data } = await axios.post(`/api/v1/like/${productId}/${userId}`);
    console.log(data);
    dispatch({
      type: LIKE_PRODUCT_REVIEW_SUCEESS,
      payload: data.savedLikes,
    });
  } catch (error) {
    dispatch({
      type: LIKE_PRODUCT_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Dislike product review
export const disLikeProductReview = (productId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: DISLIKE_PRODUCT_REVIEW_REQUEST,
    });

    const { data } = await axios.post(`/api/v1/dislike/${productId}/${userId}`);
    console.log(data);
    dispatch({
      type: DISLIKE_PRODUCT_REVIEW_SUCEESS,
      payload: data.savedDislikes,
    });
  } catch (error) {
    dispatch({
      type: DISLIKE_PRODUCT_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// product wishlist

export const productWishList = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_WISHLIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(`/api/v1/wishlist`, { productId }, config);
    // console.log(data);

    dispatch({
      type: PRODUCT_WISHLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_WISHLIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// delete wishlist product

export const deleteWishListProduct = (productId) => async (dispatch) => {
  console.log(productId);
  try {
    dispatch({
      type: DELETE_PRODUCT_WISHLIST_REQUEST,
    });
    const { data } = await axios.delete("/api/v1/wishlist", {
      data: { productId },
    });
    console.log(data);

    dispatch({
      type: DELETE_PRODUCT_WISHLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_WISHLIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
