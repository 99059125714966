import axios from "axios";
import {
  CREATE_CATEGORY_FAIL,
  CREATE_CATEGORY_REQUIEST,
  CREATE_CATEGORY_SUCCESS,
  GET_CATEGORY_BY_ID_FAIL,
  GET_CATEGORY_BY_ID_REQUEST,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_REQUIEST,
  GET_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_BY_PARENT_ID_FAIL,
  GET_PRODUCT_CATEGORY_BY_PARENT_ID_REQUEST,
  GET_PRODUCT_CATEGORY_BY_PARENT_ID_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_REQUIEST,
  UPDATE_CATEGORY_SUCCESS,
} from "../constants/categoryConstant";

// Admin create category
export const createCategory = (newCategory) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CATEGORY_REQUIEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post("/api/v1/category", newCategory, config);
    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: data.category,
    });
    console.log(data);
  } catch (error) {
    dispatch({
      type: CREATE_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// admin get all category
export const getAllCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORY_REQUIEST,
    });
    const { data } = await axios.get("/api/v1/category");
    dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: data.categoryList,
    });
    // console.log(data)
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateCategorybyId = (id, category) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CATEGORY_REQUIEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(
      `/api/v1/product-category/${id}`,
      category,
      config
    );
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data.updateCategory,
    });
    // console.log(data);
  } catch (error) {
    dispatch({
      type: UPDATE_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getCategoryById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORY_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/product-category/${id}`);
    dispatch({
      type: GET_CATEGORY_BY_ID_SUCCESS,
      payload: data.category,
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getCategoryByParentId = (parentId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_CATEGORY_BY_PARENT_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/category/${parentId}`);
    console.log(data.categories);
    dispatch({
      type: GET_PRODUCT_CATEGORY_BY_PARENT_ID_SUCCESS,
      payload: data.categories,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_CATEGORY_BY_PARENT_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};
