import axios from "axios";
import {
  CREATE_BLOG_FAIL,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_REQUEST,
  GET_BLOG_BY_ID_REQUEST,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOG_FAIL,
  GET_BLOG_BY_ID_SUCCESS,
  GET_BLOG_BY_ID_FAIL,
} from "../constants/blogContant";

// create new blog
export const createBlog = (NewBlog) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_BLOG_REQUEST,
    });

    const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

    const { data } = await axios.post("/api/v1/admin/create/blog", NewBlog,config);
    console.log("actions", data);
    dispatch({
      type: CREATE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.data) {
        // Dispatch an action with the error message from the server response
        dispatch({
          type: CREATE_BLOG_FAIL,
          payload: error.response.data.message,
        });
      } else {
        // Handle other types of errors
        console.error("Error:", error);
        dispatch({
          type: CREATE_BLOG_FAIL,
          payload: "An error occurred while processing your request.",
        });
      }
  }
};

// get all blog

export const getAllBlog = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BLOGS_REQUEST,
    });

    const { data } = await axios.get("/api/v1/allblog");
    console.log(data);

    dispatch({
      type: GET_ALL_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOG_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get blog by id
export const getBlogDetails = ({id}) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BLOG_BY_ID_REQUEST,
    });
    const { data } = await axios.get(`/api/v1/allblog/${id}`);
    console.log(data);
    dispatch({
      type: GET_BLOG_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOG_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};
