import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderDetailsById, clearErrors } from "../../actions/orderActions";
import Loader from "../Loader/Loader";
import { Link, useParams } from "react-router-dom";
// import Review from "../Orders/Reviews";
import { Rating } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { AiFillStar } from "react-icons/ai";
import MetaData from "../MetaData/MetaData";
import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import { createReview } from "../../actions/reviewAction";
import { REVIEW_RESET } from "../../constants/reviewConstant";

const OrderDetails = () => {
  const [toggle, setToggle] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [headline, setHeadline] = useState("")
  const [uploadImage, setUploadProductImage] = useState("");
  const dispatch = useDispatch();
  const { loading, error, order } = useSelector((state) => state.orderDetails);
  const {user} = useSelector((state)=>state.user)
  console.log(user)
  const { success, error: reviewError } = useSelector((state) => state.allreviews);
  // console.log(review)
  // const { review } = useSelector((state) => state.reviewId);
  // console.log(review);
  const [invoice, setInvoice] = useState();
  const [handleReviewId, setHandleReviewId] = useState();
  const [errorMessage, setErrorMessage] = useState({});


  // console.log(order);

  const DeliveryStatus = [
    { status: "Order Confirmed", date: order?.createAt },
    { status: "Shipped", date: order?.shippedAt },
    { status: "Delivered", date: order?.deliveredAt },
  ];

  // const ALlreview = review?.reviews && review?.reviews?.reduce(function (prev, current) {
  //   return prev + current.rating;
  // }, 0);

  // console.log(review);
  const { id } = useParams();
  // console.log(id);

  const itemDelivered = (item) => {
    return item.date !== undefined && item.date !== null && item.date !== "";
  };

  // console.log(id)

  // const productId =
  //   order?.orderItems && order?.orderItems.map((id) => id.product);
  //   console.log(productId)

  const reviewSubmitHandler = (e) => {
    e.preventDefault();
    let error = {};

    if (!rating) {
      error.rating = "Rating can't be empty.";
    }

    if (!comment) {
      error.comment = "Description can't be empty.";
    }

    if (!headline) {
      error.headline = "Headline can't be empty.";
    }

    if (Object.keys(error)?.length > 0) {
      setErrorMessage(error);
      return;
    }

    // const createdDate = new Date()

    const myForm = new FormData();
    myForm.append("rating", rating);
    myForm.append("comment", comment);
    myForm.append("headline",headline)
    myForm.append("user",user._id)
    myForm.append("productId", handleReviewId);
    for (let i = 0; i < uploadImage?.length; i++) {
      myForm.append(`productImage`, uploadImage[i]);
    }

    dispatch(createReview(myForm));
    setToggle(false);
  };

  // const onHandleReviewId = (item) => {
  //   console.log(item);

  //   dispatch(getReviewById(item));
  // };

  useEffect(() => {
    if (error) {
      toast(error);
      dispatch(clearErrors());
    }
    if (reviewError) {
      toast(reviewError);
      dispatch(clearErrors());
    }

    if (success) {
      toast("Review Submitted Successfully");
      dispatch({ type: REVIEW_RESET });
    }

    // invoice download
    async function downloadInvoice() {
      const res = await axios.get(`/api/v1/invoices/${id}`);
      setInvoice(res.data.userInvoice);
    }

    downloadInvoice();
    dispatch(orderDetailsById(id));
  }, [dispatch, error, id,success,reviewError]);

  return (
    <div className="font-roboto w-full pb-20 bg-gray-light">
      <MetaData title={`Gshoppi.com - Your order information`} />
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="pt-10 md:pl-10 md:pr-10">
          <div className="border-b pb-2">
            <h1 className="font-medium text-sm pl-3">
              Order ID - {order && order._id}
            </h1>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="mt-5 pl-5 p-3 rounded md:w-1/2">
              <h1 className="font-bold text-base">Deliverey Address</h1>
              <h1 className="font-semibold text-sm capitalize pt-3">
                {order?.user && order?.user.name}
              </h1>
              <div className="flex flex-wrap">
                <p className="font-normal text-base capitalize">
                  {order?.shippingInfo && order?.shippingInfo.address}
                </p>
                ,
                <p className="font-normal text-base capitalize pl-1">
                  {order?.shippingInfo && order?.shippingInfo.city}
                </p>
                ,
                <p className="font-normal text-base capitalize pl-1">
                  {order?.shippingInfo && order?.shippingInfo.state}
                </p>
                ,
                <p className="font-normal text-base capitalize pl-1">
                  {order?.shippingInfo && order?.shippingInfo.country}
                </p>
                ,
                <p className="font-normal text-base capitalize pl-1">
                  {order?.shippingInfo && order?.shippingInfo.pinCode}
                </p>
              </div>
              <h1 className="font-bold text-base mt-5">Phone Number</h1>
              <h1 className="font-medium text-sm">
                {order.shippingInfo && order.shippingInfo.phoneNo}
              </h1>
            </div>
            <div className="md:w-1/2 p-3  md:border-l">
              <h1 className="pt-3">More actions</h1>
              <div className="flex items-center justify-between">
                <h1>Download Invoice</h1>
                <div>
                  {/* <a
                    href={order.invoice}
                    download=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button onClick={downloadInvoice} className="text-blue font-normal text-sm border border-gray w-[200px] pt-1 pb-1 rounded">
                      Download
                    </button>
                  </a> */}
                  <PDFDownloadLink
                    fileName="invoice.pdf"
                    style={{
                      textDecoration: "none",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      color: "#ffffff",
                      backgroundColor: "#8492a6",
                      width: "auto",
                    }}
                    document={<Invoice Invoice={invoice} />}
                  >
                    Download
                  </PDFDownloadLink>
                </div>
              </div>

              {/* "Delivered" */}
              {/* <div>
                {
                  order.orderStatus
                }
              </div> */}
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex flex-col-reverse xl:flex-row ">
              <div className="mt-5 mb-5  w-full rounded border-b">
                {order.orderItems &&
                  order.orderItems.map((item) => {
                    console.log(item)
                    return (
                      <div className="flex items-center" key={item?.product}>
                        <div className="flex flex-col items-center w-full">
                          <div className="flex flex-col md:flex-row justify-between space-y-3 md:space-y-0 p-5 pr-10 border-t  w-full">
                            <Link to={`/product/${item.product}`}>
                              <img
                                className="w-[100px]"
                                src={item.image && item.image}
                                alt={item.name}
                              />
                            </Link>
                            <div className="flex flex-col ml-0 md:ml-2 w-[400px]">
                              <Link
                                to={`/product/${item.product}`}
                                className="font-medium text-base capitalize text-[#2874F0]"
                              >
                                {item.name}
                              </Link>
                              <p>Quantity : {item.quantity}</p>
                              <div className="flex">
                                <h1>Price : </h1>
                                <s className="font-medium text-base opacity-75 ml-2">
                                  ₹ {item?.MRP && item?.MRP}
                                </s>
                                <h1 className="ml-2 font-medium text-base">
                                  ₹ {item?.price && item?.price}
                                </h1>
                              </div>
                              {/* <div> */}
                                {/* <Rating
                                  className="mr-1"
                                  size="large"
                                  name="rating" */}
                                   {/* value={ALlreview} */}
                                {/* /> */}
                              {/* </div> */}
                            </div>

                            <div>
                              <button
                                className="w-[200px] h-10 flex items-center justify-center text-[#2874F0] mt-10"
                                onClick={() => {
                                  setToggle(true);
                                  // onHandleReviewId(item.product);
                                  setHandleReviewId(item.product);
                                }}
                              >
                                <span className="mr-3">
                                  <AiFillStar />
                                </span>
                                Rate & Review Product
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="flex relative ml-5 mr-5 mt-5 mb-10 md:mb-0 border-t  md:border-none ">
                {DeliveryStatus.map((item, index) => {
                  let statusColor;
                  if (item.status === "Order Confirmed") {
                    statusColor = item.date ? "progressBarColor" : "itemColor";
                  } else if (item.status === "Shipped") {
                    statusColor = item.date ? "progressBarColor" : "itemColor";
                  } else if (item.status === "Delivered") {
                    statusColor = item.date ? "progressBarColor" : "itemColor";
                  } else {
                    statusColor = item.date ? "progressBarColor" : "itemColor";
                  }

                  return (
                    <div className="md:w-32 mt-10 md:mt-0" key={index}>
                      <h1 className="ml-2 md:ml-0 mr-2 md:mr-0">
                        {item.status}
                      </h1>
                      <div
                        className={`w-full h-2 ${statusColor} flex items-center mt-1`}
                        style={{ animation: "progressBarAnimation 1s" }}
                      >
                        <div className="w-3 h-3 rounded-full bg-[#396e39e7]"></div>
                      </div>
                      {item.date && (
                        <>
                          <p className="font-medium text-xs mt-2">
                            {new Date(item.date).toDateString()}
                          </p>
                          {/* <p>{new Date(item.date).toLocaleTimeString()}</p> */}
                        </>
                      )}
                      {item.status === "Delivered" && itemDelivered(item) && (
                        <div className="absolute left-0 right-0">
                          <h1>Your Item has been delivered.</h1>
                          <p>
                            Time:
                            {item.date
                              ? new Date(item.date).toLocaleTimeString()
                              : ""}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex items-center justify-end md:justify-start ">
              <h1 className="font-semibold text-lg">Total Paid </h1>
              <span className="font-semibold ml-2 mr-2">:</span>
              <h1 className="font-semibold text-lg mr-10">
                {" "}
                ₹ {order.totalPrice?.toLocaleString()}
              </h1>
            </div>

            <div className="absolute top-2/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-full flex items-center justify-center">
              {toggle ? (
                <div className="md:w-3/6 h-auto  p-5 md:mt-3  bg-gray-light shadow-2xl">
                  <form action="" onSubmit={reviewSubmitHandler}>
                    <div className="flex items-center justify-between">
                      <h1 className="font-bold text-lg opacity-90 text-black">
                        Rate this Product
                      </h1>
                      <div className="cursor-pointer">
                        <AiOutlineClose
                          color="black"
                          size={25}
                          onClick={() => setToggle(false)}
                        />
                      </div>
                    </div>
                    <div className="mt-1 flex items-center  mb-3">
                      <Rating
                        className="mr-2 "
                        size="large"
                        name="rating"
                        onChange={(e) => setRating(e.target.value)}
                        value={rating}
                      />
                      {errorMessage.rating && (
                        <p className="text-red">{errorMessage.rating}</p>
                      )}
                    </div>

                    <div>
                      <h1 className="font-bold text-lg pt-2 pb-2 opacity-90 text-black">Add a headline</h1>
                      <input
                       className="w-full h-10 outline-blue border pl-3  font-medium text-base placeholder:text-sm"
                       type="text" value={headline} onChange={(e)=>setHeadline(e.target.value)}  placeholder="What's most important to know ?"/>
                        {errorMessage.headline && (
                        <p className="text-red">{errorMessage.headline}</p>
                      )}
                    </div>

                    <h1 className="font-bold text-lg pt-2 pb-2 opacity-90 text-black">
                      Review this Product
                    </h1>
                    <textarea
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      id=""
                      className="w-full h-20 outline-blue border pl-3 pt-2  font-medium text-base placeholder:text-sm"
                      cols="20"
                      rows="10"
                      placeholder="Description"
                    ></textarea>
                    {errorMessage.comment && (
                      <p className="text-red">{errorMessage.comment}</p>
                    )}
                    <div className="pt-2 pb-3">
                      <h1 className="font-semibold text-lg text-black capitalize">
                        upload product image
                      </h1>
                      <input
                        name="productImage"
                        type="file"
                        onChange={(e) => setUploadProductImage(e.target.files)}
                        multiple
                        className="w-full  border border-black mt-2 font-medium text-base text-black placeholder:text-sm"
                        placeholder="product image"
                      />
                    </div>
                    <button
                      className="font-semibold text-lg bg-orange text-white w-[200px] pt-2 pb-2"
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
