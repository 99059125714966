import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../actions/userActions";
import { Link, NavLink, useLocation,useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { toast } from "react-toastify";

import MetaData from "../../MetaData/MetaData";
// import axios from "axios";

const Login = () => {
  const [userlogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  let { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  
  // console.log(token)

 
  // if(!user && localStorage.getItem('user_info')) {
  //   user = JSON.parse(localStorage.getItem('user_info')).user;
  //   isAuthenticated =  JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  // console.log(isAuthenticated)
  // console.log(user)

  const dispatch = useDispatch();
  const onHandleChange = (e) => {
    setUserLogin({ ...userlogin, [e.target.name]: e.target.value });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    
    dispatch(login(userlogin.email, userlogin.password));
  };

  const redirect = location.search ? location.search.split("=")[1] : "/profile";

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirect);
      toast.success("login successfull");
    }
    // if (error) {
    //   toast.error(error);
    // }
    
  }, [error, isAuthenticated,redirect,navigate]);

  return (
    <Fragment>
      {loading ? (
        <div className="flex items-center justify-center bg-gray-light">
          <Loader />
        </div>
      ) : (
        <div className="h-20  pt-10 pb-[100px] bg-gray-light w-full h-full flex flex-col items-center justify-center p-2">
          <MetaData title={`sign In | gshoppi`} />

          {/* <h1>Logo</h1> */}
          <div className="w-full md:w-2/6 border-2 border-gray rounded-lg p-2 mt-3">
            <h1 className="font-semibold text-3xl">Sign in</h1>
            <form action="" onSubmit={onHandleSubmit}>
              <div className="flex flex-col mt-3">
                <div className="flex flex-col">
                  <label
                    htmlFor="email/number"
                    className="font-medium text-base"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={userlogin.email}
                    onChange={onHandleChange}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <label
                    htmlFor="email/number"
                    className="font-medium text-base mt-2"
                  >
                    Password
                  </label>
                  <div className="w-full relative">
                    <input
                      type={toggle ? "text" : "password"}
                      name="password"
                      className="border-2 w-full border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                      value={userlogin.password}
                      onChange={onHandleChange}
                    />
                    <div className="absolute top-4 right-8 cursor-pointer">
                      {toggle ? (
                        <AiOutlineEye
                          size={25}
                          onClick={() => setToggle(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={25}
                          onClick={() => setToggle(true)}
                        />
                      )}
                    </div>
                  </div>
                  <NavLink
                    to="/password/forgot"
                    className="font-medium text-sm capitalize text-end mt-3 text-blue"
                  >
                    forgot password.?
                    
                  </NavLink>
                </div>
                <button
                  type="submit"
                  className="mt-5 w-full h-10 bg-yellow hover:bg-yellow-dark rounded capitalize"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>

          <div className="w-full md:w-2/6 mt-7">
            <div className="flex items-center justify-center mb-3">
              <div className="w-full h-[1px] bg-black opacity-75"></div>
              <div className="w-full text-center">New to Gshoppi?</div>
              <div className="w-full h-[1px] bg-black opacity-75"></div>
            </div>
            <Link to="/register">
              <button className="w-full h-10 border-2 border-gray capitalize rounded-lg">
                create your new account
              </button>
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
