import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../../actions/cartActions";
// import { Country, State } from "country-state-city";
import MetaData from "../../MetaData/MetaData";
import CheckOutSetps from "./CheckOutSetps.js";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { FiPlus } from "react-icons/fi";
import {
  // AddShippingAddess,
  getShippingAddress,
} from "../../../actions/shippingAddressAction.js";

// import Loader from "../../Loader/Loader.js"

const ShippingInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { shippingInfo } = useSelector((state) => state.cart);
  // console.log(shippingInfo);
  const [name, setName] = useState(shippingInfo.name);
  const [locality, setLocality] = useState(shippingInfo.locality);
  const [state, setState] = useState(shippingInfo.state);
  const [city, setCity] = useState(shippingInfo.city);
  const [landmark, setLandMark] = useState(shippingInfo.landmark);
  const [address, setAddress] = useState(shippingInfo.address);
  const [country, setCountry] = useState(shippingInfo.country);
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [gstNumber, setGstNumber] = useState(shippingInfo.gstNumber);
  const [companyName, setCompanyName] = useState(shippingInfo.companyName);
  const [error, setError] = useState({});
  const [addressType, setAddressType] = useState("Home");
  const [toggle, setToggle] = useState(false);

  // gst input uppercase 
  const toInputUpperCase =(e) =>{
    e.target.value = ("" + e.target.value).toUpperCase();
  }

  // console.log(addressType);

  // const { getALLAddress } = useSelector((state) => state.shippingAddress);
  // console.log(getALLAddress);

  const handleAddressTypeChange = (e) => {
    if (e.target && e.target.value) {
      setAddressType(e.target.value);
    } else {
      // Handle the case when e.target or e.target.value is undefined
      console.error("Invalid event or value");
    }
  };

  const shippingSubmit = (e) => {
    e.preventDefault();
    let errorMessage = {};
    if (phoneNo.length !== 10 || isNaN(phoneNo)) {
      errorMessage.phoneNo = "Invalid phone number";
    }

    if (!phoneNo) {
      errorMessage.phoneNo = "Phone number is required.";
    }

    if (!name.trim()) {
      errorMessage.name = "Name is required.";
    }

    if (pinCode.length !== 6 || isNaN(pinCode)) {
      errorMessage.pinCode = "Invalid pinCode number";
    }

    if (!pinCode) {
      errorMessage.pinCode = "PinCode is required.";
    }

    if (!locality?.trim()) {
      errorMessage.locality = "Locality is required.";
    }

    if(toggle){
      if (!companyName?.trim()) {
        errorMessage.companyName = "Company Name is required.";
      }

      const gstNumberPattern = /^[0-9a-zA-Z]{15}$/;
      if (!gstNumber?.trim()) {
        errorMessage.gstNumber = "GST number is required.";
      } else if (!gstNumberPattern.test(gstNumber)) {
        errorMessage.gstNumber = 'Enter a valid GST Number.';
      }
    }

   

    if (!address?.trim()) {
      errorMessage.address = "Address is required";
    }
    if (!state) {
      errorMessage.state = "State is required";
    }

    if (!city?.trim()) {
      errorMessage.city = "City / District / Town is required";
    }

    if (!landmark?.trim()) {
      errorMessage.landmark = "Landmark is required";
    }

    if (!toggle) {
      setCompanyName(" ");
      setGstNumber(" ");
      setToggle(false);
    }
  

    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }

    // const formData = new FormData();
    // formData.append("name", name);
    // formData.append("phoneNo", phoneNo);
    // formData.append("locality", locality);
    // formData.append("address", address);
    // formData.append("landmark", landmark);
    // formData.append("city", city);
    // formData.append("country", country);
    // formData.append("pinCode", pinCode);
    // formData.append("state", state);
    // formData.append("addressType", addressType);

    dispatch(
      saveShippingInfo({
        name,
        phoneNo,
        locality,
        address,
        gstNumber: toggle && toggle ? gstNumber : "",
        companyName: toggle && toggle ? companyName : "",
        landmark,
        city,
        country,
        pinCode,
        state,
        addressType,
      })
    );

    // dispatch(AddShippingAddess(formData));

    navigate("/order/confirm");
  };

  useEffect(() => {
    dispatch(getShippingAddress());
  }, [dispatch]);

  return (
    <Fragment>
      <MetaData title="Shipping details | gshoppi.com" />
      <CheckOutSetps activeSteps={0} />
      {/* {
        loading ? <div className="flex items-center justify-center">
          <Loader />
        </div>

      : */}
      <div className="pt-10 pb-20 w-full flex flex-col items-center justify-center bg-gray-light font-roboto">
        <div className="w-full md:w-1/2 pl-2 md:pl-0 pr-2 md:pr-0  h-[70px] bg-gray text-white flex items-center justify-center">
          <h1 className="font-medium text-xl uppercase">shipping info</h1>
        </div>
        <div className="w-full md:w-1/2 mt-5 pl-2 pr-2 md:pl-0 md:pr-0">
          {/* <div className="w-full flex flex-col">
            <div className="flex  flex-col "> 
              <div className="flex flex-col" >

                {getALLAddress &&
                  getALLAddress.map((add) => {
                    return (
                      <div className="flex flex-col items-start  border-[1px] m-1 p-2" key={add._id} onSubmit={shippingSubmit}>
                        <div className="flex">
                        <input type="radio" className="font-medium text-lg w-5 h-5" onChange={handleAddressTypeChange} />

                          <div className="ml-3  flex items-center justify-center">
                            <h1 className="font-medium text-base">
                              {add.name}
                            </h1>
                            <h1 className="bg-white pl-5 pr-5 pt-1 pb-1 rounded ml-3 mr-3 font-medium text-sm">
                              {add.addressType}
                            </h1>
                            <h1>{add.phoneNo}</h1>
                          </div>
                        </div>
                        <div className="ml-5">
                          <h1>{add.address}</h1>
                        </div>
                        <button type="submit" className="pl-5 pr-5 pt-2 pb-2 font-medium text-sm uppercase bg-[#FB641B] text-white">Deliver Here</button>
                      </div>
                    );
                  })}
              </div>
            </div>

            <button
              className="text-base font-medium capitalize text-gray md:w-2/6 flex mt-10"
              onClick={() => setToggle(!toggle)}
            >
              <FiPlus size={20} /> <span className="ml-3">Add new Address</span>
            </button>
          </div> */}
          <div className="w-full mt-5">
            {/* {toggle ? ( */}
            <form
              action=""
              encType="multipart/form-data"
              className="pl-2 pr-2"
              onSubmit={shippingSubmit}
            >
              <div className="flex flex-col sm:flex-row sm:space-x-3 ">
                <div className="flex flex-col w-full">
                  <label htmlFor="name" className="font-medium text-base">
                    Name
                  </label>
                  <input
                    type="name"
                    name="name"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {error.name && <p className="text-red">{error.name}</p>}
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="number" className="font-medium text-base">
                    Mobile
                  </label>
                  <input
                    type="number"
                    name="phoneno"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    size="10"
                  />
                  {error.phoneNo && <p className="text-red">{error.phoneNo}</p>}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-3">
                <div className="flex flex-col w-full">
                  <label htmlFor="pincode" className="font-medium text-base">
                    Pin code
                  </label>
                  <input
                    type="number"
                    name="pincode"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    size="6"
                  />
                  {error.pinCode && <p className="text-red">{error.pinCode}</p>}
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="locality"
                    className="font-medium text-base capitalize"
                  >
                    locality
                  </label>
                  <input
                    type="text"
                    name="locality"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={locality}
                    onChange={(e) => setLocality(e.target.value)}
                  />
                  {error.locality && (
                    <p className="text-red">{error.locality}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full mt-2 mb-1">
                <div className="flex items-center">
                  <label htmlFor="locality" className="font-medium text-base">
                    Do you have GST No.
                  </label>
                  <div className="flex ml-4">
                    <div class="flex items-center">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        checked={toggle}
                        onChange={() => {
                          setToggle(true);
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex items-center ml-2">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        checked={!toggle}
                        onChange={() => {
                          setToggle(false);
                          setCompanyName(" ");
                          setGstNumber(" ");
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  {toggle && (
                    <div className="flex flex-col">
                      <div className="flex flex-col w-full">
                        <label
                          htmlFor="company name"
                          className="font-medium text-base capitalize"
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          placeholder="Company Name"
                          className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                        {error.companyName && (
                          <p className="text-red">{error.companyName}</p>
                        )}
                      </div>
                      <div className="flex flex-col w-full">
                        <label
                          htmlFor="gst"
                          className="font-medium text-base capitalize"
                        >
                          GST
                        </label>
                        <input
                          type="text"
                          name="gstNumber"
                          placeholder="GST Number"
                          className="border-2 border-black border-opacity-70 rounded h-10 pl-2"
                          value={gstNumber}
                          onChange={(e) => setGstNumber(e.target.value)}
                          onInput={toInputUpperCase}
                        />
                        {error.gstNumber && (
                          <p className="text-red">{error.gstNumber}</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {error.locality && <p className="text-red">{error.locality}</p>}
              </div>
              <div className="flex flex-col w-full w-full">
                <label htmlFor="address" className="font-medium text-base">
                  Address
                </label>
                <textarea
                  type="text"
                  name="address"
                  className="border-2 pl-2 h-20 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                  value={address}
                  placeholder="Address..."
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
                {error.address && <p className="text-red">{error.address}</p>}
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-3">
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="country"
                    className="font-medium text-base capitalize"
                  >
                    country
                  </label>
                  <select
                    value={country}
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="">Select Country</option>
                    <option value="IN">India</option>
                    {/* 
                    {Country &&
                      Country.getAllCountries().map((item) => {
                        return (
                          <option key={item.isoCode} value={item.isoCode}>
                            {item.name}
                          </option>
                        );
                      })} */}
                  </select>
                  {error.country && <p className="text-red">{error.country}</p>}
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="email/number"
                    className="font-medium text-base capitalize"
                  >
                    state
                  </label>
                  {/* {country && ( */}
                  <select
                    className="w-full border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">Select State </option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="telangana">Telangana</option>

                    {/* {State &&
                        State.getStatesOfCountry(country).map((item) => {
                          return (
                            <option  value="Karnataka">
                              Karnataka
                            </option>
                          );
                        })} */}
                  </select>

                  {/* )} */}
                  {error.state && <p className="text-red">{error.state}</p>}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-3">
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="city"
                    className="font-medium text-base capitalize"
                  >
                    city / district / town
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  {error.city && <p className="text-red">{error.city}</p>}
                  {/* {state && (
                    <select
                      className="w-full border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option value="">-- select City --</option>
                      {City &&
                        City.getCitiesOfState(country, state).map((item) => {
                          console.log(item)
                          return (
                            <option
                              key={item.countryCode}
                              value={item.stateCode}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select> */}
                  {/* )} */}
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="landmark"
                    className="font-medium text-base capitalize"
                  >
                    Landmark
                  </label>
                  <input
                    type="text"
                    name="landmark"
                    className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                    value={landmark}
                    onChange={(e) => setLandMark(e.target.value)}
                  />
                  {error.landmark && (
                    <p className="text-red">{error.landmark}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full mt-2">
                <label
                  htmlFor="email/number"
                  className="font-medium text-base capitalize"
                >
                  Address type
                </label>
                <div className="flex">
                  <div className="flex">
                    <input
                      type="checkbox"
                      id="home"
                      value="Home"
                      checked={addressType === "Home"}
                      onChange={handleAddressTypeChange}
                    />
                    <label htmlFor="home" className="font-medium text-base">
                      Home
                    </label>
                  </div>
                  <div className="flex ml-10">
                    <input
                      type="checkbox"
                      id="work"
                      value="Work"
                      checked={addressType === "Work"}
                      onChange={handleAddressTypeChange}
                    />
                    <label htmlFor="work" className="font-medium text-base">
                      Work
                    </label>
                  </div>
                  <div className="flex ml-10">
                    <input
                      type="checkbox"
                      id="others"
                      value="Others"
                      checked={addressType === "Others"}
                      onChange={handleAddressTypeChange}
                    />
                    <label htmlFor="others" className="font-medium text-base">
                      Others
                    </label>
                  </div>
                </div>

                {/* {error.state && <p className="text-red">{error.state}</p> */}
              </div>

              <button
                type="submit"
                className="bg-blue text-base font-medium w-full mt-3 h-10 capitalize text-white rounded"
              >
                submit
              </button>
            </form>
            {/* ) : (
              ""
            )}  */}
          </div>

          {/* <div className="w-[300px]">
          <h1>Name : {shippingInfo.name}</h1>
          <h1>Mobile No : {shippingInfo.phoneNo}</h1>
          <h1>Add : {shippingInfo.name}</h1>
          <h1>Name : {shippingInfo.name}</h1>
          <h1>Name : {shippingInfo.name}</h1>
          <h1>Name : {shippingInfo.name}</h1>
          <h1>Name : {shippingInfo.name}</h1>
        </div> */}
        </div>
      </div>
      {/* } */}
    </Fragment>
  );
};

export default ShippingInfo;
