import React from "react";
// import Sidebar from "../Admin/Sidebar.js";
// import { useSelector } from "react-redux";
import DashboardData from "./DashboardData";
// import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

const Dashboard = () => {
  return (
      <div className="antialiased bg-lightgray w-full min-h-screen text-slate-300 relative py-4">
        <div className="grid grid-cols-12 mx-auto gap-2 sm:gap-4 md:gap-6 lg:gap-10  max-w-7xl my-10 px-2">
          <div id="menu" className="bg-white col-span-3 rounded-lg p-4 sticky">
            <Sidebar />
          </div>

          <DashboardData />
        </div>
      </div>
  );
};

export default Dashboard;
