import axios from "axios";
import {
  CREATE_BRAND_FAIL,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  DELETE_BRAND_REQUIEST,
  DELETE_BRAND_SUCCESS,
  GET_ALL_BRAND_FAIL,
  GET_ALL_BRAND_REQUEST,
  GET_ALL_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  UPDATE_BRAND_REQUIEST,
  UPDATE_BRAND_SUCCESS,
} from "../constants/BrandConstant";

export const createBrand = (newBrand) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_BRAND_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/brand/create", newBrand, config);
    // console.log(data);

    dispatch({
      type: CREATE_BRAND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_BRAND_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllBrands = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BRAND_REQUEST,
    });

    const { data } = await axios.get("/api/v1/brands/allbrands");
    dispatch({
      type: GET_ALL_BRAND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BRAND_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateBrand = (id,name) =>async(dispatch)=>{
    try {
        dispatch({
            type: UPDATE_BRAND_REQUIEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
      

        const {data} = await axios.put("/api/v1/brand/update",{id,name},config)
        console.log(data)
        dispatch({
            type: UPDATE_BRAND_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_BRAND_FAIL,
            payload: error.response.data.message
        })
    }
}


export const deleteBrand = (id) => async(dispatch)=>{
    try {
        dispatch({
            type: DELETE_BRAND_REQUIEST
        }) 
        console.log(id)  
        const config = {
            headers: {   
              "Content-Type": "application/json",
            },
          };  
     
        const {data} = await axios.delete(`/api/v1/brand/deletebrand`,{id}, config)
        console.log(data)
        dispatch({
            type: DELETE_BRAND_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_BRAND_FAIL,
            payload: error.response.data.message
        })
    }
}