import React, { Fragment, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { searchProduct } from "../../actions/productActions";
import { IoSearch } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";


const Search = ({toggle,setToggle}) => {
// const {searchProduct} = useSelector((state)=>state.searchProduct)

// const dispatch = useDispatch()

// console.log(searchProduct)
  const [keyword, setKeyword] = useState(" ");
  // console.log(keyword)
  const navigate = useNavigate();
  const searchHandler = (e) => {
    e.preventDefault();
    // console.log(keyword)

   if(keyword.trim()){
    // const search = dispatch(searchProduct(keyword))
    navigate(`/products/search/${keyword}`)
   }
   setToggle(false)

    // if (keyword.trim()) {
    //   navigate(`/products/${keyword}`);
    //   // localStorage.setItem("previousKey",[...keyword,keyword])
    // } else {
    //   navigate("products");
    // }
  };

  return (
    <Fragment> 
      <div>
        <div className="block md:hidden text-blue dark:text-blue" onClick={()=>setToggle(!toggle)}>
        <IoSearch size={20}  />
        </div>

        {
          toggle ?  <form onSubmit={searchHandler} className="text-black bg-gray-light w-full h-[400px] absolute top-0 left-0 z-20 pl-10 pr-10 flex items-center">
           <div className="absolute top-10 right-10"> <IoMdClose size={30} color="black" onClick={()=>setToggle(false)}/></div>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative  w-full md:w-[300px] lg:w-[400px] h-4 flex items-center justify-between">
            
            <input
              type="text"
              className="block w-full p-2 outline-blue border-[1.5px] border text-sm text-gray-900  rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search a product... "
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
            />
            <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer w-10">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-blue dark:text-blue"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
          </div>
        </form> : ""
        }

        <form onSubmit={searchHandler} className="text-black hidden md:block">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative  md:w-[300px] lg:w-[400px] h-4 flex items-center justify-between">
            
            <input
              type="text"
              className="block w-full p-2 outline-blue  text-sm text-gray-900  rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search a product... "
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer w-10">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-blue dark:text-blue"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Search;
