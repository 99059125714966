import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllWishlist } from "../../actions/userActions";
// import { Rating } from "@mui/material";
import { MdDelete } from "react-icons/md";
import Loader from "../Loader/Loader";
import {
  clearErrors,
  deleteWishListProduct,
} from "../../actions/productActions";
import { Link } from "react-router-dom";
import Profile from "../userProfile/Profile";
import { toast } from "react-toastify";
import MetaData from "../MetaData/MetaData";

const ProductWishList = () => {
  // const [wishLists, setWishList] = useState();
  // const [deleteProduct, setDeleteProduct] = useState();

  const dispatch = useDispatch();

  const { wishlist, error, loading, success } = useSelector(
    (state) => state.getwishList
  );

  const { success: deleted, loading: loadingDelete } = useSelector(
    (state) => state.wishlist
  );
  // console.log(wishlist)

  const [deleteWishlist, setDeleteWishlist] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null); // State to store the item._id for which delete confirmation message should be shown

  // console.log(wishlist);

  // const productPrice = (wishlist.price * wishlist.gst) / 100;
  // const InclusiveAllTax = wishlist.price + productPrice;

  // // cutoffprice with gst
  // const cutoffprice = (wishlist.cutoffprice * wishlist.gst) / 100;
  // const InclusiveTax = wishlist.cutoffprice + cutoffprice;

  // // discount percentage
  // const discountPercentage = InclusiveTax - InclusiveAllTax;
  // const cutoffPercentage = discountPercentage / InclusiveTax;
  // const actuallPercentage = cutoffPercentage * 100;

  const onHandleDelete = (id) => {
    // console.log(id);
    dispatch(deleteWishListProduct(id));
    if (deleted) {
      toast.success("wishlist deleted successfull");
    }
  };

  // discount price

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (success) {
      toast.success("product deleted successfull");
    }

    dispatch(getAllWishlist());
  }, [dispatch, error, success, deleted]);

  return (
    <div className="flex flex-col md:flex-row w-full pt-5 pb-20 pl-2 md:pl-5 pr-2 md:pr-0 bg-gray-light font-roboto">
      <div className="w-full md:w-2/6 md:pr-3">
        <Profile />
      </div>
      <div className="w-full min-h-full bg-white mr-2 shadow rounded mt-5 md:mt-0">
        <div className={`border-b-2 border-opacity-75 border-gray pl-5`}>
          {wishlist?.length > 0 ? (
            <h1 className="font-bold pt-4 pb-4 text-black">
              My Wishlist (<span>{wishlist?.length}</span>)
            </h1>
          ) : (
            ""
          )}
        </div>
        <MetaData title={`My Profile`} />
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="pt-5 ">
            {wishlist?.length ? (
              <div>
                {wishlist &&
                  wishlist.map((item) => {
                    return (
                      <div
                        className="flex justify-between border-b-2 border-opacity-75 border-gray mt-2 pb-2 relative"
                        key={item._id}
                      >
                        <div className="flex">
                          <Link to={`/product/${item._id}`} className="">
                            <div className="w-[100px] md:w-[150px] ">
                              <img
                                className="object-fill md:pl-5 "
                                src={item.images[0].url}
                                alt=""
                              />
                            </div>
                          </Link>
                          <div className="flex flex-col ml-2 md:ml-10 mt-3">
                            <Link
                              to={`/product/${item._id}`}
                              className="hover:text-blue"
                            >
                              <h1 className="font-medium text-base">
                                {item.name}
                              </h1>
                            </Link>
                            {/* <Rating size="large" value={item.ratings} readOnly="true" precision="0.5"  className="ml-[-5px]" /> */}
                            <div className="flex  space-x-3 mt-3">
                              <div className="flex flex-col-reverse md:flex-row space-x-2">
                                <h1 className="font-semibold text-base">
                                  ₹ {item.price}
                                </h1>
                                <s className="opacity-75 font-semibold text-base">
                                  ₹{item.cutoffprice}
                                </s>
                              </div>
                              <div>
                                <h1 className="text-green font-semibold">
                                  {(
                                    ((item.cutoffprice - item.price) /
                                      item.cutoffprice) *
                                    100
                                  ).toFixed()}{" "}
                                  % off
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="opacity-75 cursor-pointer mt-4 pr-5"
                          onClick={() => {
                            setDeleteItemId(item._id);
                            setDeleteWishlist(!deleteWishlist);
                          }}
                        >
                          <MdDelete color="red" size={30} />
                        </div>

                        <div className="absolute right-10 top-10">
                          {deleteWishlist && deleteItemId === item._id && (
                            <div className="w-[270px] h-auto bg-white absolute z-10 right-3 top-[-30px] p-3 shadow-xl rounded">
                              <h1 className="font-semibold text-sm">
                                Are you sure you want to remove this product?
                              </h1>
                              <div className="flex mt-3 space-x-3">
                                <button
                                  className="w-1/2 h-10 shadow text-black capitalize rounded font-medium text-sm"
                                  onClick={() => setDeleteWishlist(false)}
                                >
                                  cancle
                                </button>
                                {loadingDelete ? (
                                  <div className="flex items-center justify-center">
                                    <Loader />
                                  </div>
                                ) : (
                                  <button
                                    className="w-1/2 h-10 shadow font-medium text-sm bg-red text-white capitalize rounded"
                                    onClick={() => onHandleDelete(item._id)}
                                  >
                                    yes, Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[500px]">
                <h1 className="font-bold text-lg">Empty Wishlist</h1>
                <p className="font-medium text-medium">
                  You have no items in your wishlist. Start adding!
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductWishList;
