import {
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAIL,
  GET_ALL_BRAND_FAIL,
  GET_ALL_BRAND_SUCCESS,
  GET_ALL_BRAND_REQUEST,
  UPDATE_BRAND_REQUIEST,
  UPDATE_BRAND_SUCCESS,
  DELETE_BRAND_REQUIEST,
  DELETE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND_FAIL,
  CLEAR_ERROR,
} from "../constants/BrandConstant";

export const createBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BRAND_REQUEST:
    case GET_ALL_BRAND_REQUEST:
      case UPDATE_BRAND_REQUIEST:
      case DELETE_BRAND_REQUIEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brand: action.payload.brand,
        success: action.payload.success
      };

    case GET_ALL_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        allbrands: action.payload.allbrands,
      };

     case UPDATE_BRAND_SUCCESS:
      return{
        ...state,
        loading: false,
        success: action.payload.success
      } 

      case DELETE_BRAND_SUCCESS:
        return{
          ...state,
          loading: false,
          isDelete : action.payload.success
        }

    case CREATE_BRAND_FAIL:
    case GET_ALL_BRAND_FAIL:
     case UPDATE_BRAND_FAIL: 
     case DELETE_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case CLEAR_ERROR:
        return{
          ...state,
          loading: false,
          error: null
        }

    default:
      return state;
  }
};
