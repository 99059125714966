import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Profile from "./Profile";

const UserProfile = () => {
  let { user } = useSelector((state) => state.user);


  return (
    <Fragment>
      <div className="flex flex-col md:flex-row w-full pt-5 pb-20 pl-2 md:pl-5 pr-2 md:pr-5 bg-gray-light">
        <div className="w-full md:w-2/6">
          <Profile />
        </div>
        <div className="w-full h-auto pl-5 bg-white md:ml-3 rounded shadow">
          <h1 className="font-medium text-xl capitalize pt-3">
          Personal Information
          </h1>
          <div className="flex w-full pt-2">
            <div className="bg-white space-y-3 rounded-tl-lg w-4/6">
              <div className="flex items-center">
                <h1 className="font-normal text-base capitalize mr-1 w-20">
                  Name
                </h1>
                <span className="font-semibold">:</span>
                <p className="font-normal text-base capitalize ml-2">
                  {user?.name}
                </p>
              </div>
              <div className="flex items-center">
                <h1 className="font-normal text-base capitalize mr-1 w-20">
                  Email
                </h1>
                <span className="font-semibold">:</span>
                <p className="font-normal text-base ml-2">{user?.email}</p>
              </div>
              <div className="flex items-center">
                <h1 className="font-normal text-base capitalize mr-1 w-20">
                  Mobile
                </h1>
                <span className="font-semibold">:</span>
                <p className="font-normal text-base ml-2">{user?.phone}</p>
              </div>
              <div className="flex items-center">
                <h1 className="font-normal text-base capitalize mr-1 w-20">
                  join date
                </h1>
                <span className="font-semibold">:</span>
                <p className="font-normal text-base ml-2">
                  {user?.createdAt?.substr(0, 10)}
                </p>
              </div>
              <NavLink to="/password/update">
                <button className="capitalize border mt-5 pl-3 pr-3 pt-1 pb-1 border-orange rounded text-orange">
                  change password
                </button>
              </NavLink>
            </div>
            <div className="bg-white w-2/6 flex flex-col items-center justify-center mt-2 border-l border-opacity-75 rounded-tr-lg ">
              <h1 className="mt-2 font-medium text-sm capitalize ">
                profile image
              </h1>
              <div className="bg-white w-[100px] h-[100px] mt-2 rounded flex items-center justify-center">
                {user?.avatar?.url ? (
                  <img
                    className="w-full h-full rounded"
                    src={user?.avatar?.url}
                    alt={user?.name}
                  />
                ) : (
                  <img
                    className="w-full h-full rounded"
                    src="/user.png"
                    alt={user?.name}
                  />
                )}
              </div>
              {/* <button className="font-medium text-sm capitalize border pl-2 pr-2 mt-3 pt-2 pb-2 rounded">
                    upload image
                  </button> */}
            </div>
          </div>
          <div className="flex items-center justify-end w-full bg-white pr-10 rounded-br-lg rounded-bl-lg">
            <NavLink
              to="/me/update"
              className="bg-orange text-white  flex items-center justify-center font-medium text-sm rounded-lg h-10 w-[300px]  mt-10 mb-5"
            >
              EDIT PROFILE
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserProfile;
