import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { FaRegCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import {
  deleteReview,
  getAllReviews,
  reviewVerify,
} from "../../../actions/reviewAction";
import { MdDelete } from "react-icons/md";

const Reviews = () => {
  const dispatch = useDispatch();
  const [verify, setVerify] = useState("");
  const [getId, setGetid] = useState("");
  const { allreview, loading, error } = useSelector(
    (state) => state.allreviews
  );
  // console.log(allreview)
  const { success, isDelete } = useSelector((state) => state.review);

  //   console.log(reviews);
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleRowClick = (index) => {
    setClickedIndex(clickedIndex === index ? null : index);
  };

  const onHandleVerify = (e) => {
    e.preventDefault();

    if (!verify) {
      toast.error("please select the options");
      return;
    }

    let reviewValue = {
      id: getId,
      verify: verify,
    };
    dispatch(reviewVerify(reviewValue));
  };

  const onHandleDelete = (reviewId,productId) => {
    console.log(reviewId)
    console.log(productId)

    let review = {
      id: reviewId,
      productId: productId
    }
    dispatch(deleteReview(review));
  };

  useEffect(() => {
    dispatch(getAllReviews());
    if (success) {
      toast.success("review verify successfull");
    }

    if (isDelete) {
      toast.success("review deleted successfull");
    }
  }, [dispatch, success,isDelete]);

  useEffect(() => {}, [allreview, loading, error]);

  return (
    <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className=" font-roboto bg-white w-full h-[700px] rounded  overflow-scroll">
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <div>
                {allreview &&
                  allreview.map((item) => <h1>{item.verified === "true"}</h1>)}
              </div>
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray dark:bg-gray-700 dark:text-gray-400 h-[50px]">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Review No.
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Review Id
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      verified
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allreview &&
                    allreview.map((item, index) => {
                      console.log(item);
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={`${
                              index % 2 === 0
                                ? "odd:bg-white odd:dark:bg-gray-900"
                                : "even:bg-gray-50 even:dark:bg-gray-800"
                            } border-b dark:border-gray-700 cursor-pointer`}
                            onClick={() => {
                              handleRowClick(index);
                              setGetid(item._id);
                            }}
                          >
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {index}
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item._id}
                            </th>
                            <td className="px-6 py-4">{item.user.name}</td>
                            <td className="px-6 py-4">{item.user.email}</td>
                            <td className="px-6 py-4">
                              {new Date(item.createdAt).toLocaleDateString()}
                            </td>
                            <td className="px-6 py-4">
                              {item.verified === true ? (
                                <FaRegCircleCheck color="green" size={30} />
                              ) : (
                                <FaRegCircleCheck size={30} />
                              )}
                            </td>
                            <td className="px-6 py-4">
                              <MdDelete
                                size={25}
                                color="red"
                                onClick={() =>
                                  onHandleDelete(item._id, item.productId)
                                }
                              />
                            </td>
                          </tr>
                          {clickedIndex === index && (
                            <tr className="bg-gray dark:bg-gray-800 rounded w-full">
                              <td colSpan="5" className="px-6 py-4">
                                <div className="w-full p-5 bg-gray-100 dark:bg-gray-900">
                                  <div className="flex w-full">
                                    <div className="w-3/6">
                                      <div className="flex items-center space-x-3 w-full">
                                        <h1 className="font-semibold w-[100px]  text-white">
                                          Headline
                                        </h1>
                                        <span className="text-white">:</span>
                                        <h1 className="font-semibold w-full  text-white">
                                          {item.headline}
                                        </h1>
                                      </div>
                                      <div className="flex items-center space-x-3 w-full">
                                        <h1 className="font-semibold w-[100px]  text-white">
                                          Comment
                                        </h1>
                                        <span className="text-white">:</span>
                                        <h1 className="font-semibold w-full  text-white">
                                          {item.comment}
                                        </h1>
                                      </div>
                                      <div className="flex space-x-3 w-full">
                                        <h1 className="font-semibold w-[100px] text-white">
                                          Rating
                                        </h1>
                                        <span className="text-white">:</span>
                                        <h1 className="font-semibold w-full  text-white">
                                          {item.rating}
                                        </h1>
                                      </div>
                                    </div>
                                    <div className="w-3/6">
                                      {item.verified === true ? (
                                        <h1 className="font-semibold text-lg text-white flex">
                                          Product review is verified
                                          <span className="ml-3">
                                            <FaRegCircleCheck
                                              color="green"
                                              size={25}
                                            />
                                          </span>
                                        </h1>
                                      ) : (
                                        <form
                                          onSubmit={onHandleVerify}
                                          className=""
                                        >
                                          <select
                                            className="border border-1 h-10 rounded w-full"
                                            onChange={(e) =>
                                              setVerify(e.target.value)
                                            }
                                            value={verify}
                                          >
                                            <option value="">
                                              verify review
                                            </option>
                                            <option value="true">True</option>
                                          </select>
                                          <button
                                            type="submit"
                                            className="pl-1 pr-1 pt-2 pb-2 rounded capitalize mt-5 w-full bg-blue text-white"
                                          >
                                            verify
                                          </button>
                                        </form>
                                      )}
                                    </div>
                                  </div>

                                  <div className="flex space-x-2 mt-5">
                                    {item.productImage.map(
                                      (imges, imgIndex) => (
                                        <img
                                          key={imgIndex}
                                          className="w-[100px]"
                                          src={imges.url}
                                          alt=""
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
