import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts, clearErrors } from "../../../actions/productActions";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import Loader from "../../Loader/Loader";
import { Button } from "@mui/material";
import { deleteProduct } from "../../../actions/productActions";
import { PRODUCT_DELETE_RESET } from "../../../constants/productConstant";
import { toast } from "react-toastify";
import { CgClose } from "react-icons/cg";

const AllProducts = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProductData, setFilterProductData] = useState([]);
  const [productInfo, setProductInfo] = useState();

  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();
  // const id = useParams();
  const { error, products, loading } = useSelector((state) => state.products);
  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.product
  );

  // delete products ---admin
  const OnHandleDelete = (id) => {
    dispatch(deleteProduct(id));
  };

  // console.log(filterProductData);

  useEffect(() => {
    const filterData = products?.filter((product) => {
      return (
        product.name.toLowerCase().includes(searchQuery.toLowerCase())||
        product._id.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilterProductData(filterData);
  }, [products, searchQuery]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      toast.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      toast.success("Product Deleted Successfully");
      dispatch({ type: PRODUCT_DELETE_RESET });
    }

    dispatch(getAllProducts());
  }, [dispatch, error, deleteError, isDeleted]);

  return (
    <div className="antialiased bg-lightgray w-full min-h-screen  text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className="w-full w-full min-h-full">
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg h-[700px] min-h-full overflow-scroll">
              <div class="flex items-center justify-between pb-4 bg-white dark:bg-gray-900 p-2">
                <div>
                  <h1 className="font-bold text-lg">
                    Total products :- {products?.length} items
                  </h1>
                </div>
                <label for="table-search" class="sr-only">
                  Search
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    id="table-search-users"
                    class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search for products"
                  />
                </div>
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {/* <th scope="col" class="p-4">
                    <div class="flex items-center">
                        <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="checkbox-all-search" class="sr-only">checkbox</label>
                    </div>
                </th> */}
                    <th scope="col" class="px-6 py-3">
                      Id
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Product Name
                    </th>

                    <th scope="col" class="px-6 py-3">
                      product Id
                    </th>
                    <th scope="col" class="px-6 py-3">
                      stock
                    </th>
                    <th scope="col" class="px-6 py-3">
                      All Info
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Edit
                    </th>
                    <th scope="col" class="px-6 py-3">
                      delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterProductData &&
                    filterProductData.map((item, index) => {
                      return (
                        <tr
                          className="bg-white border-b  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          key={item._id}
                        >
                          {" "}
                          <th
                            scope="row"
                            className="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <h1>{index}</h1>
                          </th>
                          <th className="px-6 py-4">
                            <div className="text-base font-semibold">
                              {item?.name}
                            </div>
                          </th>
                          <td className="px-6 py-4 ">{item?._id}</td>
                          <td className="px-6 py-4 bg-gray text-white font-bold  flex items-center justify-center">
                            {item?.stock}
                          </td>
                          <td className="px-6 py-4  font-bold ">
                            <button
                              className=""
                              onClick={() => {
                                setProductInfo(item);
                                setToggle(true);
                              }}
                            >
                              Info
                            </button>
                          </td>
                          <td className="px-6 py-4">
                            <Link
                              to={`/dashboard/all-products/${item._id}`}
                              className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-green capitalize"
                            >
                              <BiEdit size={25} />
                            </Link>
                          </td>
                          <td className="px-6 py-4">
                            <Button
                              onClick={() => OnHandleDelete(item._id)}
                              className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-red"
                            >
                              <MdDeleteForever size={25} color="red" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/* see product details */}
              <div className="absolute top-0 right-0 left-0">
                {toggle ? (
                  <div className="min-w-screen h-full bg-white flex flex-col pb-10 shadow-lg shadow-black">
                    <div className="flex items-center justify-between bg-gray p-3">
                      <h1 className="font-bold text-lg capitalize text-white  ">
                        Product details
                      </h1>
                      <CgClose
                        size={30}
                        color="blue"
                        onClick={() => setToggle(false)}
                        className="cursor-pointer"
                      />
                    </div>

                    <div className="flex flex-col">
                      <div className="mt-5 ml-10 space-y-2 flex flex-wrap">
                        <div className="w-[350px]">
                          <h1 className="capitalize font-bold text-lg">Name</h1>
                          <h1 className="font-medium text-base ">
                            {productInfo?.name}
                          </h1>
                        </div>
                        <div className="w-[350px]">
                          <h1 className="capitalize font-bold text-lg">
                            selling price
                          </h1>
                          <h1 className="w-full">₹ {productInfo.price}</h1>
                          {/* <input
                              type="text"
                              onChange={onChangeHandle}
                              value={productInfo.price}
                              name=""
                              // className="border pl-2"
                            /> */}
                        </div>
                        <div className="w-[350px]">
                          <h1 className="capitalize font-bold text-lg">
                            MRP price
                          </h1>
                          {/* <input
                              type="text"
                              onChange={onChangeHandle}
                              value={productInfo.cutoffprice}
                              className="border pl-2"
                            /> */}
                          <h1 className="w-full">
                            ₹ {productInfo.cutoffprice}
                          </h1>
                        </div>
                      </div>

                      <div className="mt-5 ml-10 space-y-2 flex">
                        <div className="w-[350px]">
                          <h1 className="capitalize font-bold text-lg">
                            Stock
                          </h1>
                          <h1 className="font-medium text-lg">
                            {productInfo?.stock}
                          </h1>
                          {/* <input
                              type="text"
                              onChange={onChangeHandle}
                              value={productInfo.stock}
                              className="border pl-2"
                            /> */}
                        </div>
                        <div className="w-[350px]">
                          <h1 className="capitalize font-bold text-lg">
                            category
                          </h1>
                          <h1 className="w-full">{productInfo.category}</h1>
                        </div>
                      </div>

                      <div className="ml-10 mt-5">
                        <h1 className="capitalize font-semibold text-lg">
                          description
                        </h1>
                        <p>{productInfo.description}</p>
                      </div>
                    </div>
                    <div className="ml-10 mt-5">
                      <h1 className="capitalize font-semibold text-lg">
                        Product Images
                      </h1>
                      <div className="flex items-center space-x-2">
                        {productInfo.images &&
                          productInfo?.images.map((image) => {
                            return (
                              <img
                                className="w-[100px] h-[100px] mt-3 "
                                src={image?.url}
                                alt="product"
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* update product */}
              {/* <div className="absolute top-0 right-0 left-0">
                {updateToggle ? (
                 
                ) : (
                  ""
                )}
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
