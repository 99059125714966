import React, { useEffect, useState } from "react";
import SideOrderDashboard from "./sideOrderDashboard";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllOrders } from "../../actions/orderActions";
import MetaData from "../MetaData/MetaData";
import Loader from "../Loader/Loader";

const AllOrderDashboard = () => {

  const dispatch = useDispatch();
  const { orders, loading, success, error } = useSelector(
    (state) => state.allOrders
  );

  const [search,setSearchItem] = useState(orders)
  
  console.log(search);


  function AllOrdersData(orders) {
    return (
      orders &&
      orders.map((item, index) => {
        if (item.orderItems) {
          return (
            <div className="shadow m-2 flex items-center justify-between w-full font-roboto p-2" key={item._id}>
              <div className="flex flex-col w-full">
              {AllOrdersData(item.orderItems)}
              </div>
              <div>
                <Link className="pl-10 pr-10 pb-2 pt-2 bg-green text-white" to={`/order-dashboard/allorder/${item._id}`}>Edit</Link>
              </div>
             
            </div>
          );
        } else {
          return (
            <li key={index} className="w-full flex">
                <img className="w-1/6" src={item.image} alt={item.name}/>
              <div className="ml-2">
              <p className=" font-medium text-base"><span className="font-medium text-base">Name</span> : {item.name}</p>
              <p className="font-medium text-base"><span className="font-medium text-base">Price</span> : <s className="mr-3">{item.MRP}</s>  {item.price}</p>
              <p><span className="font-medium text-base">quantity</span> : {item.quantity}</p>
              </div>
            </li>
          );
        }
      })
    );
  }

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    dispatch(getAllOrders());
  }, [dispatch, error]);

  return (
    <div className="pl-10 pr-10 pt-10 pb-10 bg-gray-light flex w-full">
      <SideOrderDashboard />
      <div className="ml-5 bg-white rounded w-full h-auto">
        <div className="w-full bg-white shadow rounded mt-5 md:mt-0">
          <MetaData title={`Gshoppi.com - Your orders History`} />
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="list-none flex flex-col ">
              <div className="flex items-center justify-between h-20 bg-gray p-2">
                <div>
                  <h1 className="text-white">{`ALL Orders - ${success?.OrderListOfProduct} items`}</h1>
                </div>
                <input
                  type="text"
                  placeholder="search order by name and id"
                  className="w-1/2 border p-1 rounded outline-none"
                  onChange={(e)=>setSearchItem(e.target.value)}
                />
              </div>
              <div className="p-2">{AllOrdersData(orders)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllOrderDashboard;
