import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, updatePassword } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { UPDATE_PASSWORD_RESET } from "../../constants/userConstant";
import Loader from "../Loader/Loader";
import MetaData from "../MetaData/MetaData";
import { toast } from "react-toastify";

const UpdatePassword = () => {
  const {error,isUpdated,loading} = useSelector((state)=>state.profile)  


  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onHandleUpdatePassword  = (e ) =>{
    e.preventDefault()

    const myform = new FormData()

    myform.set("oldPassword",oldPassword)
    myform.set("newPassword",newPassword)
    myform.set("confirmPassword",confirmPassword)

    dispatch(updatePassword(myform))
  }


  useEffect(()=>{

    if(error){
        toast.error("please enter correct password")
        dispatch(clearErrors())
    }

    if(isUpdated){  
        navigate("/profile") 
        toast.success("password update successfull")
        dispatch({type: UPDATE_PASSWORD_RESET})    
    }

  },[dispatch,error,isUpdated,navigate])

  return (
    <Fragment>
    {
        loading ? <Loader /> : <>
        <MetaData title="update password" />
        <div className="bg-gray-light w-full h-screen  flex items-center justify-center ">
      <div className="ml-3 mr-3 md:ml-0 md:mr-0 w-full md:w-3/6 h-5/6 bg-white shadow-2xl text-black relative rounded p-5 ">
        <h1 className="text-center font-semibold text-xl opacity-80 mt-10">
          Update your Password
        </h1>
        <div className="w-full h-[1.5px] bg-gray mt-3"></div>
        <div className="flex items-center justify-center z-20">
          <form
            action=""
            onSubmit={onHandleUpdatePassword}
            className="w-full md:w-4/6 mt-4"
          >
            <div className="flex flex-col mt-3 space-y-3">
              <div className="w-full relative">
              <label
                    htmlFor="email/number"
                    className="font-medium text-base"
                  >
                    Old Password
                  </label>
                <input
                  type="password"
                  name="password"
                  className="border-2 w-full border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="w-full relative">
              <label
                    htmlFor="email/number"
                    className="font-medium text-base"
                  >
                    New Password
                  </label>
                <input
                  type="password"
                  name="password"
                  className="border-2 w-full border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="w-full relative">
              <label
                    htmlFor="email/number"
                    className="font-medium text-base"
                  >
                    Confirm Password
                  </label>
                <input
                  type="password"
                  name="password"
                  className="border-2 w-full border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <input
                type="submit"
                value="change password"
                className=" w-full h-10 bg-yellow hover:bg-yellow-dark rounded capitalize "
              />
            </div>
          </form>
        </div>
      </div>
    </div>
        </>
    }
    </Fragment>
  );
};

export default UpdatePassword;
