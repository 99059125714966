export const ALL_PRODUCT_REQUIEST = "ALL_PRODUCT_REQUIEST";
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL";

// filter product by paret id 
export const FILTER_PRODUCT_REQUIEST = "FILTER_PRODUCT_REQUIEST"
export const FILTER_PRODUCT_SUCCESS = "FILTER_PRODUCT_SUCCESS"
export const FILTER_PRODUCT_FAIL = "FILTER_PRODUCT_FAIL"

// Admin all product list
export const ADMIN_PRODUCT_REQUIEST = "ADMIN_PRODUCT_REQUIEST";
export const ADMIN_PRODUCT_SUCCESS = "ADMIN_PRODUCT_SUCCESS";
export const ADMIN_PRODUCT_FAIL = "ADMIN_PRODUCT_FAIL";

// PRODUCT details
export const PRODUCT_DETAILS_REQUIEST = "PRODUCT_DETAILS_REQUIEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";


// search product 
export const SEARCH_PRODUCT_REQUEST = "SEARCH_PRODUCT_REQUEST"
export const SEARCH_PRODUCT_SUCCESS = "SEARCH_PRODUCT_SUCCESS"
export const SEARCH_PRODUCT_FAIL = "SEARCH_PRODUCT_FAIL"
export const SEARCH_PRODUCT_RESET = "SEARCH_PRODUCT_RESET"


// PRODUCT details by slug
export const GET_PRODUCT_BY_SLUG_DETAILS_REQUIEST = "GET_PRODUCT_BY_SLUG_DETAILS_REQUIEST";
export const GET_PRODUCT_BY_SLUG_DETAILS_SUCCESS = "GET_PRODUCT_BY_SLUG_DETAILS_SUCCESS";
export const GET_PRODUCT_BY_SLUG_DETAILS_FAIL = "GET_PRODUCT_BY_SLUG_DETAILS_FAIL";

// delete product details ---Admin
export const PRODUCT_DELETE_REQUIEST = "PRODUCT_DELETE_REQUIEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET"


// update product ---Admin
export const UPDATE_PRODUCT_REQUIEST = "UPDATE_PRODUCT_REQUIEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";

// Create new product ---admin
export const CREATE_NEW_PRODUCT_REQUIEST = "CREATE_NEW_PRODUCT_REQUIEST";
export const CREATE_NEW_PRODUCT_SUCCESS = "CREATE_NEW_PRODUCT_SUCCESS";
export const CREATE_NEW_PRODUCT_RESET = "CREATE_NEW_PRODUCT_RESET";
export const CREATE_NEW_PRODUCT_FAIL = "CREATE_NEW_PRODUCT_FAIL";



// export const ADD_PREVIOUS_SEARCH = "ADD_PREVIOUS_SEARCH"

// product wishlist
export const PRODUCT_WISHLIST_REQUEST = "PRODUCT_WISHLIST_REQUEST";
export const PRODUCT_WISHLIST_SUCCESS = "PRODUCT_WISHLIST_SUCCESS"
export const PRODUCT_WISHLIST_FAIL = "PRODUCT_WISHLIST_FAIL";
export const PRODUCT_WISHLIST_RESET = "PRODUCT_WISHLIST_RESET"


export const DELETE_PRODUCT_WISHLIST_REQUEST = "DELETE_PRODUCT_WISHLIST_REQUEST";
export const DELETE_PRODUCT_WISHLIST_SUCCESS = "DELETE_PRODUCT_WISHLIST_SUCCESS"
export const DELETE_PRODUCT_WISHLIST_FAIL = "DELETE_PRODUCT_WISHLIST_FAIL";
export const DELETE_PRODUCT_WISHLIST_RESET = "PRODUCT_WISHLIST_RESET"


// like product review
export const LIKE_PRODUCT_REVIEW_REQUEST =  "LIKE_PRODUCT_REVIEW_REQUEST"
export const LIKE_PRODUCT_REVIEW_SUCEESS =  "LIKE_PRODUCT_REVIEW_SUCEESS"
export const LIKE_PRODUCT_REVIEW_FAIL =  "LIKE_PRODUCT_REVIEW_FAIL"

// dislike product review
export const DISLIKE_PRODUCT_REVIEW_REQUEST =  "DISLIKE_PRODUCT_REVIEW_REQUEST"
export const DISLIKE_PRODUCT_REVIEW_SUCEESS =  "DISLIKE_PRODUCT_REVIEW_SUCEESS"
export const DISLIKE_PRODUCT_REVIEW_FAIL =  "DISLIKE_PRODUCT_REVIEW_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR";
