import React, { Fragment } from 'react'
import {Helmet} from "react-helmet"

const MetaData = ({title}) => {
  return (
    <Fragment>
    <div>
        <Helmet>
            <title>{title}</title>
        </Helmet>
    </div>
    </Fragment>
  )
}

export default MetaData