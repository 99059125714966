import React, { useEffect, useState } from "react";
import { deleteOrder, getAllOrders } from "../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Loader from "../Loader/Loader";
import { MdDeleteForever } from "react-icons/md";
import { Button } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { clearErrors } from "../../actions/productActions";
import { toast } from "react-toastify";

const AllOrders = () => {
  const { orders, loading, success, error } = useSelector(
    (state) => state.allOrders
  );




  const { isDelete } = useSelector((state) => state.order);

  // console.log(orders);
  // const { error: updateError, isUpdate } = useSelector((state) => state.order);
  // console.log(success)
  // const [searchQuery, setSearchQuery] = useState("");
  // const [filterOrder, setFilterOrder] = useState('');
  const [orderInfo, setOrderInfo] = useState();
  const [toggle, setToggle] = useState(false);
  const [checkDelete, setCheckDelete] = useState(false);
  const [orderDeleteToggle,setOrderDeleteToggle] = useState(null)
  // const [orderStatus, setStatus] = useState("");
  // const id = useParams();
  // console.log(filterOrder)

  const dispatch = useDispatch();

  // const onHandleStatus = (e) => {
  //   e.preventDefault();
  //   const myForm = new FormData();
  //   myForm.append("status", orderStatus);
  //   dispatch(upadteOrders(id, myForm));
  // };

  // const CheckDeleteItem = (item)=>{
  //  const orderData = orders && orders.find((orderItem) => orderItem._id === item);
  // //  console.log(orderData)

  //  if(orderData){
  //   setCheckDelete(!checkDelete)
  //  }  
  // }

  const OnHandleDelete = (id) => {
    // console.log(id)
    dispatch(deleteOrder(id));
  };

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
    if (isDelete) {
      toast.success("order deleted successfull");
    }

    dispatch(getAllOrders());
    // console.log(orders)

    // for(let order of orders && orders){
    //     const orderItem = order && order.orderItems.forEach(element => {
    //       console.log(element)
    //     });
    // }

    // const orderData = orders && orders.filter((order,id)=>{
    //   console.log(order)
    //   return(
    //     order && order?.forEach((item)=>{
    //       console.log(item)
    //       return(
    //          item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //          item.name.toLowerCase().includes(searchQuery.toLowerCase())
    //       )
    //     })
    //   )
    // })

    // setFilterOrder(orderData)
  }, [dispatch, error, isDelete]);

  return (
    <div className="antialiased bg-lightgray w-full min-h-screen text-slate-300 relative py-4 font-roboto">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className="w-full h-full overflow-x-auto bg-white rounded-lg">
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col w-full h-[700px] min-h-full overflow-scroll relative">
              <div className="flex items-center justify-between p-2">
                <div className="flex flex-col  ">
                  <h1 className=" w-full font-medium text-lg">
                    <span className="font-bold text-xl">Orders</span> :{" "}
                    {success?.OrderListOfProduct} items
                  </h1>
                  <h1 className=" w-full font-medium text-lg">
                    <span className="font-bold text-xl">Total Amount</span> : ₹{" "}
                    {Number(success?.totalAmount ).toLocaleString()}
                  </h1>
                </div>
                <div className="flex">
                  <label for="table-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokelinecap="round"
                          strokelinejoin="round" 
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search-users"
                      className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search for users"
                      // onChange={(e)=>setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 	">
                <thead className="text-xs text-white uppercase bg-gray dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {/* <th scope="col" className="p-4">
                    <div className="flex items-center">
                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="checkbox-all-search" className="sr-only">checkbox</label>
                    </div>
                </th> */}
                    <th scope="col" className="px-3 py-3">
                      Id
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Product Name
                    </th>

                    <th scope="col" className="px-3 py-3">
                      Order Id
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Update Status
                    </th>
                    <th scope="col" className="px-3 py-3">
                      All Info
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Order Date
                    </th>
                    <th scope="col" className="px-1 py-3">
                      delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orders &&
                      orders
                        ?.sort((a, b) => {
                          return new Date(b.createAt) - new Date(a.createAt);
                        })
                        .map((item, index) => (
                          <tr
                            className="bg-white border-b  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 relative"
                            key={index}
                          >   
                            {/* <td className="w-4 p-4">
                          <div className="flex items-center">
                              <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                              <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                          </div>
                      </td> */}
                            <th
                              scope="row"
                              className="flex items-center px-3 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {/* <img className="" src={item.images[0]?.url && item.images[0]?.url} alt="" />  */}
                              <h1>{index}</h1>
                            </th>
                            <th className="px-3 py-3">
                              <div className="text-base font-semibold">
                                {Array.prototype.map
                                  .call(item.orderItems, function (itemName) {
                                    return itemName.name;
                                  })
                                  .join(",")}
                              </div>
                            </th>
                            <td className="px-3 py-3 ">{item?._id}</td>
                            <td className="px-3 py-4 bg-gray text-white font-bold  flex items-center justify-center">
                              {Array.prototype.map
                                .call(item.orderItems, function (itemName) {
                                  return itemName.quantity;
                                })
                                .join(",")}
                            </td>
                            <td className="px-3 py-3  font-medium text-base">
                              {/* <h1>Process Order</h1> */}
                              {/* <form onSubmit={onHandleStatus}>
                                    <select
                                      className="border"
                                      onChange={(e) => setStatus(e.target.value)}
                                    >
                                      <option value="">Choose Category</option>
                                      {item.orderStatus === "Processing" && (
                                        <option value="Shipped">Shipped</option>
                                      )}
      
                                      {item.orderStatus === "Shipped" && (
                                        <option value="Delivered">Delivered</option>
                                      )}
                                    </select>
                                    <button
                                      type="submit"
                                      className="pl-1 pr-1  w-full bg-blue text-white"
                                    >
                                      update
                                    </button>
                                  </form> */}
                              {/* <input
                                    type="text"
                                    value={item.orderStatus}
                                    className="w-[100px] border pl-1 pr-1"
                                    style={{
                                      color:
                                        item.orderStatus === "Delivered"
                                          ? "green"
                                          : "red",
                                    }}
                                  /> */}
                              <NavLink
                                className="text-green"
                                to={`/dashboard/all-orders/${item?._id}`}
                              >
                                Edit
                              </NavLink>
                            </td>
                            <td className="px-3 py-3  font-bold ">
                              <Button
                                className=""
                                onClick={() => {
                                  setOrderInfo(item);
                                  setToggle(true);
                                }}
                              >
                                Info
                              </Button>
                            </td>
                            <td className="px-4 py-4">
                              {new Date(item.createAt).toUTCString()}
                            </td>
                            <td className="px-1 py-3">
                              <Button
                                onClick={() => { 
                                  setOrderDeleteToggle(item._id)
                                  setCheckDelete(!checkDelete)}}
                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-red"
                              >
                                <MdDeleteForever size={25} color="red" />
                              </Button>
                            </td>
                            <div>
                              {checkDelete && orderDeleteToggle === item._id ? (
                                <div className="w-[270px] h-auto bg-white absolute z-10 right-7 top-0 p-3 shadow-xl">
                                  <h1 className="font-semibold text-sm">
                                    Are you ready to delete this order.
                                  </h1>
                                  <div className="flex mt-3">
                                    <button
                                      className="w-1/2 h-10 shadow bg-white"
                                      onClick={() => setCheckDelete(false)}
                                    >
                                      Cancle
                                    </button>
                                    <button
                                      className="w-1/2 h-10 shadow bg-red text-white"
                                      onClick={() => OnHandleDelete(item._id)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>  
                          </tr>
                        ))
                    //     orders.map((item, index) => {
                    //       // console.log(item)
                    //       return (
                    //         <tr
                    //           className="bg-white border-b  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    //           key={index}
                    //         >
                    //           {/* <td className="w-4 p-4">
                    //     <div className="flex items-center">
                    //         <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    //         <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                    //     </div>
                    // </td> */}
                    //           <th
                    //             scope="row"
                    //             className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                    //           >
                    //             {/* <img className="" src={item.images[0]?.url && item.images[0]?.url} alt="" />  */}
                    //             <h1>{index}</h1>
                    //           </th>
                    //           <th className="px-6 py-3">
                    //             <div className="text-base font-semibold">
                    //               {Array.prototype.map
                    //                 .call(item.orderItems, function (itemName) {
                    //                   return itemName.name;
                    //                 })
                    //                 .join(",")}
                    //             </div>
                    //           </th>
                    //           <td className="px-6 py-3 ">{item?._id}</td>
                    //           <td className="px-6 py-4 bg-gray text-white font-bold  flex items-center justify-center">
                    //             {Array.prototype.map
                    //               .call(item.orderItems, function (itemName) {
                    //                 return itemName.quantity;
                    //               })
                    //               .join(",")}
                    //           </td>
                    //           <td className="px-6 py-3  font-medium text-base">
                    //             {/* <h1>Process Order</h1> */}
                    //             {/* <form onSubmit={onHandleStatus}>
                    //               <select
                    //                 className="border"
                    //                 onChange={(e) => setStatus(e.target.value)}
                    //               >
                    //                 <option value="">Choose Category</option>
                    //                 {item.orderStatus === "Processing" && (
                    //                   <option value="Shipped">Shipped</option>
                    //                 )}

                    //                 {item.orderStatus === "Shipped" && (
                    //                   <option value="Delivered">Delivered</option>
                    //                 )}
                    //               </select>
                    //               <button
                    //                 type="submit"
                    //                 className="pl-1 pr-1  w-full bg-blue text-white"
                    //               >
                    //                 update
                    //               </button>
                    //             </form> */}
                    //             {/* <input
                    //               type="text"
                    //               value={item.orderStatus}
                    //               className="w-[100px] border pl-1 pr-1"
                    //               style={{
                    //                 color:
                    //                   item.orderStatus === "Delivered"
                    //                     ? "green"
                    //                     : "red",
                    //               }}
                    //             /> */}
                    //             <NavLink to={`/dashboard/all-orders/${item?._id}`}>
                    //               Edit
                    //             </NavLink>
                    //           </td>
                    //           <td className="px-6 py-3  font-bold ">
                    //             <Button
                    //               className=""
                    //               onClick={() => {
                    //                 setOrderInfo(item);
                    //                 setToggle(true);
                    //               }}
                    //             >
                    //               Info
                    //             </Button>
                    //           </td>
                    //           {/* <td className="px-6 py-4">
                    //             <Link
                    //               href="#"
                    //               className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-green capitalize"
                    //             >
                    //               <BiEdit size={25} />
                    //             </Link>
                    //           </td> */}
                    //           <td className="px-3 py-3">
                    //             <Button
                    //               // onClick={() => OnHandleDelete(item._id)}
                    //               className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-red"
                    //             >
                    //               <MdDeleteForever size={25} color="red" />
                    //             </Button>
                    //           </td>
                    //         </tr>
                    //       );
                    //     })
                  }
                </tbody>
              </table>

              <div>
                {toggle ? (
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-white">
                    <div className="flex items-center justify-between bg-gray p-3">
                      <h1 className="font-bold text-lg capitalize text-white  ">
                        order details
                      </h1>
                      <CgClose
                        size={30}
                        color="blue"
                        onClick={() => setToggle(false)}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="p-3">
                      {orderInfo.orderItems.map((item) => {
                        return (
                          <div>
                            <div className="flex items-center justify-between w-full">
                              <div className="w-2/6">
                                <h1 className="font-semibold text-base">
                                  Product Name
                                </h1>
                                <h1 className="font-medium text-base">
                                  {item.name}
                                </h1>
                              </div>
                              <div className="w-2/6">
                                <h1 className="font-semibold text-base">
                                  Product Price
                                </h1>
                                <h1 className="font-medium text-base">
                                  ₹ {item.price}
                                </h1>
                              </div>
                              <div className="w-2/6">
                                <h1 className="font-semibold text-base">
                                  Product Quantity
                                </h1>
                                <h1 className="font-medium text-base">
                                  {item.quantity}
                                </h1>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="">
                      <h1 className="w-full bg-gray pt-2 pb-2 pl-3  text-white">
                        Address
                      </h1>
                      <div className="flex flex-wrap items-center justify-between w-full pt-2 pb-2 pl-3 space-y-3">
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">Address</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.shippingInfo.address}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">City</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.shippingInfo.city}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">State</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.shippingInfo.state}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">Country</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.shippingInfo.country}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">Phone</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.shippingInfo.phoneNo}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">Pin Code</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.shippingInfo.pinCode}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <h1 className="w-full bg-gray pt-2 pb-2 pl-3  text-white">
                        Shipping Info
                      </h1>
                      <div className="flex flex-wrap items-center justify-between w-full pt-2 pb-2 pl-3 space-y-3">
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">
                            Shipping Price
                          </h1>
                          <h1 className="font-medium text-base">
                            ₹ {orderInfo.shippingPrice}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">Tax Price</h1>
                          <h1 className="font-medium text-base">
                            ₹ {orderInfo.taxPrice}
                          </h1>
                        </div>

                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">
                            Total Price
                          </h1>
                          <h1 className="font-medium text-base">
                            ₹ {orderInfo.totalPrice / 100}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <h1 className="w-full bg-gray pt-2 pb-2 pl-3  text-white">
                        Payment Info
                      </h1>
                      <div className="flex flex-wrap items-center justify-between w-full pt-2 pb-2 pl-3 space-y-3">
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">ID</h1>
                          <h1 className="font-medium text-base">
                            {orderInfo.paymentInfo.id}
                          </h1>
                        </div>
                        <div className="w-2/6">
                          <h1 className="font-semibold text-base">Status</h1>
                          <h1
                            className="font-medium text-base"
                            style={{
                              color:
                                orderInfo.paymentInfo.status === "paid"
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {orderInfo.paymentInfo.status}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
