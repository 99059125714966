import React, { Fragment } from "react";
import "./Loader.css"

const Loder = () => {
  return (
    <Fragment>
    <div role="status" className="mt-[200px] mb-[200px]">
      <div className="spinner"></div>
    </div>
    </Fragment>
  );
};

export default Loder;
