import React from "react";
import { Link, useLocation } from "react-router-dom";

const WashingMachine = () => {
  const { state } = useLocation();
  const { data = {} } = state;
  return (
    <div>
        <div className="h-[200px] flex flex-col items-center justify-center font-bold text-lg">
            Best Washing Machine
            <span>{data?.length}</span>
        </div>
      <div className="flex items-center justify-center flex-wrap border-t pt-10 pb-10">
        {data.map((item) => {
          return (
            <Link to={`/product/${item._id}`} className="flex flex-col border items-center justify-center m-2 p-1">
              <div className="w-[170px] h-[170px]">
                <img className="w-full h-full" src={item.images[0]?.url} alt="" />
              </div>
              <h1>{item.name}</h1>
              <p>₹ {((item.price * item.gst) / 100) + item.price}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default WashingMachine;
