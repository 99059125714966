import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const CartItem = ({ Item, removeItem }) => {
  const showMoreText = (item) => {
    if (item.length <= 70) return item;
   
    if (item.length > 70) {
      return (
        <>
          <p>
            {item.slice(0, 70)} ...
          </p>
        </>
      );
    }
  };
  
  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <Link to={`/product/${Item?.product}`} className="w-[200px] xl:w-[170px] h-[200px] p-1 md:p-4">
          <img className="w-auto h-auto" src={Item?.image} alt={Item.name} />
        </Link>
        <div className="md:w-[130px] xl:w-[300px]  ml-2 md:ml-3">
          <Link
            className="capitalize hover:text-orange hover:underline pr-5 md:pr-0"
            to={`/product/${Item.product}`}
          >
            {showMoreText(Item.name)}
          </Link>
          <div className="flex mt-3">
          <s className="capitalize opacity-75">{` ₹ ${Item.cutoffprice.toFixed()}`}</s>
          <p className="capitalize  ml-2">{` ₹ ${Item.price.toFixed()}`}</p>
          <p className="capitalize  ml-2 text-green">{` ${(((Item.cutoffprice - Item.price)/Item.cutoffprice)*100).toFixed()} % off`}</p>
          </div>
          <button
            className="mt-2 text-white font-normal  text-base bg-red pl-3 pr-3 pt-1 pb-1 uppercase"
            onClick={() => removeItem(Item._id)}
          >
            remove
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default CartItem;
