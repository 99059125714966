import axios from "axios";
import {
  CREATE_REVIEW_FAIL,
  CREATE_REVIEW_REQUIEST,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  GET_ALL_REVIEW_FAIL,
  GET_ALL_REVIEW_REQUIEST,
  GET_ALL_REVIEW_SUCCESS,
  GET_REVIEW_BY_ID_FAIL,
  GET_REVIEW_BY_ID_REQUEST,
  GET_REVIEW_BY_ID_SUCCESS,
  REVIEW_VERIFY_FAIL,
  REVIEW_VERIFY_REQUEST,
  REVIEW_VERIFY_SUCCESS,
} from "../constants/reviewConstant";

export const createReview = (review) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_REVIEW_REQUIEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(`/api/v1/review`, review, config);
    console.log(data)
    dispatch({
      type: CREATE_REVIEW_SUCCESS,
      payload: data.success,
    });

  } catch (error) {
    dispatch({
      type: CREATE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllReviews = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_REVIEW_REQUIEST,
    });

    const { data } = await axios.get("/api/v1/allproducts/reviews");
    console.log(data);

    dispatch({
      type: GET_ALL_REVIEW_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get review by id

export const getReviewById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_BY_ID_REQUEST,
    });
    const { data } = await axios.get(`/api/v1/reviews?id=${id}`);

    dispatch({
      type: GET_REVIEW_BY_ID_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: GET_REVIEW_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const reviewVerify = (review) => async (dispatch) => {
  try {
    dispatch({
      type: REVIEW_VERIFY_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put("/api/v1/review/verify", review, config);

    dispatch({
      type: REVIEW_VERIFY_SUCCESS,
      payload: data.verify,
    });
  } catch (error) {
    dispatch({
      type: REVIEW_VERIFY_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const deleteReview = (review)=>async(dispatch)=>{

  try {
    dispatch({
      type: DELETE_REVIEW_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json", 
      },
    };
  

    const {data} = await axios.delete("/api/v1/reviews",review,config);

    console.log(data)

    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data
    })
  } catch (error) {
      dispatch({
        type: DELETE_REVIEW_FAIL,
        payload: error.response.data.message
      })
  } 
}
