import React from "react";
import MetaData from "../MetaData/MetaData";

const ShippingDelivery = () => {
  return (
    <div>
    <MetaData title={`Shipping Store Online`} />
    <div className="pt-20 flex  justify-center flex-col pl-3 md:pl-20 pr-2 md:pr-20 pb-20 font-roboto bg-gray-light">
      <h1 className="text-center font-bold text-xl">
        Shipping and Delivery Information
      </h1>

      <div className="mt-5 md:mt-2 ">
        <p className="font-semibold text-lg">Delivery Times:</p>
        <p className="font-medium text-lg mt-2">
          At{" "}
          <a href="https://gshoppi.com/" className="text-blue">
            gshoppi.com
          </a>
          , we prioritize the satisfaction of our customers by ensuring swift
          and reliable delivery of your orders. We are committed to exceeding
          your expectations by providing timely delivery within 72 hours of
          order placement. Our dedicated team works diligently to process and
          ship your orders promptly, typically within 24 hours of order
          confirmation. Rest assured, your package is handled with care and
          dispatched efficiently to reach you as swiftly as possible.
        </p>
      </div>

      <div className="mt-2 ">
        <h1 className="font-bold text-lg">Shipping Carriers:</h1>
        <p className="font-medium text-lg mt-2">
          Orders are processed and shipped swiftly, typically within 24 hours of
          order confirmation. Our dedicated team works tirelessly to ensure that
          your package is prepared and dispatched as quickly as possible.
        </p>
      </div>
      <div className="mt-2 ">
        <h1 className="font-bold text-lg">Shipping Costs:</h1>
        <p className="font-medium text-lg mt-2">
          We are pleased to offer free standard shipping on all orders within
          the domestic regions. There are no additional shipping charges for
          standard delivery.
        </p>
      </div>

      <div className="mt-2 ">
        <h1 className="font-bold text-lg">Tracking Your Order:</h1>
        <p className="font-medium text-lg">
          Once your order has been shipped, you will receive a confirmation
          email containing tracking information.
        </p>
      </div>

      {/* <div className="mt-2 ">
        <p className="font-medium text-lg">
          For detailed information about our shipping policies, including any
          exceptions to the 72-hour delivery timeframe, please visit our
          Shipping Policy page [Link to Shipping Policy Page]. 
                  </p>
      </div> */}

      <div className="mt-2 ">
        <h1 className="font-bold text-lg">Contact Us:</h1>
        <p className="font-medium text-lg flex flex-col">
          If you have any questions or concerns regarding our Shipping and
          Delivery Policy, please feel free to contact our Customer Service
          team. We are here to assist you and ensure your shopping experience
          with us is smooth and satisfactory.
          <span>
            {" "}
            <a
              href="mailto:support@gshoppi.com"
              className="text-blue ml-1 mr-1"
            >
              support@gshoppi.com
            </a>
            or{" "}
            <a href="tel:1800 12398 6666" className="text-blue ml-1 mr-1">
              1800 12398 6666
            </a>
          </span>
        </p>
      </div>
    </div>
    </div>
  );
};

export default ShippingDelivery;
