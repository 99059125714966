export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST"
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS"
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL"

export const GET_BLOG_BY_ID_REQUEST = "GET_BLOG_BY_ID_REQUEST"
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS"
export const GET_BLOG_BY_ID_FAIL = "GET_BLOG_BY_ID_FAIL"


export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST"
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS"
export const GET_ALL_BLOG_FAIL = "GET_ALL_BLOG_FAIL"