import axios from "axios";
import React, { useEffect, useState } from "react";
import SubAdminSidebar from "../../SubAdminSidebar";

const GetAllImage = () => {
  const [allimage, setAllImage] = useState();

  const getALlImages = async () => {
    const response = await axios.get("/api/v1/subadmin/all-images");
    setAllImage(response.data.allImage);
  };

  useEffect(() => {
    getALlImages();
  }, []);

  console.log(allimage);
  return (
    <div className="pl-10 pr-10 pt-10 pb-10 bg-gray-light flex w-full">
      <SubAdminSidebar />
      <div className="ml-5 bg-white rounded pl-5 pt-3 w-full">
        <div
          id="stats"
          className="flex flex-col"
        >
          <div>
            <h1>All images</h1>
          </div>

          <div className="flex items-center flex-wrap">
          {allimage &&
            allimage.map((image, index) => {
              return (
                <div key={image._id} className="">
                  {image.productImage.map((item) => {
                    return (
                      <div className="w-[300px] m-2" key={item.imageId}>
                        <img src={item?.url} alt="" />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetAllImage;
