import React, { Fragment } from 'react'
import { BiPhone } from 'react-icons/bi'
import { CgMailOpen } from 'react-icons/cg'
import MetaData from '../MetaData/MetaData';

const Contact = () => {
  return (
    <Fragment>
      <div>
      <MetaData title={`Online Shopping`} />
      <div className="pb-5 pt-3 bg-gray-light">
        <div className="w-full h-[350px] flex flex-col items-center justify-end pb-10 opacity-75" >
          <h1 className="font-bold text-2xl text-black">
            WE WOULD LOVE TO HELP YOU
          </h1>
          <p className="font-medium text-lg text-black">REACH US AT:</p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center w-full h-[500px]">
          <div className="flex flex-col m-2 mt-10">
            <div className="flex items-center justify-center w-[350px] h-[50px] bg-red">
              <BiPhone color="white" />
              <h1 className="font-bold text-lg text-white ml-2">Call Us</h1>
            </div>
            <div className="border flex flex-col items-center justify-center h-[150px]">
              <h1 className="font-semibold text-lg">
                For E-Commerce Related Queries
              </h1>
              <a href="tel: 1800 889 1055" className="text-blue">
                1800 12398 6666
              </a>
              <a href="tel: 9353400254" className="text-blue">
              9353400254
              </a>
              
              
              <p>( 9:30 AM to 6:00 PM )</p>
            </div>
          </div>
          <div className="flex flex-col m-2 mt-10">
            <div className="flex items-center justify-center w-[350px] h-[50px] bg-red">
              <CgMailOpen color="white" />
              <h1 className="font-bold text-lg text-white ml-2">Email Us</h1>
            </div>
            <div className="border flex flex-col items-center justify-center h-[150px]">
              <a
                href="mailto:support@gshoppi.com"
                className="font-semibold text-lg text-blue"
              >
                support@gshoppi.com
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
}

export default Contact