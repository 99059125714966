import React from 'react'
import { Link } from 'react-router-dom'

const PageNoteFound = () => {
  return (
    <div className='font-roboto bg-gray-light flex items-center  justify-center flex-col w-full h-screen'>
        <h1 className='font-bold text-7xl '>404</h1>
        <p className='uppercase font-semibold text-xl pt-3'>opps! page not found</p>
        <Link to="/" className='font-semibold text-base  pl-5 pr-5 pt-2 pb-2 mt-3 text-white bg-blue hover:bg-white hover:text-blue uppercase rounded-full'>return home</Link>
    </div>
  )
}

export default PageNoteFound