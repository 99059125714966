import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  createProductReducer,
  getAllProductBySlug,
  getSearchProductsReducers,
  likeDislikeProduct, 
  productDeleteReducer,
  productDetailsReducer,
  productReducer,
   
  wishListForProduct,
} from "./reducers/productReducers";
import {
  forgotPasswordReducer,
  profileReducer,
  getAllUser,
  userReducers,
  updateUserRole,
  getWishListProduct,
} from "./reducers/userReducers";
import { cartReducers } from "./reducers/cartReducers";
import { getAllOrdersReducers, myOrderReducer, newOrderReducers, orderDetailsReducer, orderReducer } from "./reducers/orderReducers";
import { createCategoryReducer, getAllCategoryReducer } from "./reducers/CategoryReducer";
import { createBlogReducer, getBlogDetailsReducer } from "./reducers/blogReducer";
import { createBrandReducer } from "./reducers/BrandReducer";
import { shippingReducers } from "./reducers/shippingAddressReducers";
import { allreviews, reviewVerifyReducer } from "./reducers/reviewReducer";
// import { createReview } from "./actions/reviewAction";

const reducer = combineReducers({
  createProduct: createProductReducer,
  products: productReducer,
  productDetails: productDetailsReducer,
  allproduct: getAllProductBySlug,
  product: productDeleteReducer,
  user: userReducers,
  profile: profileReducer,
  allUser: getAllUser,
  order : orderReducer,
  userRole: updateUserRole,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducers,
  shippingAddress: shippingReducers,
  newOrder : newOrderReducers,
  myOrders: myOrderReducer,
  orderDetails: orderDetailsReducer,
  allOrders:getAllOrdersReducers,
  review:reviewVerifyReducer,
  allreviews: allreviews,
  category: createCategoryReducer,
  allCategory: getAllCategoryReducer,
  wishlist: wishListForProduct,
  getwishList : getWishListProduct,
  searchProducts: getSearchProductsReducers,
  likeProduct : likeDislikeProduct,
  createBlog: createBlogReducer,
  blogById: getBlogDetailsReducer,
  brand: createBrandReducer
});


let initalState = {
  
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
  },
  
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initalState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
