import React, { Fragment, useState } from "react";
import CheckOutSetps from "../ShippingInfo/CheckOutSetps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../../MetaData/MetaData";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import {
  addToCartItems,
  removeItemsToCart,
} from "../../../actions/cartActions";
import { Link, NavLink } from "react-router-dom";
// import Loader from "../../Loader/Loader";
import Payment from "../Payment/Payment";
import { IoIosArrowDown } from "react-icons/io";
import { FaCartPlus, FaArrowLeftLong } from "react-icons/fa6";
import "./ConfirmOrder.css";

const ConfirmOrder = () => {
  const { cartItems, shippingInfo } = useSelector((state) => state.cart);
  let { user } = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(false);
  // console.log(shippingInfo)
  // if(!user && localStorage.getItem('user_info')) {
  //   user = JSON.parse(localStorage.getItem('user_info')).user;
  //   isAuthenticated =  JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  // console.log(isAuthenticated)
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  console.log(shippingInfo);

  const subTotal = cartItems.reduce(
    (acc, item) => acc + item.cutoffprice * item.quantity,
    0
  );

  const discountPrice = cartItems.reduce(
    (acc, item) =>
      acc + item.cutoffprice * item.quantity - item.price * item.quantity,
    0
  );

  const sellingPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  // const shippingCharges = subTotal > 1000 ? 0 : 200;

  // const tax = subTotal * gst

  // const totalPrice = subTotal + tax + shippingCharges;
  // const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pincode}, ${shippingInfo.country},`

  const showMoreText = (item) => {
    if (item.length <= 70) return item;

    if (item.length > 70) {
      return (
        <>
          <p>{item.slice(0, 70)} ...</p>
        </>
      );
    }
  };

  const increaseQuentity = (id, quantity, stock) => {
    const addQty = quantity + 1;

    if (stock <= quantity) {
      return;
    }

    dispatch(addToCartItems(id, addQty));
  };

  const decreaseQuentity = (id, quantity) => {
    const newQnty = quantity - 1;
    if (1 >= quantity) {
      return;
    }
    dispatch(addToCartItems(id, newQnty));
  };

  const removeItem = (id) => {
    dispatch(removeItemsToCart(id));
  };

  // const OnHandlePayment = () => {
  //   const data = {
  //     subTotal,
  //     totalPrice,
  //     tax,
  //     shippingCharges
  //   };

  //   sessionStorage.setItem("orderInfo", JSON.stringify(data));
  //   navigate("/process/payment");
  // };

  return (
    <Fragment>
      <MetaData title={`${user.name} - orders`} />
      <CheckOutSetps activeSteps={1} />
      {/* {
        loading ? <div className="pt-20 pl-[500px]">
        <Loader />
      </div> :  */}
      <div className="min-h-screen w-full pb-10 flex flex-col md:flex-row bg-gray-light">
        <div className="w-full md:w-4/6">
          <div className="w-full pl-2 md:pl-10 pt-5 md:pt-10 pr-2 md:pr-0">
            <div
              className="font-bold h-10 flex items-center justify-between i text-lg uppercase bg-[#1976D2] text-white pl-3 pt-1 cursor-pointer"
              onClick={() => setToggle(!toggle)}
            >
              <h1>your Address</h1>
              <div className="pr-5">
                <IoIosArrowDown />
              </div>
            </div>
            {/* <div className='w-full h-[1px] bg-black  mb-1'></div> */}
            {toggle ? (
              <div className="w-full flex flex-col bg-white  p-3 shadow-xl">
                <div className="font-medium text-base flex items-center">
                  <p className="font-medium text-base">{shippingInfo.name}</p> 
                  <p className="ml-5 font-medium text-base  capitalize">
                  {shippingInfo.phoneNo}
                  </p>
                  <p className="ml-5 font-medium capitalize pl-5 pr-5 pt-1 pb-1 bg-gray-light rounded">
                    {shippingInfo.addressType}
                  </p>
                </div>
                {
                  shippingInfo.companyName.length > 0 ?  <div className="font-medium text-base flex mt-2">
                  <p className="w-[150px]">Company Name</p> :
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.companyName}
                  </p>
                </div> : ""
                }

{
                  shippingInfo.gstNumber.length > 0 ?  <div className="font-medium text-base flex mt-2">
                  <p className="w-[150px]">GST Number</p> :
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.gstNumber}
                  </p>
                </div> : ""
                }
               
                

                <div className="font-medium text-base flex mt-2">
                  <p className="w-[150px]">Address</p> :
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.address}
                  </p>
                </div>
                <div className="font-medium text-base flex">
                  <p className="w-[150px]">Country</p> :
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.country}
                  </p>
                </div>
                <div className="font-medium text-base flex ">
                  <p className="w-[150px]">State</p> :
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.state}
                  </p>
                </div>
                <div className="font-medium text-base flex">
                  <p className="w-[150px]">City</p> :{" "}
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.city}
                  </p>
                </div>
                <div className="font-medium text-base flex">
                  <p className="w-[150px]">Landmark</p> :{" "}
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.landmark}.
                  </p>
                </div>
                <div className="font-medium text-base flex">
                  <p className="w-[150px]">PinCode</p> :{" "}
                  <p className="ml-5 font-normal capitalize">
                    {shippingInfo.pinCode}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="w-full pl-2 md:pl-10 pt-5 md:pt-2 pr-2 md:pr-0">
            <h1 className="font-bold h-10 flex i text-lg uppercase bg-[#1976D2] text-white pl-3 pt-1">
              order summery
            </h1>
            <div className="shadow rounded-b-lg pb-2 bg-white">
              {cartItems.length > 0 ? (
                cartItems &&
                cartItems.map((item) => {
                  return (
                    <>
                      <div
                        key={item.product}
                        className="w-full h-auto  flex items-center justify-between  border-t-[2px]  border-gray border-opacity-25"
                      >
                        <div className=" p-2 flex flex-col items-center justify-center">
                          <NavLink
                            to={`/product/${item.product}`}
                            className="w-[100px] md:w-[150px]"
                          >
                            <img
                              className=""
                              src={item.image}
                              alt={item.name}
                            />
                          </NavLink>
                          <div className="flex mt-2 bg-gray rounded">
                            <button
                              onClick={() =>
                                decreaseQuentity(item.product, item.quantity)
                              }
                              className="w-[30px] p-1 rounded  border-white h-full flex items-center justify-center bg-gray"
                            >
                              <AiOutlineMinus size={20} color="white" />
                            </button>
                            <input
                              className="w-[40px] text-center border border-gray  outline-none"
                              type="number"
                              value={item.quantity}
                              readOnly
                            />
                            <button
                              onClick={() =>
                                increaseQuentity(
                                  item.product,
                                  item.quantity,
                                  item.stock
                                )
                              }
                              className="w-[30px] p-1 rounded  border-white h-full  flex items-center justify-center bg-gray"
                            >
                              <AiOutlinePlus size={20} color="white" />
                            </button>
                          </div>
                        </div>
                        <div className="ml-5 w-5/6">
                          <NavLink
                            to={`/product/${item.product}`}
                            className=" hover:text-orange hover:underline font-medium text-base capitalize pt-5"
                          >
                            {showMoreText(item.name)}
                          </NavLink>
                          <div className="flex">
                            <h1 className="capitalize font-medium text-base mt-2 mb-2">
                              <s className="font-medium text-base ml-2 opacity-75">
                                {`₹ ${item?.cutoffprice?.toFixed()}`}
                              </s>
                            </h1>
                            <h1 className="capitalize font-medium text-base mt-2 mb-2">
                              <span className="font-medium text-base ml-2 ">
                                {`₹ ${item?.price?.toFixed()}`}
                              </span>
                            </h1>

                            <h1 className="capitalize font-medium text-base mt-2 mb-2">
                              <span className="font-medium text-base ml-3 text-green">
                                {`${(
                                  ((item?.cutoffprice - item?.price) /
                                    item?.cutoffprice) *
                                  100
                                ).toFixed()} % off`}
                              </span>
                            </h1>
                          </div>
                          <button
                            className="bg-red h-8 pl-5 pr-5 pt-1 pb-1 rounded md:w-1/6 mt-1 mb-1 text-white font-bold text-semibold capitalize"
                            onClick={() => removeItem(item.product)}
                          >
                            remove
                          </button>
                        </div>
                        {/* <p className="font-medium text-base flex items-center justify-center w-[200px]">
                        {item.quantity}
                        <span className="ml-1 font-bold text-lg">x</span>
                        <p className="mr-1 ml-1">{`${(
                          item.price * item.quantity
                        ).toFixed()}`}</p>
                        =
                        <p className="ml-1">{`₹ ${(
                          item.price * item.quantity
                        ).toFixed()}`}</p>
                      </p> */}
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center pt-10 pb-20">
                  <h1 className="font-semibold text-lg ">
                    Your Confirm order is empty. Please add your product.
                  </h1>
                  <p className="mt-10">
                    <FaCartPlus size={40} />
                  </p>
                  <Link to="/" className="mt-10">
                    <button className="shopingOrder text-black rounded pl-5 pr-5 pt-2 pb-2  font-semibold text-sm flex items-center justify-center">
                      {" "}
                      <span className="mr-3">
                        <FaArrowLeftLong />
                      </span>{" "}
                      Go to Shop
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/6 pl-2 md:pl-5 pt-10 pr-2 md:pr-10">
          <h1 className="font-bold h-10 flex i text-lg  bg-[#1976D2] text-white pl-3 pt-1 uppercase">
            Price details
          </h1>

          <div className="pl-2 pr-2 bg-white pb-10">
            <div className="flex items-center justify-between mt-2">
              <p className="font-medium text-base capitalize">
                Price ({cartItems.length} items)
              </p>
              <h1 className="font-bold text-lg">{` ₹ ${subTotal.toFixed()}`}</h1>
            </div>
            <div className="flex items-center justify-between mt-2">
              <h1 className="font-medium text-base capitalize">Discount</h1>
              <p className="font-bold text-base text-green">
                - ₹ {discountPrice}
              </p>
            </div>
            <div className="flex items-center justify-between mt-2">
              <h1 className="font-medium text-base capitalize">
                delivery charges
              </h1>
              <p className="font-bold text-base text-green">Free</p>
            </div>
            <div className="flex items-center justify-between mt-5 border-t">
              <h1 className="font-bold text-base capitalize">Amount payable</h1>
              <h1 className="font-bold text-lg">{` ₹ ${sellingPrice.toFixed(
                2
              )}`}</h1>
            </div>
          </div>

          <Payment price={sellingPrice} />
          {/* <button
            onClick={OnHandlePayment}
            className="w-full capitalize bg-[#1976D2] h-10  text-white font-semibold text-lg "
          >
            Procced to payment
          </button> */}
        </div>
      </div>
      {/* } */}
    </Fragment>
  );
};

export default ConfirmOrder;
