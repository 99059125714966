import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../actions/categoryAction";
import { toast } from "react-toastify";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const { category, error } = useSelector((state) => state.allCategory);
  // console.log(category)
  const dispatch = useDispatch();
  // console.log(category)
  // console.log(category)

  // render category
  const renderCategory = (category) => {
    if (Array.isArray(category))
      return <>{category.map((e, i) => renderCategory(e))}</>;

    if (typeof category === "object") {
      // console.log(category.parentId);
      if (category.name)
        return (
          <li
            className="font-semibold text-lg list-disc list-none listItem"
            key={category.name}
          >
            {category.parentId ? (
              <Link
                className=""
                to={`/product-category/${category.slug}?id=${category._id}`}
              >
                {category.name}
              </Link>
            ) : (
              <span className="cursor-pointer">{category.name}</span>
            )}

            {category.children.length > 0 ? (
              <ul className="font-medium z-20 ">
                {renderCategory(category.children)}
              </ul>
            ) : null}
          </li>
        );
    }
  };

  // const OnHandleCategory = (parentId) => {
  //   // console.log(parentId);
  //   const categoryParent = category.find((element) => element._id === parentId);
  //   if (categoryParent) {
  //     dispatch(getCategoryByParentId(categoryParent._id));
  //   }
  // };

  useEffect(() => {
    if (error) {
      toast(error);
    }
    dispatch(getAllCategory());
  }, [dispatch, error]);

  return (
    <div className="w-full  bg-white text-white shadow-xl h-10 ">
      <ul className="category  overflow-x-scroll">
        {category?.length > 0 ? renderCategory(category) : null}
      </ul>
    </div>
  );
};

export default Header;
