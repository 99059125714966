import React, { Fragment } from 'react'
import "./CheckOutSetps.css"
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const CheckOutSetps = ({activeSteps}) => {
    const steps = [
        {
            id:1,
            label: "Shoping details"
        },
        {
            id: 2,
            label: "Confirm order"
        },
        // {
        //     label: "Payment"
        // },
    ]
  return (
    <Fragment>
    <div className='flex items-center justify-center pt-10 bg-gray-light'>
    <div className='flex items-center justify-between w-full md:w-3/6'>
        <Box sx={{width: '100%'}}>
        <Stepper activeStep={activeSteps} alternativeLabel>
        {
           steps?.map((item,index)=>(
            <Step key={item.id}
            active = {activeSteps === index ? true : false}
            completed = {activeSteps >= index ? true : false}
            >
                <StepLabel>{item.label}</StepLabel>
            </Step>
               
            ))
        }
        </Stepper>
        </Box>
        </div>
    </div>
    </Fragment>
  )
}

export default CheckOutSetps