import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getBlogDetails } from "../../../actions/blogAction";
import * as DOMPurify from "dompurify";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { BsInstagram } from "react-icons/bs";
import { SlLike } from "react-icons/sl";
import { SlDislike } from "react-icons/sl";

const BlogDetails = () => {
  const id = useParams();
  console.log(id);
  const dispatch = useDispatch();

  const { blogId } = useSelector((state) => state.blogById);
  console.log(blogId);
  useEffect(() => {
    dispatch(getBlogDetails(id));
  }, [dispatch, id]);

  return (
    <div className="bg-white pl-3 md:pl-10 pt-3 md:pt-10 pb-3 md:pb-10 pr-3 md:pr-10">
      <div>
        <img src="" alt="" />
      </div>
      <div className="mt-2 mb-2">
        <Link className="font-medium text-sm text-yellow" to="/">
          Home /{" "}
          <Link to="/blogs">
            blogs / <Link className="text-black">{blogId?.title}</Link>
          </Link>
        </Link>
      </div>
      <div className="w-full">
        <h1 className="capitalize font-semibold text-lg">{blogId?.title}</h1>
        <p className="font-normal text-sm">{Date(blogId?.createdAt)}</p>
        <div className="mt-10">
          {blogId?.content.map((con) => {
            return (
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(con) }}
              />
            );
          })}
        </div>
      </div>
      <div className="mt-10 w-3/6">
        <div className="border-t">
          <div className="flex items-center justify-end mt-4 space-x-4">
            <div className="cursor-pointer">
              <SlLike size={25} />
            </div>
            <div className="cursor-pointer">
              <SlDislike size={25} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/6 border-t mt-10">
        <div className="w-full  md:w-3/6 flex items-center justify-center space-x-2 mt-3">
          <FacebookShareButton
            url={`https://gshoppi.com/blogs/${blogId?.title}/${blogId?._id}`}
            title={blogId?.title}
          >
            <FacebookIcon size={40} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={`https://gshoppi.com/blogs/${blogId?.title}/${blogId?._id}`}
            title={blogId?.title}
          >
            <LinkedinIcon size={40} />
          </LinkedinShareButton>
          <InstapaperShareButton
            url={`https://gshoppi.com/blogs/${blogId?.title}/${blogId?._id}`}
            title={blogId?.title}
          >
            <BsInstagram size={40} color="red" />
          </InstapaperShareButton>

          <WhatsappShareButton
            url={`https://gshoppi.com/blogs/${blogId?.title}/${blogId?._id}`}
            title={blogId?.title}
          >
            <WhatsappIcon size={40} />
          </WhatsappShareButton>

          <TwitterShareButton
            url={`https://gshoppi.com/blogs/${blogId?.title}/${blogId?._id}`}
            title={blogId?.title}
          >
            <TwitterIcon size={40} />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
