import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import logo from "../../image/gshoppiLogo.png";
import { MdCurrencyRupee } from "react-icons/md";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFF0",
    padding: 8,
  },
  container: {
    flex: 1,
    border: "1px solid #ccc",
    padding: 5,
    // margin: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  main: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderTopWidth: 1,
    marginBottom: 10,
  },
  gstSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  registration: {
    width: "48%",
    marginTop: 5,
  },
  shipping: {
    width: "50%",
    marginLeft: 3,
  },
  billing: {
    width: "50%",
  },
  phoneNo: {
    fontSize: 12,
    fontWeight: 600,
  },
  shippingAddress: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  details: {
    fontSize: 12,
    marginBottom: 5,
  },
  // gstDetailsMain:{
  //   fontSize: 12,
  //   fontWeight: 600,
  //   display: "flex",
  //   flexDirection: "column",
  //   marginBottom: 5,
  //   width: "100%"

  // },
  gstDetails: {
    fontSize: 12,
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  userName: {
    fontSize: 12,
    marginBottom: 3,
    marginTop: 5,
  },
  details_heading: {
    fontSize: 15,
    fontWeight: 900,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },

  tableCol: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColId: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColName: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColQty: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColMrp: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColDiscount: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColNetPrice: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColTaxRate: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerTaxRate: {
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 8,
    paddingLeft: 5,
    paddingBottom: 5,
  },

  headerColTaxType: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColTaxAmount: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  headerColTotalPrice: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#D3D3D3",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  TabletotalPrice: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    borderBottom: 1,
    fontWeight: 600,
    fontSize: 20,
  },
  text: {
    margin: 5,
    fontSize: 10,
  },
  textTotal: {
    fontSize: 15,
    fontWeight: 500,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  textTotals: {
    fontSize: 15,
    fontWeight: 500,
    borderLeft: 1,
    borderRight: 1,
    width: "10%",
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
  numberTowordsHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    padding: 5,
  },
  numberToword: {
    fontSize: 13,
  },
  AuthorizedSectionHead: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 50,
  },
  AuthorizedSection: {
    borderBottomWidth: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  AuthorizedSectionText: {
    fontSize: 10,
    margin: 5,
  },
  invoiceWhisess: {
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    margin: 5,
  },
  invoiceTxt: {
    fontSize: 7,
  },
  companyAddress: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    bottom: 5,
    left: 0,
    right: 0,
  },
  link: {
    fontSize: 10,
    margin: 2,
  },
});

// Invoice component
const Invoice = ({ Invoice }) => {
  // console.log(Invoice);

  const taxDistribution = (netPrice, gst) => {
    const withoutGst = (netPrice / (gst + 100)) * 100;
    const gstValue = (netPrice - withoutGst) / 2;
    return gstValue?.toFixed(2);
  };

  const convert = (num) => {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const bigs = ["", "Thousand", "Million", "Billion", "Trillion"];

    if (num === 0) return "Zero";

    const convertUnder1000 = (num) => {
      let result = "";
      if (num >= 100) {
        result += ones[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num >= 20) {
        result += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      if (num > 0) {
        if (num >= 10) {
          result += teens[num - 10];
        } else {
          result += ones[num];
        }
      }
      return result.trim();
    };

    let result = "";
    let index = 0;
    while (num > 0) {
      if (num % 1000 !== 0) {
        result =
          convertUnder1000(num % 1000) + " " + bigs[index] + " " + result;
      }
      num = Math.floor(num / 1000);
      index++;
    }
    return result.trim();
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Image src={logo} style={{ width: 200 }} />
          </View>
          <View>
            <Text style={styles.details}>
              GST Registration No : 29AAWCS7290P2ZW
            </Text>
          </View>
          {/* top heading */}
          <View style={styles.main}>
            <View style={styles.registration}>
              <Text style={styles.details}>Order Id: {Invoice?._id}</Text>
              <Text style={styles.details}>
                Order Date : {new Date(Invoice?.createAt).toLocaleDateString()}
              </Text>
              <Text style={styles.details}>Invoice Number : #1234567890</Text>
              {/* <Text style={styles.details}>
                Invoice Date :
                {new Date(Invoice?.createAt).toLocaleDateString()}
              </Text> */}
              {/* <Text style={styles.details}>Date: 25 / 04/ 2024</Text> */}
            </View>
            <View style={styles.gstSection}>
              <View style={styles.billing}>
                <Text style={styles.details_heading}>Billing Address : </Text>
                <Text style={styles.userName}>
                  {Invoice?.shippingInfo?.name}
                </Text>
                <Text style={styles.details}>
                  {Invoice?.shippingInfo.address},
                  {Invoice?.shippingInfo.landmark},
                  {Invoice?.shippingInfo.locality},{Invoice?.shippingInfo.city},
                  {Invoice?.shippingInfo.state},{Invoice?.shippingInfo.pinCode},
                </Text>
                <Text style={styles.phoneNo}>
                  Phone : {Invoice?.shippingInfo.phoneNo}
                </Text>
                <View style={styles.gstDetailsMain}>
                  {Invoice?.shippingInfo?.companyName?.length > 0 ? (
                    <Text style={styles.gstDetails}>
                      {`Company Name : ${Invoice?.shippingInfo?.companyName}`}
                    </Text>
                  ) : (
                    ""
                  )}

                  {Invoice?.shippingInfo?.gstNumber?.length > 0 ? (
                    <Text style={styles.gstDetails}>
                      {`GST Number : ${Invoice?.shippingInfo?.gstNumber}`}
                    </Text>
                  ) : (
                    ""
                  )}
                </View>
              </View>
              <View style={styles.shipping}>
                <Text style={styles.details_heading}>Shipping Address : </Text>
                <Text style={styles.userName}>
                  {Invoice?.shippingInfo?.name}
                </Text>
                <Text style={styles.details}>
                  {Invoice?.shippingInfo.address},
                  {Invoice?.shippingInfo.landmark},
                  {Invoice?.shippingInfo.locality},{Invoice?.shippingInfo.city},
                  {Invoice?.shippingInfo.state},{Invoice?.shippingInfo.pinCode},
                </Text>
                <Text style={styles.phoneNo}>
                  Phone : {Invoice?.shippingInfo.phoneNo}
                </Text>
              </View>
            </View>
          </View>

          {/* invoice section */}

          <View style={styles.row}>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.headerColId}>
                  <Text style={styles.text}>SI. No.</Text>
                </View>
                <View style={styles.headerColName}>
                  <Text style={styles.text}>Product Name</Text>
                </View>
                <View style={styles.headerColQty}>
                  <Text style={styles.text}>Qty</Text>
                </View>
                <View style={styles.headerColMrp}>
                  <Text style={styles.text}>MRP </Text>
                </View>
                <View style={styles.headerColDiscount}>
                  <Text style={styles.text}>Discount </Text>
                </View>
                <View style={styles.headerColNetPrice}>
                  <Text style={styles.text}>Net Price </Text>
                </View>

                <View style={styles.headerColTaxRate}>
                  <Text style={styles.text}>Tax Rate </Text>
                </View>
                <View style={styles.headerColTaxAmount}>
                  <Text style={styles.text}>Tax Type</Text>
                </View>
                <View style={styles.headerColTaxType}>
                  <Text style={styles.text}>Tax Amount </Text>
                </View>

                <View style={styles.headerColTotalPrice}>
                  <Text style={styles.text}>Total Amount </Text>
                </View>
              </View>
              {/* Table Body */}

              {Invoice?.orderItems &&
                Invoice?.orderItems.map((item, index) => (
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.headerColId}>
                      <Text style={styles.text}>{index + 1}</Text>
                    </View>
                    <View style={styles.headerColName}>
                      <Text style={styles.text}>{item.name}</Text>
                    </View>
                    <View style={styles.headerColQty}>
                      <Text style={styles.text}>{item.quantity}</Text>
                    </View>
                    <View style={styles.headerColMrp}>
                      <Text style={styles.text}>{`${
                        item.MRP * item.quantity
                      }`}</Text>
                    </View>
                    <View style={styles.headerColDiscount}>
                      <Text style={styles.text}>
                        {`${(item.MRP - item.price) * item.quantity}`}
                      </Text>
                    </View>
                    <View style={styles.headerColNetPrice}>
                      <MdCurrencyRupee />
                      <Text style={styles.text}>{`${
                        item.price * item.quantity
                      }`}</Text>
                    </View>
                    <View style={styles.headerColTaxRate}>
                      <Text style={styles.headerTaxRate}>
                        {((item.gst / 2) * item.quantity).toFixed(2)} %
                      </Text>
                      <Text style={styles.headerTaxRate}>
                        {((item.gst / 2) * item.quantity).toFixed(2)} %
                      </Text>
                    </View>
                    <View style={styles.headerColTaxAmount}>
                      <Text style={styles.headerTaxRate}>SGST</Text>
                      <Text style={styles.headerTaxRate}>CGST</Text>
                    </View>
                    <View style={styles.headerColTaxType}>
                      <Text style={styles.headerTaxRate}>
                        {`${taxDistribution(item.price, item.gst)}`}
                      </Text>
                      <Text style={styles.headerTaxRate}>
                        {`${taxDistribution(item.price, item.gst)}`}
                      </Text>
                    </View>

                    <View style={styles.headerColTotalPrice}>
                      <Text style={styles.text}>{`${
                        item.price * item.quantity
                      }`}</Text>
                    </View>
                  </View>
                ))}
              <View style={styles.TabletotalPrice}>
                <Text style={styles.textTotal}>Total</Text>
                {/* <Text style={styles.textTotals}>
                  {`${TotalTaxAmount(Invoice)}`}
                </Text> */}
                <Text style={styles.textTotals}>
                  {`${Invoice?.totalPrice}`}
                </Text>
              </View>
              <View style={styles.numberTowordsHead}>
                <Text style={styles.numberToword}>Total amount in words :</Text>
                <Text style={styles.numberToword}>
                  {` ${convert(Invoice?.totalPrice)} Only.`}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.AuthorizedSectionHead}>
            <View style={styles.AuthorizedSection}>
              <Text style={styles.AuthorizedSectionText}>
                Authorized Signatory
              </Text>
            </View>
            <View style={styles.invoiceWhisess}>
              <Text style={styles.AuthorizedSectionText}>Thank you !</Text>
              <Text style={styles.invoiceTxt}>for shipping with us</Text>
            </View>
          </View>

          <View style={styles.companyAddress}>
            <Text style={styles.link}>Contact Gshoppi: 1800 12398 6666</Text>
            <Text style={styles.link}>||</Text>
            <Text style={styles.link}>
              <Link style={styles.link} src="https://gshoppi.com/contact-us">
                gshoppi.com/contact-us
              </Link>
              .
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
