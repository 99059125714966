import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductBySlug } from "../../../actions/productActions";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import MetaData from "../../MetaData/MetaData";

const ProductCatgory = () => {
  const { product, loading } = useSelector((state) => state.allproduct);
  // console.log(product)

  const dispatch = useDispatch();
  const { slug } = useParams();
  // console.log(slug)

  const onHandleDiscount = (item) => {
    const cutoffprice1 = item.cutoffprice - item.price;
    const allTax = cutoffprice1 / item.cutoffprice;
    const totalDiscount = allTax * 100;
    return totalDiscount.toFixed();
  };

  const showMoreText = (item) => {
    if (item.length <= 60) return item;

    if (item.length > 60) {
      return (
        <>
          <p>{item.slice(0, 60)} ...</p>
        </>
      );
    }
  };

  useEffect(() => {
    dispatch(getProductBySlug(slug));
  }, [dispatch, slug]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center bg-gray-light">
          <Loader />
        </div>
      ) : (
        // <div>
        //   {
        //     product && product.map((item)=>{
        //       return(
        //         <div>
        //           <h1>{item.name}</h1>
        //           </div>
        //       )
        //     })
        //   }
        // </div> */}
        <div>
          <MetaData title={` ${slug.replace(/-/g, " ")}`} />
          <div className="pb-10 font-roboto bg-gray-light">
            {/* {productByPrice &&
            Object.keys(productByPrice)?.map((product, index) => {
              return (
                <div className="ml-10 mr-5  font-roboto">
                  {productByPrice && productByPrice[product]?.length > 0 ? (
                    <div className="flex items-center justify-between  h-[50px] pr-2">
                      <h1 className="font-semibold text-lg capitalize">washing machine</h1>
                      <div>
                        <button className="pl-3 pr-3 pt-1 pb-1 border">
                          View more
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
            <div className="flex flex-col flex-wrap pt-10">
              <div className="flex flex-col items-center justify-center">
                <h1 className="capitalize font-bold text-lg">
                  {slug.replace(/-/g, " ")}
                </h1>
                <h1 className="pb-10 opacity-75 font-semibold">
                  {product?.length} items
                </h1>
              </div>
              <div className="w-full h-[2px] bg-black opacity-75"></div>
              <div className="mt-10 md:pl-10 md:pr-10 flex flex-wrap items-center justify-center">
                {product &&
                  product?.map((item) => {
                    return (
                      <Link
                        key={item.slug}
                        className="md:w-[250px] md:h-[320px] bg-white p-2 flex flex-col shadow rounded-md items-center justify-center m-3 relative"
                        to={`${`/product/${item.slug}/${item._id}`}`}
                      >
                        <p className="ml-3 font-bold text-white text-sm absolute top-0 left-0  bg-[#388E3C] flex items-center justify-center p-1">
                          {onHandleDiscount(item)} % off
                        </p>
                        <div
                          className="hover:scale-110 ease-out duration-300 w-[200px] h-[220px] p-5 overflow-hidden flex items-center justify-center"
                          >
                          <img
                            className="w-auto h-auto"
                            src={item.images && item.images[0]?.url}
                            alt={item.name}
                          />
                        </div>
                        <div className="flex items-center flex-col">
                          <h1 className="font-medium text-sm mt-5">
                            {showMoreText(item.name)}
                          </h1>
                          <div className="flex items-center justify-center pt-3">
                            <p className="mr-3 font-bold">
                              ₹ {item?.price?.toLocaleString()}
                            </p>
                            <s className="font-normal text-base opacity-80">
                              ₹ {item?.cutoffprice?.toLocaleString()}
                            </s>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
            {/* </div>
              );
            })} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCatgory;
