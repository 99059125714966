import React from "react";
import MetaData from "../MetaData/MetaData";

const PrivacyPolicy = () => {
  return (
    <div>
          <MetaData title={`Privacypolicy Store Online`} />
    <div className="flex flex-col items-center justify-center font-roboto pb-10 pt-10 bg-gray-light">
      <h1 className="font-bold text-center text-2xl pt-5 pb-5">
        Privacy Policy
      </h1>
      <div className="pl-8 pr-2 md:pl-0 md:pr-0 md:w-10/12 ">
        <p className="font-normal text-base">
          At <a href="https://gshoppi.com/" className="text-blue">Gshoppi.com</a>, we take your privacy seriously and are committed to
          safeguarding your personal information. This Privacy Policy outlines
          how we collect, use, and protect the data you provide when using our
          multi-brand e-commerce platform for electronics goods.
        </p>
        <div>
          <ul className="list-decimal">
            <li className="font-semibold text-base pt-3 pb-3">
              Information Collection :
            </li>
            <p className="font-semibold text-base ">
              We may collect various types of information when you visit or
              interact with our website. This may include:
            </p>
            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base">Personal Information : </h1>
              <p className="ml-2 font-normal text-base">
                Name, contact details, email address, and other identifying
                information you provide when registering, placing an order, or
                subscribing to our newsletter.
              </p>
            </div>
            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base">Payment Information : </h1>
              <p className="ml-2 font-normal text-base">
                Details necessary for processing transactions, such as credit
                card numbers, billing address, and other payment-related data.
              </p>
            </div>
            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base ">Browsing Data : </h1>
              <p className="ml-2 font-normal text-base">
                Information about your device, IP address, browser type, and
                usage patterns when accessing our website.
              </p>
            </div>
            <li className="font-semibold text-base pt-3 pb-3">
              Information Use :{" "}
            </li>
            <p>We use the collected information for the following purposes:</p>
            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base">Processing Orders : </h1>
              <p className="ml-2 font-normal text-base">
                To fulfill your purchases, communicate order status, and provide
                customer support.
              </p>
            </div>
            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base">Account Management : </h1>
              <p className="ml-2 font-normal text-base">
                To manage your account, preferences, and access to personalized
                features.
              </p>
            </div>

            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base">
                Marketing Communication :{" "}
              </h1>
              <p className="ml-2 font-normal text-base">
                To send promotional emails, newsletters, and updates about our
                products and services.
              </p>
            </div>

            <div className="flex items-center mt-2">
              <h1 className="font-medium text-base">Improving Services : </h1>
              <p className="ml-2 font-normal text-base">
                To analyze website usage and enhance our offerings and user
                experience.
              </p>
            </div>
            <li className="font-semibold text-base pt-3 pb-3">
              Information Sharing :{" "}
            </li>
            <p className="font-normal text-base">
              We value your trust and will not sell, rent, or share your
              personal information with third parties for marketing purposes
              without your explicit consent. However, we may share data with
              trusted partners and service providers to fulfill orders, process
              payments, and deliver services on our behalf.
            </p>
            <li className="font-semibold text-base pt-3 pb-3">
              Data Security :{" "}
            </li>
            <p className="font-normal text-base">
              We employ industry-standard security measures to protect your
              personal data from unauthorized access, alteration, disclosure, or
              destruction. Your payment details are encrypted during
              transmission and securely stored.
            </p>
            <li className="font-semibold text-base pt-3 pb-3">
              Cookies and Tracking :{" "}
            </li>
            <p className="font-normal text-base">
              We use cookies and similar tracking technologies to enhance your
              browsing experience, customize content, and analyze website
              traffic. You can manage your preferences and control cookie
              settings through your browser.
            </p>
            <li className="font-semibold text-base pt-3 pb-3">
              Children's Privacy :{" "}
            </li>
            <p className="font-normal text-base">
              GShoppi.com is not intended for children under the age of 13. We
              do not knowingly collect or store personal information from
              minors.
            </p>
            <li className="font-semibold text-base pt-3 pb-3">
              Consent and Opt-Out :{" "}
            </li>
            <p className="font-normal text-base">
              By using our website, you consent to the terms of this Privacy
              Policy. You can opt-out of receiving marketing communications at
              any time by unsubscribing or contacting us.
            </p>
            <li className="font-semibold text-base pt-3 pb-3">
              Updates to Privacy Policy :{" "}
            </li>
            <p className="font-normal text-base">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. Any modifications
              will be effective upon posting on the website.
            </p>
            <p className="font-normal text-base mt-5">
              For further details about our privacy practices or to exercise
              your rights, please refer to the full Privacy Policy available on
              our website.
            </p>
            <p className="font-normal text-base mt-5">
              If you have any questions or concerns about our Privacy Policy,
              please don't hesitate to contact our Customer Support team.
            </p>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
