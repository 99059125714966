export const ADD_SHIPPING_ADDRESS_REQUEST = "ADD_SHIPPING_ADDRESS_REQUEST"
export const ADD_SHIPPING_ADDRESS_SUCCESS = "ADD_SHIPPING_ADDRESS_SUCCESS"
export const ADD_SHIPPING_ADDRESS_FAIL = "ADD_SHIPPING_ADDRESS_FAIL"

//  get every user shipping Address

export const GET_SHIPPING_ADDRESS_REQUEST = "GET_SHIPPING_ADDRESS_REQUEST"
export const GET_SHIPPING_ADDRESS_SUCCESS = "GET_SHIPPING_ADDRESS_SUCCESS"
export const GET_SHIPPING_ADDRESS_FAIL = "GET_SHIPPING_ADDRESS_FAIL"

