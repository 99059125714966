import React, { Fragment, useState } from "react";
import CartItem from "./CartItem";
import { useDispatch, useSelector } from "react-redux";
import { addToCartItems, removeItemsToCart } from "../../actions/cartActions";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import "./Cart.css";
import MetaData from "../MetaData/MetaData";
import cart from "../../image/shopping-cart.png";

const Cart = () => {
  const dispatch = useDispatch();
  const [fixedDetails, setFixedDetails] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  // console.log(cartItems)
  const navigate = useNavigate();

  const fixedImageScroll = () => {
    if (window.scrollY >= 200) {
      setFixedDetails(true);
    } else {
      setFixedDetails(false);
    }
  };

  window.addEventListener("scroll", fixedImageScroll);

  const increaseQuentity = (id, quantity, stock) => {
    const newQty = quantity + 1;
    if (stock <= quantity) {
      return;
    }

    dispatch(addToCartItems(id, newQty));
  }; 

  const decreaseQuentity = (id, quantity) => {
    const newQty = quantity - 1;
    if (1 >= quantity) { 
      return;
    }

    dispatch(addToCartItems(id, newQty));
  };

  const removeItem = (id) => {
    dispatch(removeItemsToCart(id));
  };

  const checkoutHandler = () => {
    navigate("/login?redirect=/shipping-details");
  };

  return (
    <Fragment>
      <MetaData title="shoping cart | gshoppi.com" />
      <div className="w-full min-h-screen bg-gray-light">
        {cartItems.length > 0 ? (
          <>
            <div className="bg-gray-light p-1 md:p-5 font-roboto">
              <div className="w-full  flex items-center justify-between mt-3">
                <h1 className="font-medium  md:text-lg capitalize">
                  shoping cart
                </h1>
                <Link
                  className="text-white flex items-center justify-center pt-1 pb-1 md:pt-0 md:pb-0 md:h-10 pl-2 md:pl-4 pr-2 md:pr-4 bg-gray hover:text-white rounded"
                  to="/"
                >
                  <AiOutlineArrowLeft size={20} />
                  <span className="font-normal md:font-medium md:text-base">
                    continue shoping
                  </span>
                </Link>
              </div>
              <div className="mt-5 pb-10 mb-10 flex flex-col md:flex-row">
                <div>
                  <div className="flex items-center justify-between p-5 bg-gray text-white font-medium capitalize rounded-b">
                    <p>product</p>
                    <p>name</p>
                    <p>quentity</p>
                    <p>subtotal</p>
                  </div>
                  <div className="flex flex-col  justify-between rounded mt-3 bg-white">
                    {cartItems &&
                      cartItems.map((item) => {
                        // console.log(item.gst);
                        return (
                          <div
                            key={item.product}
                            className="flex flex-col md:flex-row  pt-2 pb-2  items-center justify-between hover:shadow-2xl rounded-sm  pr-1 md:pr-5"
                          >
                            <CartItem
                              Item={item}
                              removeItem={() => removeItem(item.product)}
                            />
                            <div className="flex items-center  w-full  pb-5 md:pl-2 pt-5">
                              <div className="flex md:w-2/6 bg-gray border-gray border rounded">
                                <button
                                  onClick={() =>
                                    decreaseQuentity(
                                      item.product,
                                      item.quantity
                                    )
                                  }
                                  className="lg:w-[50px] p-1 rounded  h-full flex items-center justify-center "
                                >
                                  <AiOutlineMinus size={20} color="white" />
                                </button>
                                <input
                                  className="w-[35px] text-center outline-none"
                                  type="number"
                                  value={item.quantity}
                                  readOnly
                                />
                                <button
                                  onClick={() =>
                                    increaseQuentity(
                                      item.product,
                                      item.quantity,
                                      item.stock
                                    )
                                  }
                                  className="lg:w-[50px] p-1 rounded-l  h-full flex items-center justify-center "
                                >
                                  <AiOutlinePlus size={20} color="white" />
                                </button>
                              </div>
                              <p className="lg:w-[150px] text-end font-bold text-base ml-3 md:ml-0">{`₹ ${(
                                item.price * item.quantity
                              )?.toFixed()}`}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="flex flex-col w-full md:mr-3">
                  <div
                    className={
                      fixedDetails
                        ? "product_imgs fixed  md:ml-5 mr-0  flex  flex-col"
                        : "product_imgs  flex flex-col md:ml-10  md:pb-4 "
                    }
                  >
                    <div className="w-full pt-3 pb-3 pl-3 md:p-5 bg-gray rounded-b">
                      <h1 className="font-medium capitalize text-white">
                        product details
                      </h1>
                    </div>
                    <div className="w-full flex flex-col mt-5 space-y-3 shadow p-4 rounded bg-white">
                      <div className="flex justify-between pr-5">
                        <p className=" font-medium text-base  mr-10">
                          Price
                          <span>{` ( ${cartItems.length} items)`}</span>
                        </p>
                        <h1 className="font-bold text-base">{` ₹ ${cartItems.reduce(
                            (acc, item) =>
                              acc +
                              (item.cutoffprice) *
                                item.quantity,
                            0
                          )}`}</h1>
                      </div>
                      <div className="flex justify-between pr-5">
                        <h1 className="font-medium text-base capitalize opacity-75">
                          Discount
                        </h1>
                        <p className="font-bold text-base opacity-75"> - {` ₹ ${cartItems
                          .reduce(
                            (acc, item) =>
                              acc +
                              (item.cutoffprice - item.price) *
                                item.quantity,
                            0
                          )
                          ?.toFixed(2)}`}</p>
                      </div>
                      <div className="w-full h-[2px] bg-black"></div>
                      <div className="flex justify-between pr-5">
                        <h1 className="font-medium text-base capitalize">
                          Total amount
                        </h1>
                        <p className="font-bold text-base">{` ₹ ${cartItems
                          .reduce(
                            (acc, item) =>
                              acc +
                              (item.price) *
                                item.quantity,
                            0
                          )
                          .toFixed(2)}`}</p>
                      </div>
                    </div>

                    <button
                      onClick={checkoutHandler}
                      className="bg-orange text-white font-medium rounded w-full h-[40px]"
                    >
                      Check out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center pt-20">
            <img className="w-[200px]" src={cart} alt="cart is empty" />
            <h1 className="font-medium text-lg mt-10">Your cart is empty</h1>
            <Link
              to="/"
              className="bg-orange  text-white font-medium text-base pl-10 pr-10 pt-2 pb-2 mt-10 flex items-center justify-center rounded transition ease-in-out delay-150 hover:-translate-x-1 hover:scale-110 duration-300"
            >
              <AiOutlineArrowLeft size={20} />
              <span className="ml-3 ">Go to shop</span>
            </Link>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Cart;
