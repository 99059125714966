import React from 'react'
import {AiFillCloseCircle} from "react-icons/ai"
import {BsArrowLeft} from "react-icons/bs"
import { Link } from 'react-router-dom'

const PaymentCancel = () => {
  return (
    <div className='w-full h-4/6 pt-[150px] pb-[150px] flex items-center justify-center flex-col bg-gray-light' >
      <AiFillCloseCircle color="red" size={40} />
      <h1 className='text-lg font-bold '>PaymentCancel</h1>
      <Link to="/cart">
        <button className='pl-10 pr-10 pt-2 pb-2 bg-red text-white mt-10 flex items-center hover:bg-[#FF7276]'> <BsArrowLeft size={20} /> <span className='ml-3'>Go to Cart</span></button>
      </Link>
    </div>
  )
}

export default PaymentCancel