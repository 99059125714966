import React, { useState } from "react";
import SubAdminSidebar from "../SubAdminSidebar";
import axios from "axios";
import { toast } from "react-toastify";

const UploadImage = () => {
  const [productImage, setProductImage] = useState("");

  const uploadImages = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (let i = 0; i < productImage.length; i++) {
      formData.append("productImage", productImage[i]);
    }

    const response = await axios.post(
      "/api/v1/subadmin/upload-image",
      formData
    );
    toast.success("successfully image uploaded")

    console.log(response);
  };

  return (
    <div className="pl-10 pr-10 pt-10 pb-10 bg-gray-light flex w-full">
      <SubAdminSidebar />
      <div className="ml-5 bg-white rounded pl-5 pt-3 w-full">
        <div
          id="stats"
          className="grid gird-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          <form onSubmit={uploadImages} encType="multipart/form-data">
            <input
              type="file"
              name="productImage"
              placeholder="upload image"
              className="border-b pb-2"
              onChange={(e) => setProductImage(e.target.files)}
            />
            <button
              type="submit"
              className="pr-5 pl-5 pt-2 pb-2 bg-blue text-white rounded mt-10"
            >
              Upload Image
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
