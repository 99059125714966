import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearErrors,
  getProductDetails,
  updateProduct,
} from "../../../actions/productActions";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { UPDATE_PRODUCT_RESET } from "../../../constants/productConstant";
import Sidebar from "../Sidebar";

const UpdateProduct = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  // console.log(id)
  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { error: updateError, isUpdated } = useSelector(
    (state) => state.product
  );

  // const [productdata,setProductData] = useState(product)
  // console.log(productdata)

  const [cutoffprice, setCutOffPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [gst, setGst] = useState("");
  const [stock, setStock] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState("");
  // console.log(images);
  const [oldImages, setOldImages] = useState([]);
  // product color
  // const [oldimageColor, setImageColor] = useState([]);
  // const [productImageColor, setProductImageColor] = useState();
  const [highlight, setHighlight] = useState([
    {
      HighLightName: "",
    },
  ]);
  // console.log(highlight);
  // console.log(productImageColor);
  // const [imagesPreview, setImagesPreview] = useState([]);
  // const [spacification,setSpecifications] = useState()
  // console.log(spacification)
  const [spacification, setSpecifications] = useState([
    {
      title: "",
      productDetails: [
        {
          specname: "",
          details: "",
        },
      ],
    },
  ]);

  const addedMoreData = () => {
    setSpecifications([
      ...spacification,
      {
        title: "",
        productDetails: [
          {
            specname: "",
            details: "",
          },
        ],
      },
    ]);
  };

  const onChangeHandle = (e, index) => {
    let data = [...spacification];
    data[index][e.target.name] = e.target.value;
    setSpecifications(data);
  };

  const onHandleSubItem = (e, index, subIndex) => {
    let origal = spacification;
    origal[index].productDetails[subIndex][e.target.name] = e.target.value;
    setSpecifications(JSON.parse(JSON.stringify(origal)));
  };

  const removeItems = (index) => {
    const remove = [...spacification];
    remove.splice(index, 1);
    setSpecifications(remove);
  };

  const subInputbox = (index) => {
    let origal = spacification;
    origal[index].productDetails = [
      ...origal[index].productDetails,
      { specname: "", details: "" },
    ];
    setSpecifications(JSON.parse(JSON.stringify(origal)));
  };

  // // console.log(subItems)

  const subInputRemove = (index, ind) => {
    let origal = spacification;
    origal[index].productDetails.splice(ind, 1);
    setSpecifications(origal);
  };

  // high light on handle change
  const onHandleChangeHighLight = (e, index) => {
    const highLightData = [...highlight];
    highLightData[index][e.target.name] = e.target.value;
    setHighlight(highLightData);
  };

  // High light
  const addMoreHighLight = () => {
    setHighlight([
      ...highlight,
      {
        HighLightName: "",
      },
    ]);
  };

  // remove high light
  const removeHighLigt = (index) => {
    const remove = [...highlight];
    remove.splice(index, 1);
    setHighlight(remove);
    // console.log(remove);
  };

  const onHandleChange = (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("price", price);
      formData.append("cutoffprice", cutoffprice);
      formData.append("gst", gst);  
      formData.append("stock", stock);
      formData.append("name", name);
      formData.append("description", description);

      formData.append("highlight", JSON.stringify(highlight));
      // }
      // formData.append("images",images)

      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
        // console.log(images[i]);
      }

      // for (let i = 0; i < productImageColor?.length; i++) {
      //   formData.append("color", productImageColor[i]);
      //   console.log(productImageColor[i]);
      // }

      formData.append("specifications", JSON.stringify(spacification));

      dispatch(updateProduct(id, formData));

      console.log(formData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (product && product._id !== id) {
      dispatch(getProductDetails(id));
    } else {
      setPrice(product.price);
      setCutOffPrice(product.cutoffprice);
      setStock(product.stock);
      setName(product.name);
      setGst(product.gst);
      setDescription(product.description);
      setSpecifications(product.specifications);
      setOldImages(product.images);
      // setImageColor(product.color);
      setHighlight(product.highlight);
    }

    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      toast.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      toast.success("Product update successfull");
      dispatch({ type: UPDATE_PRODUCT_RESET });
    }
  }, [dispatch, error, id, isUpdated, updateError, product]);

  return (
    <div className="antialiased bg-lightgray w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className="w-full  min-h-full">
          <div className="w-full h-full">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div className="w-full h-full pt-10 bg-gray-light flex flex-col pb-10  rounded shadow-lg shadow-black font-roboto">
                <form onSubmit={onHandleChange} className="pl-10 pr-10">
                  <div className="flex items-center justify-between bg-gray pt-3 pb-3">
                    <h1 className="pl-2 font-bold text-lg capitalize text-white  flex items-center justify-center">
                      {`Product details :- `}
                      <p className="text-black ml-3">{product._id}</p>
                    </h1>
                  </div>

                  <div className="flex flex-col w-full  mt-5">
                    <div className="w-full">
                      <h1 className="capitalize font-bold text-base">Name</h1>
                      <textarea
                        className="font-medium text-base w-full flex-wrap border"
                        value={name}
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-wrap mt-5 space-x-3 w-full">
                      <div className="grid md:grid-cols-2 md:gap-6 w-full">
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="cutoffprice"
                            id="floating_last_name"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            value={cutoffprice}
                            onChange={(e) => setCutOffPrice(e.target.value)}
                          />
                          <label
                            for="number"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            MRP
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="gst"
                            id="floating_first_name"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                          <label
                            for="Price"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Price
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="stock"
                            id="floating_last_name"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                          />
                          <label
                            for="number"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Stock
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="gst"
                            id="floating_last_name"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            value={gst}
                            onChange={(e) => setGst(e.target.value)}
                          />
                          <label
                            for="number"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            GST %
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="relative z-0 w-full mb-6 group">
                        <textarea
                          type="text"
                          name="description"
                          id="floating_last_name"
                          className="block py-2.5 px-0 w-full h-[150px]  text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <label
                          for="text"
                          className="font-semibold absolute text-lg  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Description
                        </label>
                      </div>
                      {/* high light input box */}
                      <div className="">
                        {highlight &&
                          highlight.map((inputbox, index) => {
                            // console.log(inputbox);
                            return (
                              <div className="flex">
                                <div className="relative z-0 w-3/6 mb-6 group">
                                  <input
                                    type="text"
                                    name="HighLightName"
                                    id="HighLightName"
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=""
                                    value={inputbox.HighLightName}
                                    onChange={(e) =>
                                      onHandleChangeHighLight(e, index)
                                    }
                                  />
                                  <label
                                    for="heading"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    High light product details
                                  </label>
                                </div>
                                <div className="flex items-center ml-3">
                                  {highlight.length !== 1 && (
                                    <button
                                      className="pl-3 pr-3 pt-1 pb-1 bg-red text-white "
                                      onClick={() => removeHighLigt(index)}
                                    >
                                      remove
                                    </button>
                                  )}
                                  {highlight.length - 1 === index && (
                                    <button
                                      className="pl-3 pr-3 pt-1 pb-1 bg-blue text-white "
                                      onClick={addMoreHighLight}
                                    >
                                      Add More
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="file"
                      // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="images"
                      id="images"
                      className="block py-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      onChange={(e) => setImages(e.target.files)}
                      multiple
                    />
                    <label
                      for="update image"
                      className="capitalize font-bold absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      update image
                    </label>
                  </div>

                  <div className=" mt-5">
                    <h1 className="capitalize font-semibold text-base">
                      old Product Images
                    </h1>
                    <div className="flex items-center space-x-2">
                      {oldImages &&
                        oldImages &&
                        oldImages.map((image) => {
                          return (
                            <img
                              className="w-[100px] h-[100px] mt-3"
                              src={image?.url}
                              alt="user order"
                            />
                          );
                        })}
                    </div>
                  </div>

                  {/* product image color */}
                  {/* <div className="mt-4">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="file"
                        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        name="color"
                        id="color"
                        className="block py-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=""
                        onChange={(e) => setProductImageColor(e.target.files)}
                        multiple
                      />
                      <label
                        for="update image"
                        className="capitalize font-bold absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        update image color
                      </label>
                    </div>

                    <div className=" mt-5">
                      <h1 className="capitalize font-semibold text-base">
                        Product Color
                      </h1>
                      <div className="flex items-center space-x-2">
                        {oldimageColor &&
                          oldimageColor.map((image) => {
                            return (
                              <img
                                className="w-[100px]  mt-3"
                                src={image?.url}
                                alt=""
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div> */}

                  <div className="mt-5">
                    <div className="flex bg-gray p-1">
                      <h1 className="font-medium text-lg">category</h1>
                      <p className="ml-2 mr-2 text-white">:- </p>
                      <h1 className="font-medium text-lg">
                        {product.category?._id}
                      </h1>
                    </div>
                    <div className="flex ">
                      <h1 className="font-medium text-lg">Category Name</h1>
                      <p className="ml-2 mr-2 ">:- </p>
                      <h1>{product.category?.name}</h1>
                    </div>
                  </div>

                  <div className="mt-5">
                    {spacification.map((item, index) => (
                      <div className="mb-5 border p-2">
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="title"
                              id="title"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=""
                              value={item.title}
                              onChange={(e) => onChangeHandle(e, index)}
                            />
                            <label
                              for="heading"
                              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Specification Heading
                            </label>
                          </div>
                        </div>
                        {/* sub input box */}
                        <div
                          className="mt-2 flex flex-col w-full"
                          key={item.productDetails?.length}
                        >
                          {item.productDetails &&
                            item.productDetails.map((subItem, ind) => (
                              <div className="flex">
                                <div className="grid md:grid-cols-2 md:gap-6 w-full ">
                                  <div className="relative z-0 w-full mb-6 group">
                                    <input
                                      type="text"
                                      name="specname"
                                      id="specname"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                      placeholder=""
                                      value={subItem.specname}
                                      onChange={(e) => {
                                        onHandleSubItem(e, index, ind);
                                      }}
                                    />
                                    <label
                                      for="specificationName"
                                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                      Specification Name
                                    </label>
                                  </div>
                                  <div className="relative z-0 w-full mb-6 group">
                                    <input
                                      type="text"
                                      name="details"
                                      id="details"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                      placeholder=""
                                      value={subItem.details}
                                      onChange={(e) => {
                                        onHandleSubItem(e, index, ind);
                                      }}
                                    />
                                    <label
                                      for="details"
                                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                      Specification details
                                    </label>
                                  </div>
                                </div>
                                <div className="">
                                  {item.productDetails.length !== 1 && (
                                    <button
                                      className="bg-red  pb-3 pt-3 pl-2 pr-2 text-white"
                                      onClick={() => subInputRemove(index, ind)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                  {item.productDetails.length - 1 === ind && (
                                    <button
                                      className="bg-blue pb-3 pt-3 pl-2 pr-2 text-white"
                                      onClick={() => subInputbox(index)}
                                    >
                                      Add More
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                        {spacification.length !== 1 && (
                          <button
                            className="bg-red pl-10 pr-10 pb-3 pt-3 text-white"
                            onClick={() => removeItems(index)}
                          >
                            Remove
                          </button>
                        )}
                        {spacification?.length - 1 === index && (
                          <button
                            className="bg-blue pl-10 pr-10 pb-3 pt-3 text-white"
                            onClick={addedMoreData}
                          >
                            Add More
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-blue pt-2 pb-2  mt-5 text-white capitalize"
                  >
                    update Product
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
