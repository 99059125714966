import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../MetaData/MetaData";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdate } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";



const UpdateProfile = () => {
  let { user } = useSelector((state) => state.user);
  // console.log(user)
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [avtar,setAvtar] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();




  const onHandleRegisterSubmit = (e) => {
    e.preventDefault();
    const updateForm = new FormData()
    updateForm.set("name",name)
    updateForm.set("email",email)
    updateForm.set("avtar",avtar)

   
    // console.log(updateForm)
    dispatch(profileUpdate(updateForm))
    navigate("/profile");

  };


  const onHandleChange = (e) => {
    const image = e.target.files[0];
    if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error("select valid image");
      return false;
    } else {
      setAvtar(image);
    }
  };


  useEffect(()=>{

  },[dispatch,])


  return (
    <Fragment>
    <MetaData title="update your profile" />
    <div className="bg-gray-light w-full h-screen  flex items-center justify-center " >
      <div className="ml-3 md:ml-0 mr-3 md:mr-0 w-full md:w-3/6 h-5/6 bg-white shadow-2xl text-black relative rounded p-5 " >
        {/* <div
          className="flex items-center justify-end "
          onClick={() => setUpdateProfile(false)}
        >
          <AiOutlineClose
            size={30}
            className="cursor-pointer w-10 h-10 p-1 bg-gray hover:border-gray  rounded-full text-white shadow-2xl"
          />
        </div> */}
        <h1 className="text-center font-semibold text-xl opacity-80 mt-10">
          Update your Profile
        </h1>
        <div className="w-full h-[1.5px] bg-gray mt-3"></div>
        <div className="flex items-center justify-center z-20">
          <form
            action=""
            onSubmit={onHandleRegisterSubmit}
            className="w-full md:w-4/6 mt-4"
          >
            <div className="flex flex-col mt-3">
              <label htmlFor="email/number" className="font-medium text-base">
                Name
              </label>
              <input
                type="name"
                name="name"
                className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label
                htmlFor="email/number"
                className="font-medium text-base mt-2"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                className="border-2 border-black border-opacity-70 mt-2 rounded h-10 pl-2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="flex items-center mt-3">
                <img className="w-10" src="/user.png" alt="" />
                <input
                  className="ml-2"
                  type="file"
                  name="avtar"
                  accept="image/*"
                  onChange={onHandleChange}
                  required
                />  
              </div>
              <button
                type="submit"
                className="mt-5 w-full h-10 bg-yellow hover:bg-yellow-dark rounded capitalize"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default UpdateProfile;
