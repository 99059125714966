import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCategoryById,
  updateCategorybyId,
} from "../../../actions/categoryAction";
import Loader from "../../Loader/Loader";
import { CLEAR_ERROR } from "../../../constants/categoryConstant";
import { toast } from "react-toastify";
import Sidebar from "../Sidebar";

const UpdateCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { categoryId, error, loading } = useSelector(
    (state) => state.allCategory
  );
  console.log(categoryId);
  const [categoryName, setCategoryName] = useState();
  //   const [selectCategory,setSelectCategory] = useState()
  const [image, setImage] = useState();

  const onHandleFile = (e) => {
    const image = e.target.files[0];
    setImage(image);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();



    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("banner", image);

    dispatch(updateCategorybyId(id, formData));
    toast.success("category updated successfull")

  };

  useEffect(() => {
    if (categoryId && categoryId.name) {
      setCategoryName(categoryId.name);
    }
  }, [categoryId]);

  useEffect(() => {
    dispatch(getCategoryById(id));
    if (error) {
      dispatch(CLEAR_ERROR());
    }
  }, [dispatch, id, error]);

  return (
    <div>
            <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
      <div className="bg-white  flex flex-col space-y-3  w-full rounded">
        <div className="flex items-center justify-between pb-5 bg-gray pl-5 pt-5 rounded-t">
          <h1 className="font-semibold text-lg text-white">Update Product Category</h1>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <form
            action=""
            className="flex flex-col space-y-3 p-5"
            onSubmit={onHandleSubmit}
          >
            <input
              value={categoryName}
              type="text"
              name="categoryName"
              className="w-2/6 h-10 p-2 rounded bg-gray-light"
              placeholder="Add category name"
              onChange={(e) => setCategoryName(e.target.value)}
            />
            {/* <select
                        className="h-10 rounded"
                        value={selectCategory}
                        onChange={(e) => setSelectCategory(e.target.value)}
                      >
                        <option
                          value=""
                          className="font-medium text-base capitalize"
                        >
                          Choose Category
                        </option>
                        {categoryList.map((item, index) => {
                          return (
                            <option
                              className="font-medium text-base capitalize"
                              key={item.value}
                              value={item.value}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select> */}
            <div className=" flex  h-10  rounded">
              <input
                className="bg-gray-light w-2/6"
                type="file"
                name="banner"
                accept="image/*"
                onChange={onHandleFile}
              />
            </div>
            <div className="w-2/6">
              <img className="w-full" src={categoryId?.banner?.url} alt="" />
            </div>
            <button
              type="submit"
              className="text-white bg-blue pt-2 pb-2  rounded w-2/6"
            >
              Add category
            </button>
          </form>
        )}
      </div>
      </div>
      </div>
    </div>
  );
};

export default UpdateCategory;
