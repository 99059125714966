export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST"
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS"
export const CREATE_BRAND_FAIL = "CREATE_BRAND_FAIL"

// get all brands
export const GET_ALL_BRAND_REQUEST = "GET_ALL_BRAND_REQUEST"
export const GET_ALL_BRAND_SUCCESS = "GET_ALL_BRAND_SUCCESS"
export const GET_ALL_BRAND_FAIL = "GET_ALL_BRAND_FAIL"


// update brands
export const UPDATE_BRAND_REQUIEST = "UPDATE_BRAND_REQUIEST"
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS"
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL"


// Delete brand
export const DELETE_BRAND_REQUIEST = "DELETE_BRAND_REQUIEST"
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS"
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL" 
export const CLEAR_ERROR = "CLEAR_ERROR"

