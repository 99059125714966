import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FilterProducts } from "../../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../../MetaData/MetaData";
import ReactSlider from "react-slider";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { AiFillStar } from "react-icons/ai";
import ProductsList from "../ProductCard/ProducstCard";
import ReactPaginate from "react-paginate";
import Loader from "../../Loader/Loader";
import { CLEAR_ERROR } from "../../../constants/productConstant";

const ProductFilterByCategory = () => {
  const { id } = useParams();

  const { filterProducts, error, loading } = useSelector(
    (state) => state.products
  );
  // console.log(filterProducts);

  // const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 50000]);
  const [brandId, setBrand] = useState("");
  const [BrandName, setBrandName] = useState("");
  const [rating, setRating] = useState(0);
  // console.log(rating);

  const [categoryId, setCategory] = useState("");

  const [clearSelectedbrand, setclearSelectedbrand] = useState(true);
  const [brandsactive, setBrandsActive] = useState(false);
  const [toggle, setToggle] = useState(true);
  const dispatch = useDispatch();

  // const setCurrentPageNo = (e) => {
  //   setCurrentPage(e.selected + 1);
  // };

  const onPriceHandler = (newPrice) => {
    setPrice(newPrice);
    // console.log(newPrice);
  };
  const clearSelectedbrands = () => {
    setclearSelectedbrand(true);
    setBrand(false);
    setBrandsActive(false);
  };

  const onHandleCategory = (item) => {
    setCategory(item);
    // console.log(item);
  };

  useEffect(() => {
    dispatch(FilterProducts(id, categoryId, brandId, rating));
    if (error) {
      dispatch(CLEAR_ERROR());
    }
    // dispatch(getProduct(id, currentPage, price, rating, Category, brands));
    // dispatch(
    //   getProduct(keyword, currentPage, price,, rating, brands)
    // );
    // }
  }, [dispatch, error, id, categoryId, brandId, rating]);

  return (
    <Fragment>
      <div>
        <MetaData title={`- Buy Products Online at Best price`} />
        {/* <div><img src="https://darlingretail.com/cdn/shop/files/FI2924_Dealer_Website_Banner_2024-Vogue_JAMES_AND_CO-2160x720px-Web_1800x.jpg?v=1714731313" alt="" /></div> */}
        <div className="pt-10 bg-gray-light pb-20">
          <div className="flex w-full flex-col  md:justify-between">
            <div className="flex w-full relative">
              {/* <div className="absolute ">
            <IoFilterCircleSharp size={30}/>
            </div> */}
              <div className="filter_page">
                <div className="sidebar bg-white shadow-xl ">
                  <div className="pl-5 pr-5">
                    <div className="">
                      <h1 className="font-semibold text-sm uppercase mt-2">
                        price
                      </h1>
                      <ReactSlider
                        min={0}
                        max={50000}
                        value={price}
                        className="horizontal-slider w-full h-[7px] rounded"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderThumb={(props, state) => (
                          <div {...props}>{state.valueNow}</div>
                        )}
                        onChange={(e) => onPriceHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-black mt-3 opacity-75"></div>
                  <div className="pl-5 pr-5 ">
                    <h1 className="font-semibold text-sm uppercase mt-2">
                      category
                    </h1>

                    <div>
                      {filterProducts?.categoryName &&
                        filterProducts?.categoryName.map((item,index) => {
                          // console.log(item)
                          return (
                            <div key={index}>
                              <ul>
                                <li
                                  className="cursor-pointer"
                                  key={item.id}
                                  onClick={() => onHandleCategory(item.id)}
                                >
                                  {item.name}
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                    </div>

                    {/* product brand */}
                    <div className="w-full h-[0.8px] bg-black mt-3"></div>
                    <div>
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setToggle(!toggle)}
                      >
                        <h1 className="font-semibold text-sm uppercase mt-2">
                          brand
                        </h1>
                        <div className="mt-2">
                          {toggle ? (
                            <RiArrowUpSLine size={20} />
                          ) : (
                            <RiArrowDownSLine size={20} />
                          )}
                        </div>
                      </div>
                      <div>
                        {brandId ? (
                          <button
                            className="uppercase flex  items-center justify-center border bg-gray-light border-gray-light p-1 font-normal text-xs mt-2"
                            onClick={() =>
                              clearSelectedbrands(!clearSelectedbrand)
                            }
                          >
                            <span className="mr-2 border border-gray">
                              <GrClose size={12} className="opacity-80" />
                            </span>
                            {BrandName}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {filterProducts?.listOfBrand &&
                          filterProducts?.listOfBrand.map((brand, index) => {
                            return (
                              <div key={index}>
                                {toggle ? (
                                  <ul>
                                    <li
                                      style={{
                                        background:
                                          brandsactive === index
                                            ? "orange"
                                            : "",
                                        color:
                                          brandsactive === index ? "white" : "",
                                        padding:
                                          brandsactive === index ? "3px" : "",
                                        opacity:
                                          brandsactive === index ? "0px" : "",
                                        borderRadius:
                                          brandsactive === index ? "2px" : "",
                                      }}
                                      key={brand}
                                      className="font-normal text-sm pt-1 cursor-pointer hover:text-orange opacity-80 hover:opacity-100 capitalize"
                                      onClick={() => {
                                        setBrand(brand.id);
                                        setBrandName(brand.name);
                                        setBrandsActive(index);
                                      }}
                                    >
                                      {brand.name}
                                    </li>
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="text-black">
                      <h1 className="font-medium text-base capitalize mt-5">
                        Ratings
                      </h1>
                      <div>
                        {[5, 4, 3, 2, 1].map((star, index) => {
                          return (
                            <div className="flex" key={index}>
                              <div className="flex items-center mb-2">
                                <input
                                  id="default-radio-1"
                                  type="checkbox"
                                  value=""
                                  name="default-radio"
                                  // checked={rating}
                                  onChange={() => setRating(star)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="default-radio-1"
                                  className="ml-2 text-sm flex items-center font-medium text-gray-900 dark:text-gray-300"
                                  >
                                   {star} <AiFillStar /> & above
                                </label>
                              </div>
                             

                              {/* <ul className="flex items-center">
                                <li
                                  key={star}
                                  className="flex items-center justify-center"
                                >
                                  <input
                                    id="checked-checkbox"
                                    type="checkbox"
                                    checked={rating}
                                    onChange={() => setRating(star)}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    for="checked-checkbox"
                                    className="ml-2 text-sm flex items-center font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    {star} <AiFillStar /> & above
                                  </label>
                                </li>
                              </ul> */}
                              {/* <input type="checkbox" name="" id="" value={} /> */}
                              {/* <div className="flex items-center ml-3 pt-1" >{rating} <span className="ml-2"><AiFillStar /></span> <span className="ml-2">& above</span> </div > */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center w-full">
                {loading ? (
                  <div className="flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="flex  justify-center lg:pl-10 md:pb-10  flex-wrap   product_page  md:ml-10 md:mr-5 rounded ">
                    {filterProducts?.products?.length > 0 ? (
                      filterProducts?.products &&
                      filterProducts?.products?.map((product) => {
                        return (
                          <ProductsList key={product._id} product={product} />
                        );
                      })
                    ) : (
                      <div className="flex flex-col items-center justify-center ml-5 md:ml-0">
                        <h1 className="font-bold text-base">
                          Sorry, no results found!
                        </h1>
                        <p className="top-5 font-medium text-base">
                          Please check the spelling or try searching for
                          something else .
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-10 flex items-center justify-center">
              <ReactPaginate
                onPageChange={""}
                pageCount="2"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                containerClassName={"pagination"}
                pageLinkClassName={"page-number"}
                previousLinkClassName={"page-number"}
                nextLinkClassName={"page-number"}
                activeLinkClassName={"activeItem"}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductFilterByCategory;
