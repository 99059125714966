import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getDeliveredOrder } from "../../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { IoIosArrowDown } from "react-icons/io";

const DeliveredOrder = () => {
  const dispatch = useDispatch();
  const { delivered, loading } = useSelector((state) => state.allOrders);
  const [toggle, setToggle] = useState(false);

  const HandleIndex = (index) => {
    toggle !== index && setToggle(index);
  };

  useEffect(() => {
    dispatch(getDeliveredOrder());
  }, [dispatch]);

  return (
    <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className=" font-roboto bg-white w-full h-[700px] rounded  overflow-scroll">
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="w-full">
              {delivered &&
                delivered.map((order,index) => {
                  return (
                    <div>
                      {order.orderItems &&
                        order.orderItems.map((item) => {
                          return (
                            <div>
                              <div
                                onClick={() => HandleIndex(index)}
                                className="flex items-center justify-between border p-5 cursor-pointer"
                                key={order._id}
                              >
                                <h1 className="w-10">{index}</h1>
                                <h1 className="w-1/6">{order._id}</h1>
                                <h1
                                  className={`${
                                    order.orderStatus === "Delivered"
                                      ? "text-green"
                                      : ""
                                  } `}
                                >
                                  {order.orderStatus}
                                </h1>
                                <h1 className="w-2/6">{item.name}</h1>
                                <button>
                                  <IoIosArrowDown />{" "}
                                </button>
                              </div>
                              <div>
                                {toggle === index && (
                                  <div>
                                    <div className="w-full p-2">
                                      <h1><span className="font-semibold text-base">Product Id</span> : {item.product}</h1>
                                      <div className="flex items-center justify-between">
                                        <img
                                          className="w-[250px]"
                                          src={item.image}
                                          alt={item.name}
                                        />
                                        <div className="w-4/6">
                                          <h1 className="font-semibold text-base ">
                                            {item.name}
                                          </h1>
                                          <h1 className="font-medium text-base ">
                                            Quantity : {item.quantity}
                                          </h1>
                                          <div className="flex ">
                                            <s className="font-medium text-base opacity-75">
                                              ₹ {item.MRP}
                                            </s>
                                            <h1 className="ml-3 font-medium text-base">
                                              {" "}
                                              ₹ {item.price}
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex">
                                      <div className=" w-5/6 p-2">
                                        <h1 className="font-semibold">
                                          payment Id :{" "}
                                          <span className="font-medium text-base">
                                            {order.paymentInfo.id}
                                          </span>
                                        </h1>
                                        <p className="font-semibold capitalize">
                                          status : 
                                          <span
                                            className={`font-medium text-base ml-2 ${
                                              order.paymentInfo.status ===
                                              "captured"
                                                ? "text-green"
                                                : ""
                                            }`}
                                          >
                                            {order.paymentInfo.status}
                                          </span>
                                        </p>
                                        <p className="font-semibold text-base">
                                          Delivered At :{" "}
                                          <span className="font-medium text-base">
                                            {new Date(
                                              order.deliveredAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="w-5/6 p-2 ">
                                        <h1 className="font-semibold text-base capitalize ">
                                          shipping Address
                                        </h1>
                                        <p>{`${order.shippingInfo.address},${order.shippingInfo.landmark},${order.shippingInfo.locality},${order.shippingInfo.city},${order.shippingInfo.state}, ${order.shippingInfo.pinCode}`}</p>
                                        <p className="font-semibold text-base">
                                          Name :{" "}
                                          <span className="font-medium text-base">
                                            {order.shippingInfo.name}
                                          </span>
                                        </p>
                                        <p className="font-semibold text-base">
                                          Mob :{" "}
                                          <span className="font-medium text-base">
                                            {order.shippingInfo.phoneNo}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveredOrder;
