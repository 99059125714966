import React from "react";
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { clearErrors, orderDetailsById } from '../../actions/orderActions';
import { Rating } from "@mui/material";

const Reviews = ({ order }) => {
  // const orderReview = order.orderStatus === "devliverd"
  const options = {
    size: "large",
    value: order.ratings,
    readOnly: true,
    precision: 0.5,
  };

  return (
    <div>
      <div className="w-5/6 h-auto border-[1.5px] p-5 mt-3 border-gray">
        <h1 className="font-bold text-lg opacity-90">Rate this Product</h1>
        <div className="mt-3 flex items-center mb-3">
          <Rating {...options} className="mr-2" />
          {/* (<h1>{product.numOfReviews} reviews</h1>) */}
        </div>
        <h1 className="font-bold text-lg pt-2 pb-2 opacity-90">
          Review this Product
        </h1>

        <textarea
          name=""
          id=""
          className="w-full h-20 outline-blue border pl-3 pt-2  font-medium text-base placeholder:text-sm"
          cols="20"
          rows="10"
          placeholder="Description"
        ></textarea>

        <button className="font-semibold text-lg bg-orange text-white w-[200px] pt-2 pb-2">
          Submit
        </button>
      </div>
    </div>
  );
};

export default Reviews;
