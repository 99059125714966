import React, { Fragment, useEffect,useState} from "react";
import MetaData from "../../MetaData/MetaData";
// import CheckOutSteps from "../../Cart/ShippingInfo/CheckOutSetps";
// import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { useStripe } from "@stripe/react-stripe-js";
// import { useNavigate } from "react-router-dom";
import { clearErrors } from "../../../actions/orderActions";

const Payment = ({price}) => {

  const dispatch = useDispatch();
  // const orderInfo = sessionStorage.getItem("orderInfo");
  // const orderInfos = JSON.parse(orderInfo);
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  let { isAuthenticated, user } = useSelector((state) => state.user);
  // console.log(cartItems);


  // if(!user && localStorage.getItem('user_info')) {
  //   user = JSON.parse(localStorage.getItem('user_info')).user;
  //   isAuthenticated =  JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  const [razorpayKey, setRazorpayKey] = useState("");
  // console.log(razorpayKey)
  // console.log(cartItems); 
  // console.log(orderInfos)

  // console.log(shippingInfo)

  // console.log(user)
  const { error } = useSelector((state) => state.newOrder);

  async function getRazorpayKey() {
    try {
      const { data } = await axios.get("/api/v1/get-razorpay-key");
      // console.log(data.razorpayKey);
      setRazorpayKey(data.razorpayKey);
    } catch (error) {
      console.log(error);
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorpay = async () => {

    if(cartItems.length ===0){
      alert("please add products")
    }else{
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await axios.post("/api/v1/checkout-order", {
      cartItems,
      shippingInfo,
      user,
      price,
    });  
    // console.log(result.data);

    if (!result) {
      alert("Server error.");
      return;
    }

    // let orderItem = []
    // for(let item of result.data){
    // console.log(item)

    const options = {
      key: { razorpayKey }, // Enter the Key ID generated from the Dashboard
      amount: result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: result.data.currency,
      name: user.name,
      description: "Test Transaction",
      image:
        "https://images.unsplash.com/photo-1573164574511-73c773193279?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      order_id: result.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        console.log("response", response);
        // axios.post(`/api/v1/verification`,response)
        // .then((res)=>console.log(res))
        // .catch(error=>console.log(error))
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: shippingInfo.phoneNo,
      },
      // config: {
      //   display: {
      //     blocks: {
      //       // utib: {
      //       //   //name for Axis block
      //       //   name: "Pay using Axis Bank",
      //       //   instruments: [
      //       //     {
      //       //       method: "card",
      //       //       issuers: ["UTIB"],
      //       //     },
      //       //     {
      //       //       method: "netbanking",
      //       //       banks: ["UTIB"],
      //       //     },
      //       //     {
      //       //       method : "upi"
      //       //     },
                
      //       //   ],
      //       // },
      //       other: {
      //         //  name for other block
      //         name: "Other Payment modes",
      //         instruments: [
      //           {
      //             method: "card",
      //             issuers: ["ICIC"],
      //           },
      //           {
      //             method: "netbanking",
      //           },
      //         ],
      //       },
      //     },
     
      //     hide: [
      //       {
      //         method: "paylater",
      //       },
      //     ],
      //   },
      // },

      notes: {
        address: "Mahadevpura",
      },

      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  };  


  // console.log(order)  

  // const checkOutHandler = async () => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

      
  // if(cartItems.length === 0){
  //   alert("please select product")
  // }else{

  //   await axios
  //     .post("/api/v1/create-checkout-session", {
  //       cartItems,
  //       user,
  //       config,
  //       shippingInfo,
  //     }) 
  //     .then(
  //       (res) => {
  //         console.log(res);
  //         if (res.data.url) {
  //           window.location.href = res.data.url;
  //           // order.paymentInfo = {
  //           //   id: res.payment_intent.id,
  //           //   status: res.payment_status
  //           // }
  //           // dispatch(createOrder(order))
  //         }
  //         //  order.paymentInfo
  //         // console.log(res.config.data);

  //         // order.paymentInfo = {
  //         //   id: res.payment_intent,
  //         //   status: res.status,
  //         // };
  //         // window.location.href = res.data.url;
  //         // dispatch(createOrder(order));
  //       }

  //       // localStorage.removeItem("cartItems");

  //       // window.location = res.data.url
  //       // }
  //     )
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   }
  //   // .then((res) => {
  //   //   console.log(res);
  //   //   window.location = res.data.url;
  //   //   if(res.status === 200){
  //   //     alert("payment successfull")
  //   //     navigate("/process/success")
  //   //   }
  //   // })
  // };

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }

    getRazorpayKey();
  }, [dispatch, error, isAuthenticated]);

  return (
    <Fragment>
      <MetaData title="payment" />
      {/* <CheckOutSteps activeSteps={2} /> 
      <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-light">
        <h1 className="font-bold text-lg">Payment</h1>
        <p className="font-bold text-xl mt-10"> ₹ {orderInfos.subTotal}</p> */}
      <button
        // disabled={!stripe}
        className="w-full capitalize bg-[#1976D2] h-10  text-white font-semibold text-lg"
        onClick={() => {
          displayRazorpay();
        }}
      >
        Procced to payment
      </button>
      {/* <Link to="/success" className='border pl-10 pr-10 pt-1 pb-1 mt-10 bg-blue text-white'>Pay</Link> */}
      {/* </div> */}
    </Fragment>
  );
};

export default Payment;
