export const LOGIN_REQUIEST = "LOGIN_REQUIEST";
export const LOGIN_SUCCESS= "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_USER_REQUIEST = "REGISTER_USER_REQUIEST";
export const REGISTER_USER_SUCCESS= "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const GET_ALL_USER_REQUIEST = "GET_ALL_USER_REQUIEST";
export const GET_ALL_USER_SUCCESS= "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL";

export const LOAD_USER_REQUIEST = "LOAD_USER_REQUIEST";
export const LOAD_USER_SUCCESS= "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

// get userc details by id --admin
export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST"
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS"
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL"

export const USER_ROLE_REQUIEST = "USER_ROLE_REQUIEST";
export const USER_ROLE_SUCCESS= "USER_ROLE_SUCCESS";
export const USER_ROLE_RESET = "USER_ROLE_RESET"
export const USER_ROLE_FAIL = "USER_ROLE_FAIL";

export const DELETE_USER_ROLE_REQUIEST = "DELETE_USER_ROLE_REQUIEST";
export const DELETE_USER_ROLE_SUCCESS= "DELETE_USER_ROLE_SUCCESS";
export const DELETE_USER_ROLE_FAIL = "DELETE_USER_ROLE_FAIL";
export const DELETE_USER_ROLE_RESET = "DELETE_USER_ROLE_RESET"


export const LOG_OUT_SUCCESS= "LOG_OUT_SUCCESS";
export const LOG_OUT_FAIL = "LOG_OUT_FAIL";


export const UPDATE_PROFILE_REQUIEST = "UPDATE_PROFILE_REQUIEST";
export const UPDATE_PROFILE_SUCCESS= "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";


export const UPDATE_PASSWORD_REQUIEST = "UPDATE_PASSWORD_REQUIEST";
export const UPDATE_PASSWORD_SUCCESS= "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";


export const FORGOT_PASSWORD_REQUIEST = "FORGOT_PASSWORD_REQUIEST";
export const FORGOT_PASSWORD_SUCCESS= "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";


export const RESET_PASSWORD_REQUIEST = "RESET_PASSWORD_REQUIEST";
export const RESET_PASSWORD_SUCCESS= "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const GET_WISHLIST_REQUEST = "GET_WISHLIST_REQUEST"
export const GET_WISHLIST_PRODUCT = "GET_WISHLIST_PRODUCT";
export const GET_WISHLIST_FAIL = "GET_WISHLIST_FAIL" 


export const CLEAR_ERROR = "CLEAR_ERROR"