import React, { useEffect, useState } from "react";
import {
  createCategory,
  getAllCategory,
} from "../../../actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import { CLEAR_ERROR } from "../../../constants/categoryConstant";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./Category.css";
import { FaRegEdit } from "react-icons/fa";

const Category = () => {
  const { category, error } = useSelector((state) => state.allCategory);
  //   console.log(category);
  const dispatch = useDispatch();
  const [toggel, setToggle] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [banner, setBanner] = useState("");

  const renderCategory = (category) => {
    if (Array.isArray(category))
      return <>{category.map((e, i) => renderCategory(e))}</>;

    if (typeof category === "object") {
      if (category.name)
        return (
          <li className="">
            <Link
              className="font-semibold text-lg list-disc w-[500px]"
              key={category.name}
              to={`/dashboard/category/${category.slug}/${category._id}`}
            >
              {category.name}
              <button className=" ml-10 bg-green font-medium  text-base pl-5 pr-5 pt-1 pb-1 m-1">
                {" "}
                <span className="flex items-center text-black ">
                  <FaRegEdit size={20} /> Update Category
                </span>
              </button>
              {category.children.length > 0 ? (
                <ul className="font-medium ml-5">
                  {renderCategory(category.children)}
                </ul>
              ) : null}
            </Link>
          </li>
        );
    }
  };

  const createCategoryList = (categories, options = []) => {
    // console.log(categories)
    for (let category in categories) {
      // console.log(categories)
      options.push({
        value: categories[category]?._id,
        name: categories[category]?.name,
      });
      if (categories[category].children?.length > 0) {
        createCategoryList(categories[category].children, options);
      }
    }
    return options;
  };

  const categoryList = createCategoryList(category);
  //   console.log(categoryList);

  const onHandleFile = (e) => {
    const image = e.target.files[0];
    setBanner(image);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!categoryName || !selectCategory) {
      return toast.error("add category name and choose category");
    }

    const myForm = new FormData();
    myForm.append("name", categoryName);
    myForm.append("parentId", selectCategory);
    myForm.append("banner", banner);

    dispatch(createCategory(myForm));
    console.log(myForm);
  };

  useEffect(() => {
    if (error) {
      toast("invalid category");
      dispatch(CLEAR_ERROR());
    }
    dispatch(getAllCategory());
  }, [dispatch, error]);
  return (
    <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className=" font-roboto bg-white w-full h-[700px] rounded  overflow-scroll">
          <div className="font-semibold text-lg p-5 ">
            <h1>ALL Category Lists</h1>
          </div>
          <div className="w-full h-1 bg-black"></div>
          <div className="flex justify-between pt-5 pl-5 pb-20 pr-10 relative">
            <div className="w-auto h-full">
              <ul className="w-full font-normal text-sm category-items">
                {renderCategory(category)}
              </ul>
            </div>
            <div className="">
              <button
                onClick={() => setToggle(true)}
                className="w-[200px] rounded-lg bg-green text-white pt-1 pb-1 font-semibold text-lg capitalize"
              >
                Add category
              </button>

              <div className="absolute  w-full left-0 right-0 top-0 z-10 flex flex-col">
                {toggel ? (
                  <div className="bg-gray w-[600px] h-[400px] flex flex-col space-y-3 p-5">
                    <div className="flex items-center justify-between pb-10">
                      <h1 className="font-semibold text-lg text-white">
                        Add Product Category
                      </h1>
                      <IoClose
                        size={30}
                        color="white"
                        onClick={() => setToggle(false)}
                        className="cursor-pointer"
                      />
                    </div>
                    <form
                      action=""
                      className="flex flex-col space-y-3"
                      onSubmit={onHandleSubmit}
                    >
                      <input
                        value={categoryName}
                        type="text"
                        className="w-full h-10 p-2 rounded"
                        placeholder="Add category name"
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                      <select
                        className="h-10 rounded"
                        value={selectCategory}
                        onChange={(e) => setSelectCategory(e.target.value)}
                      >
                        <option
                          value=""
                          className="font-medium text-base capitalize"
                        >
                          Choose Category
                        </option>
                        {categoryList.map((item, index) => {
                          return (
                            <option
                              className="font-medium text-base capitalize"
                              key={item.value}
                              value={item.value}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className=" flex  ">
                        <input
                          className="h-10 flex justify-center bg-white w-full rounded"
                          type="file"
                          name="banner"
                          accept="image/*"
                          onChange={onHandleFile}
                        />
                      </div>
                      <button
                        type="submit"
                        className="text-white bg-blue pt-2 pb-2  rounded"
                      >
                        Add category
                      </button>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
