// Admin create Category
export const CREATE_CATEGORY_REQUIEST = "CREATE_CATEGORY_REQUIEST"
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL"
export const CREATE_CATEGORY_RESET = "CREATE_CATEGORY_RESET"


// get all category --- admin
export const GET_CATEGORY_REQUIEST = "GET_CATEGORY_REQUIEST"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

// get category by parent id
export const GET_PRODUCT_CATEGORY_BY_PARENT_ID_REQUEST = "GET_PRODUCT_CATEGORY_BY_PARENT_ID_REQUEST"
export const GET_PRODUCT_CATEGORY_BY_PARENT_ID_SUCCESS = "GET_PRODUCT_CATEGORY_BY_PARENT_ID_REQUEST"
export const GET_PRODUCT_CATEGORY_BY_PARENT_ID_FAIL ="GET_PRODUCT_CATEGORY_BY_PARENT_ID_FAIL"

// update category 
export const UPDATE_CATEGORY_REQUIEST =  "UPDATE_CATEGORY_REQUIEST"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

// get category by id
export const GET_CATEGORY_BY_ID_REQUEST = "GET_CATEGORY_BY_ID_REQUEST"
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS"
export const GET_CATEGORY_BY_ID_FAIL = "GET_CATEGORY_BY_ID_FAIL"

 
export const CLEAR_ERROR ="CLEAR_ERROR"

