import {
  LOGIN_REQUIEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUIEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  USER_ROLE_REQUIEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAIL,
  LOAD_USER_REQUIEST,
  LOAD_USER_SUCCESS,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAIL,
  LOAD_USER_FAIL,
  UPDATE_PROFILE_REQUIEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_RESET,
  UPDATE_PASSWORD_REQUIEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_RESET,
  FORGOT_PASSWORD_REQUIEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUIEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLEAR_ERROR,
  GET_ALL_USER_REQUIEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAIL,
  DELETE_USER_ROLE_REQUIEST,
  DELETE_USER_ROLE_FAIL,
  USER_ROLE_RESET,
  DELETE_USER_ROLE_RESET,
  DELETE_USER_ROLE_SUCCESS,
  GET_WISHLIST_PRODUCT,
  GET_WISHLIST_FAIL,
  GET_WISHLIST_REQUEST,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
} from "../constants/userConstant";

export const userReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUIEST:
    case REGISTER_USER_REQUIEST:
    case LOAD_USER_REQUIEST:
      return {
        loading: true,
        isAuthenticated: false,
      };

    case LOGIN_SUCCESS:
    case REGISTER_USER_SUCCESS:
    case LOAD_USER_SUCCESS:
      // const setData = {
        return{
          loading: false,
          isAuthenticated: true,
          user: action.payload,
        }
       
      // };
      // localStorage.setItem("user_info", JSON.stringify(setData));
      // return setData;

    case LOG_OUT_SUCCESS:
      return {
        loading: false,
        user: null,
        isAuthenticated: false,
      };

    case LOGIN_FAIL:
    case REGISTER_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case LOG_OUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAllUser = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USER_REQUIEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case GET_ALL_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// user profile
export const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUIEST:
    case UPDATE_PASSWORD_REQUIEST:
      return {
        ...state,
        loading: true,
      };

    
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

      case UPDATE_PROFILE_SUCCESS:
        return{
          ...state,
          loading: true,
          profileUpdate: action.payload
        }

    case UPDATE_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROFILE_RESET:
    case UPDATE_PASSWORD_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// user forgot password
export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUIEST:
    case RESET_PASSWORD_REQUIEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };
    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// update user role
export const updateUserRole = (state = {}, action) => {
  switch (action.type) {
    case USER_ROLE_REQUIEST:
    case DELETE_USER_ROLE_REQUIEST:
      case GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ROLE_SUCCESS:
      return {
        loading: false,
        ...state,
        isUpdated: action.payload.success,
      };
    case DELETE_USER_ROLE_SUCCESS:
      return {
        loading: false,
        ...state,
        isDeleted: action.payload,
        message: action.payload.message,
      };
      case GET_USER_BY_ID_SUCCESS:
        return{
          loading: false,
        ...state,
        userDetails: action.payload,
        }

    case USER_ROLE_FAIL:
    case DELETE_USER_ROLE_FAIL:
      case GET_USER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_ROLE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case DELETE_USER_ROLE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



// getting wishlist product
export const getWishListProduct = (state={},action) =>{
  switch (action.type) {
    case GET_WISHLIST_REQUEST:
      return{
        ...state,
        loading: true
      }
      case GET_WISHLIST_PRODUCT:
        return{
          ...state,
          loading: false,
          wishlist: action.payload,
          message: action.payload.message
        }
        case GET_WISHLIST_FAIL:
          return{
            ...state,
            loading: false,
            error: action.payload
          }
  
    default:
      return state
  }
}