import React from 'react'
import {AiOutlineCheck} from "react-icons/ai"
import { Link } from 'react-router-dom'


const PaymentSuccess = () => {
  return (
    <div className='font-bold text-xl flex items-center flex-col justify-center w-full h-screen bg-gray-light'>
        <h1>PaymentSuccess</h1>
        <div className='w-[50px] h-[50px] bg-blue rounded-full flex items-center justify-center mt-5'>
        <AiOutlineCheck color='white' size={25} />
        </div>
        <Link to="/orders" className='text-blue underline mt-5'>view orders</Link>
    </div>

  )
}

export default PaymentSuccess