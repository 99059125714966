import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/userActions";
import { Link } from "react-router-dom";
import MetaData from "../MetaData/MetaData";
import Loader from "../Loader/Loader";
// import UserProfile from "./userProfile";
// import UpdateProfile from "../User/UpdateProfile";
import { HiOutlineUser } from "react-icons/hi";
import { TbBorderStyle } from "react-icons/tb";
import { useEffect } from "react";


const Profile = () => {
  let { user, loading } = useSelector((state) => state.user);
  // console.log(user)
  // if(!user && localStorage.getItem('user_info')) {
  //   user = JSON.parse(localStorage.getItem('user_info')).user;
  //   isAuthenticated =  JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  // console.log(isAuthenticated)
  // console.log('test')
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  function logOut() {
    dispatch(logout());
    window.location.assign("/login");
  }

  useEffect(()=>{
  },[])

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={` My Profile`} />
          <div className=" flex flex-col lg:flex-row  space-y-5 lg:space-y-0 justify-around font-roboto mb-5 md:mb-20">
            <div className="w-full flex flex-col items-start  ">
              <div className="flex items-center justify-start bg-white w-full h-20 pl-5 rounded shadow">
                {user?.avatar?.url ? (
                  <img
                    className="w-10 h-10 rounded-full border p-1"
                    src={user?.avatar?.url}
                    alt={user?.name}
                  />
                ) : (
                  <img
                    className="w-10 h-10 rounded-full bg-yellow"
                    src="/user.png"
                    alt={user?.name}
                  />
                )}

                <div className="ml-2 leading-3">
                  <p className="font-normal text-sm opacity-75">Hello,</p>
                  <h1 className="font-medium text-base capitalize">
                    {user?.name}
                  </h1>
                  {/* <p className="font-normal text-sm">{user?.email}</p> */}
                </div>
              </div>
              <div className="w-full bg-white mt-2 p-5 rounded shadow space-y-2">
                <div className="flex items-center justify-start">
                  <HiOutlineUser size={20} />
                  <h1 className="font-medium text-base text-gray ml-2">
                    Account Settings
                  </h1>
                </div>

                <div className="ml-7">
                  <Link to="/user-profile">
                    <h1 className="font-medium text-base mt-2 hover:bg-gray w-full flex flex-start hover:text-white">
                      Profile Information
                    </h1>
                  </Link>
                  <Link to="/wishlist">
                    <button className="font-medium text-base mt-2 hover:bg-gray w-full flex flex-start hover:text-white">
                      My Wishlist
                    </button>
                  </Link>
                </div>

                <div className="flex items-center justify-satrt ">
                  <TbBorderStyle size={20} />
                  <h1 className="font-medium text-base text-gray ml-2">
                    My Orders
                  </h1>
                </div>
                <div className="ml-7">
                  <Link to="/orders">
                    <button className="font-medium text-base mt-2 hover:bg-gray w-full flex flex-start hover:text-white">
                      Order List
                    </button>
                  </Link>
                </div>

                <div className="w-full h-[1px] bg-black"></div>
                <button
                  onClick={logOut}
                  className="font-medium text-sm rounded w-auto h-8 bg-red text-white pl-5 pr-5 capitalize "
                >
                  Log out
                </button>
              </div>
            </div>

         
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Profile;
