// create review 
export const CREATE_REVIEW_REQUIEST = "CREATE_REVIEW_REQUIEST"
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS"
export const CREATE_REVIEW_FAIL = "CREATE_REVIEW_FAIL"


export const GET_ALL_REVIEW_REQUIEST = "GET_ALL_REVIEW_REQUIEST"
export const GET_ALL_REVIEW_SUCCESS = "GET_ALL_REVIEW_SUCCESS"
export const GET_ALL_REVIEW_FAIL = "GET_ALL_REVIEW_SUCCESS"




// GET Review by id
export const GET_REVIEW_BY_ID_REQUEST = "GET_REVIEW_BY_ID_REQUEST";
export const GET_REVIEW_BY_ID_SUCCESS = "GET_REVIEW_BY_ID_SUCCESS";
export const GET_REVIEW_BY_ID_FAIL = "GET_REVIEW_BY_ID_FAIL";

// review verifyed 
export const REVIEW_VERIFY_REQUEST =  "REVIEW_VERIFY_REQUEST"
export const REVIEW_VERIFY_SUCCESS = "REVIEW_VERIFY_SUCCESS"
export const REVIEW_VERIFY_FAIL = "REVIEW_VERIFY_FAIL" 


export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST"
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS"
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL"


export const REVIEW_RESET = "REVIEW_RESET" 