import React from 'react'
import MetaData from '../MetaData/MetaData'

const CanclePolicy = () => {
  return (
    <div>
    <MetaData title={`Return Policy - gshoppi.com`} />
    <div className="pl-2 md:pl-10 pr-2 md:pr-10 pt-10 flex flex-col items-center justify-center font-roboto pb-10 bg-gray-light">
    <div className="flex items-center justify-center mt-5 mb-5">
      <h1 className="font-bold text-2xl ">
        Cancellations & returns policy
      </h1>
    </div>
    <div className="mt-5 w-10/12">
      <h1 className="font-semibold text-base">Effective Date: 01 Sep 2023</h1>
      <p className="font-normal text-base mt-4">
        Thank you for choosing Gshoppi for your online shopping needs! We are
        committed to providing you with a seamless shopping experience. Please
        take a moment to familiarize yourself with our Cancellation and Refund
        Policy, which outlines the terms and conditions for cancellations and
        refunds of products or services purchased through our e-commerce
        website.
      </p>
    </div>

    <div className=" w-10/12">
      <ul className="list-decimal">
        <li className="mt-2 font-semibold text-base">Cancellation Policy</li>

        <ul className="list-decimal ml-4">
          <li className="mt-2 font-semibold text-base">
            Order Cancellation by Customers
          </li>
          <p className="font-normal text-base mt-2">
            Customers have the option to cancel their order for eligible
            products or services within 24 hours of placing the order. To
            request a cancellation, please log in to your Gshoppi account and
            follow the cancellation process. If the cancellation is
            successful, a full refund will be initiated to your original
            payment method.
          </p>
          <li className="mt-2 font-semibold text-base">
            Order Cancellation by Gshoppi
          </li>
          <p className="font-normal text-base mt-2">
            Gshoppi reserves the right to cancel an order under the following
            circumstances:
          </p>
          <p className="font-normal text-base mt-2">
            Unavailability of the product or service
          </p>
          <p className="font-normal text-base mt-2">
            Inaccurate or incomplete order information
          </p>
          <p className="font-normal text-base mt-2">
            Suspicion of fraudulent activity
          </p>
          <p className="font-normal text-base mt-2">
            Failure to meet our terms and conditions
          </p>
          <p className="font-normal text-base mt-2">
            If we cancel your order for any reason, you will receive a full
            refund to your original payment method.
          </p>
        </ul>
        <li className="mt-2 font-semibold text-base">Refund Policy</li>
          <ul className="list-decimal ml-4">
          <li className="mt-2 font-semibold text-base">
          Eligibility for Refunds
        </li>
        <p className="font-normal text-base mt-2">
          Refunds will be processed for eligible orders under the following
          conditions:
        </p>
        <p className="font-normal text-base mt-2">
          Order cancellation within 24 hours (as per Section 1.1)
        </p>
        <p className="font-normal text-base mt-2">
          Damaged or defective products received
        </p>
        <p className="font-normal text-base mt-2">
          Incorrect products received
        </p>
        <p className="font-normal text-base mt-2">
          Non-delivery of products or services within the promised time frame
        </p>
        <p className="font-normal text-base mt-2">
          Refund requests for any other valid reasons as determined by Gshoppi
        </p>
        <li className="mt-2 font-semibold text-base">Refund Process</li>
        <p className="font-normal text-base mt-2">
          To request a refund, please contact our Customer Support team at
          1800 12398 6666 within 7 days of receiving the product or service.
          Provide your order details, a clear explanation of the reason for
          the refund, and any supporting documentation or images if
          applicable.
        </p>
        <p className="font-normal text-base mt-2">
          Once your refund request is approved, we will initiate the refund
          process. Refunds will be processed to the original payment method
          used during the purchase. Please note that it may take 5-10 business
          days for the refund to reflect in your account, depending on your
          payment provider.
        </p>
        <li className="mt-2 font-semibold text-base">Non-Refundable Items</li>
        <p className="font-normal text-base mt-2">The following items are non-refundable:</p>
        <p className="font-normal text-base mt-2">Gift cards and vouchers</p>
        <p className="font-normal text-base mt-2">Downloadable digital products</p>
          </ul>
          <li className="mt-2 font-semibold text-base">Contact Us : </li>
          <p className="font-normal text-base mt-2">If you have any questions or concerns regarding our Cancellation and Refund Policy, please feel free to contact our Customer Support team at 1800 12398 6666. We are here to assist you and ensure your satisfaction with your shopping experience at Gshoppi.</p>
          <p className="font-normal text-base mt-2">Please note that this policy may be subject to periodic updates, so we encourage you to review it regularly. Your continued use of our website constitutes acceptance of any changes made to this policy.</p>
          <p className="mt-7 font-normal text-base">Thank you for choosing Gshoppi for your online shopping needs. We appreciate your trust in us, and we look forward to serving you again soon!</p>
      </ul>
    </div>
  </div>
  </div>
  )
}

export default CanclePolicy