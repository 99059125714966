import React, { Fragment, useEffect } from "react";
import ProductsList from "./ProductCard/ProducstCard";
import { clearErrors, getProduct } from "../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import MetaData from "../MetaData/MetaData";
import Slider from "react-slick";
import "./Home.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getAllCategory } from "../../actions/categoryAction";


const color = [
  "#86B5C5",
  "#AAA098",
  "#5C8C99",
  "#B3895F",
  "#8A8687",
  "#4EBAD7",
];

const Home = () => {
  const dispatch = useDispatch();

  const { loading, products, error } = useSelector((state) => state.products);
  // console.log(products);

  // const {loading:categoryLoading ,category, error: categoryError } = useSelector(
  //   (state) => state.allCategory
  // );
  // console.log(category);
  // console.log(error)
  // const getAllProduct = async()=>{
  //   const datas = await axios.get("http://ec2-13-235-2-21.ap-south-1.compute.amazonaws.com/api/v1/products")
  //   console.log(datas)
  //   setProductItem(datas)
  // }

  // console.log(productItem)

  // if (categoryLoading || loading) {
  //   return <p>Loading...</p>;
  // }

  // if (categoryError || error) {
  //   return <p>Error: {categoryError || error}</p>;
  // }

  // console.log(
  //   document.cookie = "uid= U"  + (Date.now().toString(36).toUpperCase() + "")
  // )

  // Function to get parent category of a product
  // const getParentCategory = (product,categories, parentId) => {
  //   // console.log("product",product)
  //   if (product.category && product.category.parentId === parentId) {
  //     // Access the parent category directly from the categories object based on the parentId
  //     const parentCategory = categories.parentId;
  //     // console.log("parentCategory", parentCategory);

  //     return parentCategory;
  // } else {
  //     return null; // Return null if the product's category parent ID doesn't match parentId
  // }
  // };

  // const onHandleCLick = (id) =>{
  //   console.log(id)
  //   dispatch(getCategoryByParentId(id))
  // }

  // console.log(products);
  var settings = {
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };

  var settingsProduct = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { category } = useSelector((state) => state.allCategory);
  // console.log(category);
  // render category

  const parentIdColorMap = new Map();

  const getBackgroundColor = (id) => {
    // console.log(id);
    if (!parentIdColorMap.has(id)) {
      const currentColor = color[parentIdColorMap.size % color.length];
      parentIdColorMap.set(id, currentColor);
    }
    return parentIdColorMap.get(id);
  };

  const renderCategory = (category) => {
    if (Array.isArray(category))
      return <>{category.map((e, i) => renderCategory(e))}</>;

    if (typeof category === "object") {
      if (category.name)
        return (
          <div
            className="font-semibold text-lg list-disc list-none listItem flex flex-col md:flex-row md:items-center justify-start md:justify-center pb-2 mt-2"
            style={{ backgroundColor: getBackgroundColor(category._id) }}
            key={category.name}
          >
            {category.parentId ? (
              <span className="">{category.name}</span>
            ) : (
              <div className="md:w-[400px] md:h-[370px] ml-4 md:ml-0 overflow-hidden">
                <Link
                  className="cursor-pointer md:absolute  md:left-10 flex flex-col text-white mt-3 text-xl font-medium "
                  to={`/category/${category.slug}/${category._id}`}
                >
                  {category.name}

                  <button className="mt-2 bg-yellow pt-1 pb-1 rounded hover:bg-white hover:text-black w-[100px] font-medium text-base">
                    Shop Now
                  </button>
                </Link>
                <img
                  className="h-full w-full  hidden md:block"
                  src={category?.banner && category?.banner}
                  alt=""
                />
              </div>
            )}
            {/* how to render products here you can check parantId is true then render  */}

            <div
              className="w-full md:w-4/6  p-2 rounded-r"
              style={{ backgroundColor: getBackgroundColor(category._id) }}
            >
              <Slider {...settingsProduct}>
                {products &&
                  products.map((item) => {
                    if (item.category.parentId === category._id) {
                      return <ProductsList product={item} key={item._id} />;
                    }
                    return null;
                  })}
              </Slider>
            </div>
          </div>
        );
    }
  };

  // console.log(document.cookie)

  useEffect(() => {
    if (error) {
      toast("Product not found");
      dispatch(clearErrors());
    }

    dispatch(getAllCategory());
    dispatch(getProduct());
    // getAllProduct()
  }, [dispatch, error]);

  return (
    <Fragment>
      <MetaData title="Gshoppi | Best online shopping in india 2024" />

      <div className="w-full overflow-hidden bg-gray-light">
        <div className="mt-5  w-full">
          <div className="w-full">
            <Slider {...settings}>
              <Link to="/products/search">
                <img
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="w-full h-48 md:h-96  rounded"
                  src={require("../../image/home_banner.jpeg")}
                  alt="banner"
                />
              </Link>
              <Link to="/products/search">
                <img
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="w-full h-48	 md:h-96  rounded"
                  src={require("../../image/ghoppi_banner.jpeg")}
                  alt="banner"
                />
              </Link>
            </Slider>
          </div>
        </div>

        <div className="">
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : category?.length > 0 ? (
            renderCategory(category)
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
