import {
  ADD_SHIPPING_ADDRESS_REQUEST,
  ADD_SHIPPING_ADDRESS_SUCCESS,
  ADD_SHIPPING_ADDRESS_FAIL,
  GET_SHIPPING_ADDRESS_REQUEST,
  GET_SHIPPING_ADDRESS_SUCCESS,
  GET_SHIPPING_ADDRESS_FAIL,
} from "../constants/ShippingAddress.js";

export const shippingReducers = (state = {shippingAddress:[]}, action) => {
  switch (action.type) {
    case ADD_SHIPPING_ADDRESS_REQUEST: 
    case GET_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        shippingAddress: action.payload.shippingAddress,
      };

      case GET_SHIPPING_ADDRESS_SUCCESS:
        return{
            ...state,
            loading: true,
            getALLAddress: action.payload
        }

    case ADD_SHIPPING_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case GET_SHIPPING_ADDRESS_FAIL:
        return{
            ...state,
            loading: false,
            error: action.payload
        }

    default:
      return state
  }
};


