import React, { useEffect, useState } from "react";
import SubAdminSidebar from "../SubAdminSidebar";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { createBlog } from "../../../actions/blogAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const CreateBlog = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const dispatch = useDispatch();

  const [data, setData] = useState("");
  const [Title, setTitle] = useState("");
  const [images, setImage] = useState("");
  const [category, setCategory] = useState("");
//   const [banner, setBanner] = useState("");
//   console.log("banner",banner)
//   console.log("images",images)

  console.log(Title);
  const blogCreate = async (e) => {
    e.preventDefault();

    // const formData = new FormData();
    // formData.set("title", Title);
    // formData.set("content",data)
    // for (let i = 0; i < images.length; i++) {
    //   formData.set("images", images[i]);
    // }
    // for (let i = 0; i < banner.length; i++) {
    //   formData.set("banner", banner[i]);
    // }
    // formData.set("category", category);

    for(let i = 0; i<images.length; i++){
        dispatch(createBlog({
            content : data,
            title: Title,
            images: images[i],
            category: category,

        }));
    
    }
   
    toast.success("blog created successfull.");
  };

  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setData(html);
  }, [editorState]);

  //   useEffect(() => {
  //     let html = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  //     setConvertedContent(html);
  //   }, [editorState]);

  //   console.log(convertedContent);

  return (
    <div className="pl-10 pr-10 pt-10 pb-10 bg-gray-light flex w-full">
      <SubAdminSidebar />
      <div className="ml-5 bg-white rounded pl-5 pr-5 pt-3 w-full">
        <div className="flex flex-col  gap-6">
          <form onSubmit={blogCreate} className="space-y-4">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="Title..."
                type="text"
                name="Title"
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
              />
              <label className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Title
              </label>
            </div>

            {/*     Banner */}
            {/* <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="baner..."
                type="file"
                name="banner" 
                onChange={(e) => setBanner(e.target.files)}
                multiple
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
              />
              <label className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-medium leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Banner
              </label>
            </div> */}

            {/* image */}
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="Image..."
                type="file"
                name="images"
                onChange={(e) => setImage(e.target.files)}
                
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
              />
              <label className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-medium leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                image
              </label>
            </div>

            {/* category */}
            <div className="relative h-11 w-full min-w-[200px]">
              <select
                name="category"
                id=""
                value={category}
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-medium text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option disabled value="">
                  Select your product category
                </option>
                <option className="font-semibold" value="Washing Machine">
                  Washing Machine
                </option>
                <option className="font-semibold" value="Dishwasher">
                  Dishwasher
                </option>
                <option className="font-semibold" value="refrigerator">
                  Refrigerator
                </option>
              </select>
            </div>

            {/* Content */}
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                minHeight: "400px",
              }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </div>

            <button
              type="submit"
              className="w-auto pl-10 pr-10 pt-1 pb-1 uppercase bg-blue text-white mt-10 font-semibold text-lg"
            >
              create Blog
            </button>
          </form>
          <div>
            {/* <textarea
              className="w-full"
              disabled
              value={}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
