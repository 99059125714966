import {
  LOGIN_REQUIEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_ERROR,
  REGISTER_USER_REQUIEST,
  REGISTER_USER_SUCCESS,
  // LOAD_USER_REQUIEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOG_OUT_FAIL,
  LOG_OUT_SUCCESS,
  USER_ROLE_REQUIEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAIL,
  UPDATE_PROFILE_REQUIEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUIEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUIEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUIEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  REGISTER_USER_FAIL,
  GET_ALL_USER_REQUIEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAIL,
  DELETE_USER_ROLE_REQUIEST,
  DELETE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_FAIL,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_FAIL,
  GET_WISHLIST_PRODUCT,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_REQUEST,
  // LOAD_USER_REQUIEST,
} from "../constants/userConstant";
import axios from "axios";

// const apidata = "http://ec2-13-235-2-21.ap-south-1.compute.amazonaws.com


export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUIEST });

    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/v1/login`,
      { email, password },
      config
    );

    
   


    // console.log(data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data.user,
    });

    // console.log(data);
    // localStorage.setItem("token",data.token)
    // document.cookie = `token=${data.token}`;
  } catch (error) {
    // console.log(error)
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};

export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUIEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.post(
      "/api/v1/register",
      userData,
      config
    );
    // console.log(data)
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    // console.log(data);
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// load user

export const loadUser = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOAD_USER_REQUIEST,
    // });

    const config = {  
      withCredentials : true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `/api/v1/me`,
      config
    );
    // console.log("data", data)
    // console.log("Function called")
    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: data.user,
    });
    // console.log(data);

  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.response.data.message });
  }
};


// get details by user id
export const getUserById = (id)=>async(dispatch)=>{
  try {
    dispatch({
      type: GET_USER_BY_ID_REQUEST
    })

    const {data} = await axios.get(`/api/v1/user/${id}`)
    dispatch({
      type: GET_USER_BY_ID_SUCCESS,
      payload: data.user
    })

  } catch (error) {
      dispatch({
        type: GET_USER_BY_ID_FAIL,
        payload: error.response.data.message
      })
  }
}

export const logout = () => async (dispatch) => {
  try {
    await axios.get(
      `/api/v1/logout`
    );
    dispatch({ type: LOG_OUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOG_OUT_FAIL, payload: error.response.data.message });
  }
};

// Admin get all user
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_USER_REQUIEST });

    const { data } = await axios.get(
      "/api/v1/admin/users"
    );
    dispatch({
      type: GET_ALL_USER_SUCCESS,
      payload: data.users,
    });
    // console.log(data)
  } catch (error) {
    dispatch({
      type: GET_ALL_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const profileUpdate = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUIEST });

    const { data } = await axios.put(
      `/api/v1/me/update`,
      userData
    );

    console.log(data)
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.message
    });
  }
};

// update password
export const updatePassword = (password) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUIEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/password/update`,
      password,
      config
    );
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// forgot password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUIEST });
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.post(
      `/api/v1/password/forgot`,
      email,
      config
    );
    console.log(data)

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Reset password
export const resetPassword = (token, password) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUIEST });
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `/api/v1/password/reset/${token}`,
      password,
      config
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update user role --Admin
export const userRoleUpdate = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_REQUIEST });
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `/api/v1/admin/user/${id}`,
      userData,
      config
    );
    dispatch({
      type: USER_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// delete user role --Admin
export const deleteRoleUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_ROLE_REQUIEST });
    const { data } = await axios.put(
      `/api/v1/admin/user/${id}`
    );
    dispatch({
      type: DELETE_USER_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_ROLE_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const getAllWishlist = () =>async(dispatch)=>{
  try {
    dispatch({
      type: GET_WISHLIST_REQUEST
    })

    const {data} = await axios.get("/api/v1/wishlist")
    // console.log(data)
    dispatch({
      type: GET_WISHLIST_PRODUCT,
      payload: data.finduser.wishlist
    })
  } catch (error) {
    dispatch({
      type: GET_WISHLIST_FAIL,
      payload: error.response.data.message
    })
  }
}

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
