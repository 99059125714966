import React, { Fragment, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsFillCartPlusFill } from "react-icons/bs";
import { RiUserFill } from "react-icons/ri";
import Search from "../search/Search";
import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronDown } from "react-icons/fi";
import { logout } from "../../actions/userActions";
import { FiLogOut } from "react-icons/fi";
import { MdBorderBottom } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { BiSolidDashboard } from "react-icons/bi";
import Header from "../Header/Header";
import { FaHeart } from "react-icons/fa6";
import { MdBorderAll } from "react-icons/md";

// import axios from "axios";

const Navbar = () => {
  const [fix, setFixed] = useState(false);
  let { isAuthenticated, user } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);
  const [toggle, setToggle] = useState(false);

  // if (!user && localStorage.getItem("user_info")) {
  //   user = JSON.parse(localStorage.getItem("user_info"))?.user;
  //   // console.log(user)
  //   isAuthenticated = JSON.parse(
  //     localStorage.getItem("user_info")
  //   )?.isAuthenticated;
  // }
  // console.log(isAuthenticated);

  const { wishlist } = useSelector((state) => state.getwishList);

  // console.log(user)
  // console.log(isAuthenticated);
  // console.log(localStorage.getItem("user_info"))

  // console.log(user.role)

  // const getCategory = (data,property) =>{
  //   let newVal = data.map((val)=>{
  //     return val[property]
  //   })
  //   newVal =[...new Set(newVal)]
  //   // console.log(newVal)
  // }

  // const categoryData = getCategory(products,"category")

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fixedNavScoll = () => {
    if (window.scrollY >= 200) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener("scroll", fixedNavScoll);

  const options = [
    { icon: <BsFillPersonFill />, name: "profile", func: account },
    { icon: <FaHeart />, name: "wishlist", fun: wishLists },
    { icon: <MdBorderBottom />, name: "orders", func: order },
    { icon: <FiLogOut />, name: "logout", func: logOut },
  ];

  if (user?.role === "admin") {
    options.unshift({
      icon: <BiSolidDashboard />,
      name: "dashboard",
      func: dashboard,
    });
  }

  if (user?.role === "subadmin") {
    options.unshift({
      icon: <BiSolidDashboard />,
      name: "dashboard-subAdmin",
      func: dashboardSubadmin,
    });
  }

  if (user?.role === "orderdashboard") {
    options.unshift({
      icon: <MdBorderAll />,
      name: "order-dashboard",
      fun: orderDashboard,
    });
  }

  function dashboardSubadmin() {
    navigate("/dashboard-subAdmin");
  }

  function orderDashboard() {
    navigate("/order-dashboard");
  }

  function dashboard() {
    navigate("/dashboard");
  }

  function logOut() {
    dispatch(logout());
    window.location.assign("/login");
  }

  function account() {
    navigate("/profile");
  }
  function wishLists() {
    navigate("/wishlist");
  }

  function order() {
    navigate("/orders");
  }

  return (
    <Fragment>
      <div className="">
        <div className={fix ? "navbar fixed" : "navbar"}>
          <Link to="/" className="">
            <img
              className="w-24 md:w-36"
              src={require("../../image/gshoppi2.png")}
              alt=""
            />
          </Link>

          <Search toggle={toggle} setToggle={setToggle} />

          <div className="flex items-center justify-center  md:space-x-4 icons">
            <Link
              to="/cart"
              className="font-medium text-sm lg:text-base flex items-center justify-center space-x-2 cursor-pointer relative text-white"
            >
              <BsFillCartPlusFill size={20} />
              <h1 className="font-medium text-sm md:text-base">
                Cart
                {cartItems.length > 0 ? (
                  <span className="flex items-center justify-center w-4 md:w-5 h-4 md:h-5 text-sm rounded-full bg-white absolute top-[-13px] right-[-10px] text-black">
                    {cartItems.length}
                  </span>
                ) : (
                  " "
                )}
              </h1>
            </Link>
            <div className=" ml-3 md:ml-0 w-[2px] h-5 bg-white"></div>

            {isAuthenticated ? (
              <div className="dropdown dropdown-hover flex items-center justify-center">
                <div className="flex  rounded p-1">
                  <label
                    tabIndex={0}
                    className="flex items-center capitalize justify-center w-20 h-10 font-medium text-sm md:text-base text-white"
                  >
                    {user?.name?.split(" ")[0]}
                    <span className="ml-2 text-white">
                      <FiChevronDown size={20} fontWeight={900} />
                    </span>
                  </label>
                  {user?.avatar?.url ? (
                    <img
                      className="w-7 hidden md:block md:w-10 h-7 md:h-10 rounded-full border border-gray ml-2"
                      style={{ backgroundSize: "center" }}
                      src={user?.avatar?.url}
                      alt=""
                    />
                  ) : (
                    <img
                      className="w-7 md:w-10 h-7 md:h-10 rounded-full border border-gray ml-2"
                      style={{ backgroundSize: "center" }}
                      src="/user.png"
                      alt=""
                    />
                  )}
                </div>

                <ul
                  tabIndex={0}
                  className="dropdown-content z-20 text-black menu  shadow bg-white rounded-box w-56 absolute right-0 top-10"
                >
                  {options.map((item, index) => {
                    return (
                      <li className="" key={index}>
                        <NavLink
                          className="flex items-center justify-start font-normal text-sm capitalize"
                          to={`${item.name}`}
                          onClick={item.func}
                        >
                          {item.icon} <span className="ml-3">{item.name}</span>
                          {item.name === "wishlist" ? (
                            <h1 className="ml-20">({wishlist?.length})</h1>
                          ) : (
                            ""
                          )}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <Link
                to="/login"
                className="font-medium text-base flex items-center justify-center space-x-1 capitalize  cursor-pointer"
              >
                <div className="flex items-center justify-center">
                  <RiUserFill color="white" size={20} />{" "}
                  <span className="text-white">login</span>
                </div>
              </Link>
            )}
          </div>
        </div>

        <div className=" shadow-xl md:pl-2 md:pr-2">
          <Header />
        </div>

        {/* <div className="mt-2">
          <div className="navbar bg-base-100">
            <div className="navbar-start"></div>
            <div className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal ">
                <li tabIndex={0} className="z-10">
                  <details>
                    <summary className="uppercase font-medium text-base">
                      Washing machines
                    </summary>
                    <ul className="p-2">
                      <li>
                        <Link className="font-medium text-base uppercase">
                          top load
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          front load{" "}
                        </Link>
                      </li>

                      <li>
                        <Link className="font-medium text-base uppercase">
                          Semi Automatic
                        </Link>
                      </li>
                    </ul>
                  </details>
                </li>
                <li tabIndex={0} className="z-10">
                  <details>
                    <summary className="uppercase font-medium text-base">
                      refrigerators
                    </summary>
                    <ul className="p-2">
                      <li>
                        <Link className="font-medium text-base uppercase">
                          single door
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          double door
                        </Link>
                      </li>
                    </ul>
                  </details>
                </li>

                <li tabIndex={0} className="z-10">
                  <details>
                    <summary className="font-medium text-base">
                      SMART TVs
                    </summary>
                    <ul className="p-2">
                      <li>
                        <Link className="font-medium text-base uppercase">
                          43 Inches
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          55 Inches
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          75 Inches
                        </Link>
                      </li>
                    </ul>
                  </details>
                </li>

                <li tabIndex={0} className="z-10">
                  <details>
                    <summary className="uppercase font-medium text-base">
                      air conditioner
                    </summary>
                    <ul className="p-2">
                      <li>
                        <Link className="font-medium text-base uppercase">
                          split Ac 1 ton
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          split Ac 1.5 ton
                        </Link>
                      </li>
                    </ul>
                  </details>
                </li>
                <li className="z-10">
                  <details>
                    <summary className="uppercase font-medium text-base">
                      dishwasher
                    </summary>
                    <ul className="p-2">
                      <li>
                        <Link className="font-medium text-base uppercase">
                          43 Inches
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          55 Inches
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          75 Inches
                        </Link>
                      </li>
                    </ul>
                  </details>
                </li>
                <li tabIndex={0} className="z-10">
                  <details>
                    <summary className="uppercase font-medium text-base">
                      other appliances
                    </summary>
                    <ul className="p-2">
                      <li>
                        <Link className="font-medium text-base uppercase">
                          Fans
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          gyser
                        </Link>
                      </li>

                      <li>
                        <Link className="font-medium text-base uppercase">
                          Iron box
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          gas stove
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          Mixer grinder
                        </Link>
                      </li>

                      <li>
                        <Link className="font-medium text-base uppercase">
                          electric kettles
                        </Link>
                      </li>
                      <li>
                        <Link className="font-medium text-base uppercase">
                          electric Cooker
                        </Link>
                      </li>
                    </ul>
                  </details>
                </li>
              </ul>
            </div>
            <div className="navbar-end"></div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default Navbar;
