const {
  CREATE_CATEGORY_REQUIEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  CREATE_CATEGORY_RESET,
  CLEAR_ERROR,
  GET_CATEGORY_REQUIEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_BY_ID_REQUEST,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_FAIL,
} = require("../constants/categoryConstant");

export const createCategoryReducer = (state = { category: [] }, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_REQUIEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        category: action.payload,
      };
    case CREATE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_CATEGORY_RESET:
      return {
        ...state,
        success: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get all category --- Admin

export const getAllCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CATEGORY_REQUIEST:
    case GET_CATEGORY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
        success: action.payload.success,
      };

    case GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryId: action.payload,
      };
    // case GET_PRODUCT_CATEGORY_BY_PARENT_ID_SUCCESS:
    //   // console.log(action.payload);

    //   return {
    //     ...state,
    //     loading: false,
    //     // category: action.payload,
    //     categories: action.payload,
    //     success: action.payload.success,
    //   };
    case GET_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CATEGORY_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
