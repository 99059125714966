import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { MdOutlineArrowOutward } from "react-icons/md";


const Invoice = () => {
  const [invoice, setInvoice] = useState("");
  const [searchQuery,setSearchQuery] = useState("")
  const [filterInvoice,setFilterInvoice] = useState("")
  // console.log(invoice)

  const InvliceList = async () => {
    const invoiceList = await axios.get("/api/v1/invoice");
    const allInvoiceData = invoiceList.data.invoices;
    const filterData = allInvoiceData.data.filter((item)=>{
     return item.invoice_pdf !== null
       
    })
    // console.log(filterData);
    setInvoice(filterData);
  };  

  useEffect(() => {
    const searchFilter = invoice && invoice?.filter((search)=> {
      // console.log(search)
      return (
        search.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        search?.id.toLowerCase().includes(searchQuery.toLowerCase())
        // search.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
    setFilterInvoice(searchFilter)
    InvliceList();
  }, [searchQuery,invoice]);
  return (
    <div className="antialiased bg-lightgray w-full min-h-screen text-slate-300 relative py-4 ">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className="w-full min-h-full bg-gray-light rounded-lg">
          
          <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900 p-2 rounded">
              {/* <div>
                <button
                  id="dropdownActionButton"
                  data-dropdown-toggle="dropdownAction"
                  className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  type="button"
                >
                  <span className="sr-only">Action button</span>
                  Action
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div
                  id="dropdownAction"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                  <ul
                    className="py-1 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownActionButton"
                  >
                    <li>
                      <Link
                        to="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Reward
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Promote
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Activate account
                      </Link>
                    </li>
                  </ul>
                  <div className="py-1">
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Delete User
                    </Link>
                  </div>
                </div>
              </div> */}
              <h1 className="w-full font-bold ">
            Garuda Mart India Private Limited (Invoices)
          </h1>
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search-users"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for users"
                />
              </div>
            </div>
          <div className="w-full flex flex-wrap mt-2">
            {filterInvoice &&
              filterInvoice?.map((item) => {
                // console.log(item);
                return (
                  <div key={item.id} className="w-[320px] bg-white m-1 rounded p-1">
                    <h1 className="flex font-semibold text-sm">Invoice Id: <span className="font-medium text-sm ml-2">{item.id}</span></h1>
                    <h1 className="font-bold text-sm">{item.customer_name}</h1>
                    <a href={`${item.hosted_invoice_url }`}><button className="bg-green mt-2 pl-3 pr-3 pt-1 pb-1 text-white capitalize flex items-center justify-center rounded">check <span className="ml-3"><MdOutlineArrowOutward /></span></button></a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
