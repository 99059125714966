import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { MdDeleteForever } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllUsers } from "../../actions/userActions";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

const AllUser = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [filterUser, setFilterUser] = useState("");
  const { users, error, loading } = useSelector((state) => state.allUser);

  useEffect(() => {
    dispatch(getAllUsers());
    if (error) {
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (users) {
      const searchData = users.filter((user) => {
        return (
          user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilterUser(searchData);
    }
  }, [users, searchQuery]);

  return (
    <div className="antialiased bg-lightgray w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className="flex flex-col w-full h-[700px] min-h-full overflow-scroll">
          <div className="min-w-full relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className=" flex items-center justify-end pb-4 bg-white dark:bg-gray-900 p-2">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokelinecap="round"
                      strokelinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search-users"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for users"
                />
              </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {/* <th scope="col" className="p-4">
                    <div className="flex items-center">
                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="checkbox-all-search" className="sr-only">checkbox</label>
                    </div>
                </th> */}
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    User_id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Role
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  filterUser &&
                  filterUser.map((user, index) => {
                    return (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        key={index}
                      >
                        {/* <td className="w-4 p-4">
                    <div className="flex items-center">
                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                    </div>
                </td> */}
                        <th
                          scope="row"
                          className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {user?.avatar?.url ? (
                            <img
                              className="w-10 h-10 rounded-full"
                              src={user?.avatar?.url}
                              alt={user.name}
                            />
                          ) : (
                            <img
                              className="w-10 h-10 rounded-full"
                              src="/user.png"
                              alt={user.name}
                            />
                          )}

                          <div className="pl-3">
                            <div className="text-base font-semibold">
                              {user?.name}
                            </div>
                            <div className="font-normal text-gray-500">
                              {user?.email}
                            </div>
                          </div>
                        </th>
                        <td className="px-6 py-4">{user?._id}</td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <div
                              className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2 capitalize"
                              style={{
                                color: user?.role === "admin" ? "red" : "green",
                              }}
                            >
                              {" "}
                              {user?.role}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <Link
                            to={`/dashboard/alluser/${user._id}`}
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-green capitalize"
                          >
                            <BiEdit size={25} />
                          </Link>
                        </td>
                        <td className="px-6 py-4">
                          <Link
                            to="#"
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-red"
                          >
                            <MdDeleteForever size={25} />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUser;
