import React from "react";
import { Fragment } from "react";
import MetaData from "../MetaData/MetaData";

const About = () => {
  return (
    <Fragment>
      <MetaData title="Gshoppi | Best Online shopping deals in india" />
      <div className="pt-20 pl-2 md:pl-20 pr-2 md:pr-20 pb-10 bg-gray-light font-roboto">
        <h1 className="font-bold text-lg">About Us</h1>
        <div className="space-y-3 mt-3 ">
          <p className="font-medium text-base">
            Welcome to{" "}
            <a href="https://gshoppi.com/" className="text-blue">
              Gshoppi.com
            </a>
            , your ultimate destination for all your home appliance needs! We
            are thrilled to introduce ourselves as the latest addition to the
            e-commerce landscape, having recently launched our platform to serve
            you better. Dedicated exclusively to offering a comprehensive
            selection of home appliances from a wide range of reputable brands.
          </p>
          <p className="font-medium text-base">
            At Gshoppi, we recognize the significance of home appliances in
            enhancing your daily life. Whether you're looking to upgrade your
            kitchen, streamline your laundry routine, or elevate your home
            comfort, we're here to provide you with the best-in-class products
            that cater to your needs and preferences.
          </p>
        </div>
        <div className="space-y-3 mt-3">
          <div className="space-y-3">
            <h1 className="font-semibold text-lg">Our mission : -</h1>
            <p className="font-medium text-base">
              To provide our customers with a seamless shopping experience by
              curating an extensive collection of home appliances from leading
              brands across the industry. From well-established names to
              emerging favourites, we're committed to bringing you the highest
              quality products, ensuring that you can shop with confidence and
              convenience. What sets us apart is our dedication to excellence,
              reliability, and customer satisfaction. With a user-friendly
              interface, secure transactions, and prompt delivery, we strive to
              make your shopping experience hassle-free and enjoyable.
            </p>
            <p className="font-medium text-base">
              Looking for the{" "}
              <a
                className="font-semibold text-base"
                href="https://gshoppi.com/"
              >
                best online shopping deals in India 2024
              </a>{" "}
              ?. Look no further! Gshoppi is your go-to dwestination for
              exclusive offers and discounts on a wide range of home appliances.
              Whether it's seasonal promotions, clearance sales, or special
              events, we make sure our customers get the best value for their
              money. Stay tuned for exciting deals that will enhance your
              shopping experience while saving you money.
            </p>
          </div>
          <div>
            <h1 className="font-semibold text-lg">Our promotors : -</h1>
            <p className="font-medium text-base mt-2">
              Gshoppi is proud to have the support and partnership of esteemed
              promoters such as{" "}
              <span className="font-semibold ">
                Garuda Mart India Pvt. Ltd.
              </span>{" "}
              and{" "}
              <span className="font-semibold ">
                Garuda Vega Business Solution Pvt. Ltd.
              </span>{" "}
              With their expertise and backing, we are committed to delivering
              excellence in every aspect of our service, from product selection
              to customer satisfaction.
            </p>
          </div>
          <p className="font-medium text-base">
            Thank you for choosing Gshoppi as your preferred destination for
            home appliances. Welcome to a world of convenience, quality, and
            endless possibilities! Shop with us today and discover why we're the
            <a href="https://gshoppi.com/" className="font-semibold text-base ml-2">best online shopping deals in India .</a>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
