import React from "react";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";

const CreateBrands = () => {
  return (
    <div>
      <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
        <div className=" my-10 px-2 flex space-x-5">
          <div
            id="menu"
            className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
          >
            <Sidebar />
          </div>
          <div className=" font-roboto bg-white w-full h-[700px] rounded  overflow-scroll">
            <div
              id="content"
              className="bg-white col-span-9 rounded-lg p-6 w-full"
            >
              <div id="24h">
                <h1 className="font-bold py-4 uppercase">Brands Dashboard</h1>
                <div
                  id="stats"
                  className="grid gird-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                >
                  <Link
                    to="/dashboard/brand/create"
                    className="bg-black/60 to-white p-6 rounded-lg"
                  >
                    <div className="flex flex-row space-x-4 items-center">
                      <div id="stats-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-10 h-10 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="text-white text-sm font-medium uppercase leading-4">
                          Create Brands
                        </p>
                        <p className="text-white font-bold text-2xl inline-flex items-center space-x-2">
                          <span></span>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                              />
                            </svg>
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="/dashboard/brand/allbrands"
                    className="bg-black/60 to-white p-6 rounded-lg"
                  >
                    <div className="flex flex-row space-x-4 items-center">
                      <div id="stats-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-10 h-10 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="text-white text-sm font-medium uppercase leading-4">
                          All Brands
                        </p>
                        <p className="text-white font-bold text-2xl inline-flex items-center space-x-2">
                          <span></span>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                              />
                            </svg>
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBrands;
