import {
  CREATE_REVIEW_FAIL,
  CREATE_REVIEW_REQUIEST,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  GET_ALL_REVIEW_FAIL,
  GET_ALL_REVIEW_REQUIEST,
  GET_ALL_REVIEW_SUCCESS,
  GET_REVIEW_BY_ID_REQUEST,
  GET_REVIEW_BY_ID_SUCCESS,
  REVIEW_RESET,
  REVIEW_VERIFY_FAIL,
  REVIEW_VERIFY_REQUEST,
  REVIEW_VERIFY_SUCCESS,
} from "../constants/reviewConstant";

export const allreviews = (state = { review: [] }, action) => {
  switch (action.type) {
    case GET_ALL_REVIEW_REQUIEST:
    case CREATE_REVIEW_REQUIEST:
    case GET_REVIEW_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        allreview: action.payload,
      };

    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_REVIEW_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        review: action.payload,
      };

    case GET_ALL_REVIEW_FAIL:
    case CREATE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REVIEW_RESET:
      return {
        ...state,
        success: false,
      };

    default:
      return state;
  }
};

export const reviewVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case REVIEW_VERIFY_REQUEST:
    case DELETE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REVIEW_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        isDelete: action.payload.success,
      };
    case REVIEW_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        isDelete: action.payload,
      };

    default:
      return state;
  }
};
