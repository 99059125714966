import React, { Fragment } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword } from '../../actions/userActions'
import { useEffect } from 'react'
import { clearErrors } from '../../actions/userActions'
import Loader from '../Loader/Loader'
import MetaData from '../MetaData/MetaData'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
    const { error,message,loading} = useSelector((state)=>state.forgotPassword)
    // console.log(message)
    // console.log(error)
   const [email,setEmail]  = useState("")

   const dispatch = useDispatch()

   const onHandleForgotPassword = (e) => {
    e.preventDefault()

    const data = new FormData()
    data.append("email",email)

    dispatch(forgotPassword(data))
   }


   useEffect(()=>{
    if(error){
        toast.error(error)
        dispatch(clearErrors())
    }
    if(message){
        toast(message)
    }
   },[dispatch,error,message])
  return (
    <Fragment>
    {
        loading ? <div className='pt-5 flex items-center justify-center bg-gray-light'><Loader />  </div>: <>
        <MetaData title="update password" />
        <div className="bg-gray-light w-full h-5/6  flex items-center justify-center ">
      <div className="w-full md:w-3/6 h-[400px] mt-10 mb-10 bg-white shadow-2xl text-black relative rounded p-5 ml-2 md:ml-0 mr-2 mr:mr-0">
        <h1 className="text-center font-semibold text-xl opacity-80 mt-10">
          Update your Password
        </h1>
        <div className="w-full h-[1.5px] bg-gray mt-3"></div>
        <div className="flex items-center justify-center z-20">
          <form
            action=""
            onSubmit={onHandleForgotPassword}
            className="w-4/6 mt-4"
          >
            <div className="flex flex-col mt-3 space-y-3">
             
              
              <div className="w-full relative">
              <label
                    htmlFor="email/number"
                    className="font-medium text-base"
                  >
                    email
                  </label>
                <input
                  type="email"
                  name="email"
                  className="border-2 w-full border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <input
                type="submit"
                value="send"
                className=" w-full h-10 bg-yellow hover:bg-yellow-dark rounded capitalize cursor-pointer "
              />
            </div>
          </form>
        </div>
      </div>
    </div>
        </>
    }
    </Fragment>
  )
}

export default ForgotPassword