import axios from "axios"
import {ADD_SHIPPING_ADDRESS_REQUEST,ADD_SHIPPING_ADDRESS_SUCCESS,ADD_SHIPPING_ADDRESS_FAIL, GET_SHIPPING_ADDRESS_REQUEST, GET_SHIPPING_ADDRESS_SUCCESS, GET_SHIPPING_ADDRESS_FAIL} from "../constants/ShippingAddress"

export const AddShippingAddess = (shippingAddress) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_SHIPPING_ADDRESS_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

        const {data} = await axios.post("/api/v1/shipping-address",shippingAddress,config)
        // console.log(data)

        dispatch({
            type: ADD_SHIPPING_ADDRESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ADD_SHIPPING_ADDRESS_FAIL,
            payload: error.response.data.message
        })
    }
}


// get every user shipping address
export const getShippingAddress = () =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_SHIPPING_ADDRESS_REQUEST
        })

        const {data} = await axios.get("/api/v1/usershipping-address")
        // console.log(data)

        dispatch({
            type: GET_SHIPPING_ADDRESS_SUCCESS,
            payload: data.getShippingAdd
        })
    } catch (error) {
        dispatch({
            type: GET_SHIPPING_ADDRESS_FAIL,
            payload: error.response.data.error
        })
    }
}