import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBrand,
  getAllBrands,
  updateBrand,
} from "../../../../actions/BrandAction";
import { FaRegEdit } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader"
import { MdDelete } from "react-icons/md";


const AllBrands = () => {
  const { allbrands, success, isDelete, loading } = useSelector(
    (state) => state.brand
  );
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [brands, setBrands] = useState("");
  const [brandId, setBrandId] = useState("");

  const onHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateBrand(brandId, brands));
  };

  const onHandleDelete = (id) => {
    console.log(id)
    dispatch(deleteBrand(id));
  };

  useEffect(() => {
    dispatch(getAllBrands()); 
    if (success) {
      toast.success(success);
      setToggle(false);
    }

    if (isDelete) {
      toast.success("deleted successfull");
    }
  }, [dispatch, success,isDelete]);
  return (
    <div className="antialiased bg-gray-light w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className=" font-roboto bg-white w-full h-[700px] rounded  overflow-scroll">
          {loading ? (
            <div className="flex items-center justify-center"><Loader /> </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase bg-black dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No .
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Brand Id
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Edit
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allbrands &&
                    allbrands.map((brand, index) => {
                      // console.log(brand)
                      return (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={brand._id}>
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {index}
                          </th>
                          <td className="px-6 py-4">{brand._id}</td>
                          <td className="px-6 py-4">{brand.name}</td>
                          <td
                            className="px-6 py-4 cursor-pointer"
                            onClick={() => {
                              setToggle(!toggle);
                              setBrandId(brand._id);
                            }}
                          >
                            <FaRegEdit size={25} color="green" />
                          </td>

                          <td
                            className="px-6 py-4 cursor-pointer text-red"
                            onClick={()=>onHandleDelete(brand._id)}
                          >
                            <MdDelete size={25}/>
                          </td>
                        </tr>
                      );
                    })}
                  {toggle ? (
                    <div className="absolute top-0 bg-gray-light w-full h-5/6 flex flex-col p-5">
                      <div className="flex items-center justify-between">
                        <h1 className="font-semibold text-base">
                          Update Brand Name
                        </h1>
                        <IoClose
                          size={25}
                          className="cursor-pointer"
                          onClick={() => setToggle(false)}
                        />
                      </div>
                      <form
                        action=""
                        className="flex flex-col space-y-3 w-3/6 mt-5"
                        onSubmit={onHandleSubmit}
                      >
                        <input
                          value={brands}
                          type="text"
                          className="w-full h-10 p-2 rounded border"
                          placeholder="Update Brand Name"
                          onChange={(e) => setBrands(e.target.value)}
                        />
                        <button
                          type="submit"
                          className="text-white bg-blue pt-2 pb-2 rounded"
                        >
                          Update Brand
                        </button>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllBrands;
