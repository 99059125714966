import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useParams,useNavigate } from "react-router-dom";
import { resetPassword } from "../../actions/userActions";
import MetaData from "../MetaData/MetaData";
import Loader from "../Loader/Loader"
import { clearErrors } from "../../actions/userActions";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { error, success, loading } = useSelector((state) => state.forgotPassword);
  const id = useParams()

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onHandleResetPassword = (e) => {
    e.preventDefault();

    const myform = new FormData();

    myform.set("password", password);
    myform.set("confirmPassword", confirmPassword);
    dispatch(resetPassword(id.token,myform));
  };


  useEffect(() => { 
    if (error) {
      toast.error(error)
      dispatch(clearErrors());
    }

    if (success) {
      toast.success("reset password successfull");
      navigate("/login");
    }

  },[dispatch, error, success,navigate]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title="update password" />
          <div className="bg-gray-light w-full h-screen  flex items-center justify-center ">
            <div className="w-3/6 h-5/6 bg-white shadow-2xl text-black relative rounded p-5 ">
              <h1 className="text-center font-semibold text-xl opacity-80 mt-10">
                Reset your Password
              </h1>
              <div className="w-full h-[1.5px] bg-gray mt-3"></div>
              <div className="flex items-center justify-center z-20">
                <form
                  action=""
                  onSubmit={onHandleResetPassword}
                  className="w-4/6 mt-4"
                >
                  <div className="flex flex-col mt-3 space-y-3">
                    
                    <div className="w-full relative">
                      <label
                        htmlFor="password"
                        className="font-medium text-base"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        className="border-2 w-full border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="w-full relative">
                      <label
                        htmlFor="password"
                        className="font-medium text-base"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="border-2 w-full   border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                        value={confirmPassword}
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>

                    <input
                      type="submit"
                      value="update password"
                      className="w-full h-10 bg-yellow hover:bg-yellow-dark rounded capitalize "
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default ResetPassword;
