import React from "react";
import MetaData from "../MetaData/MetaData";

const TermsConditions = () => {
  return (
    <div>
    <MetaData title={`Terms Store online - gshoppi.com`} />
    <div className="flex flex-col items-center justify-center font-roboto pb-10 pt-10 bg-gray-light">
      <h1 className="font-bold text-2xl text-center pt-5 pb-5">
        Terms Conditions
      </h1>
      <div className="pl-8 pr-2 md:pl-0 md:pr-0 md:w-10/12">
        <p className="font-medium text-base">
          Welcome to GShoppi.com, your ultimate destination for electronics
          goods. By accessing and using our website, you agree to comply with
          the following Terms & Conditions. Please read them carefully before
          making any purchases or using our services.
        </p>
        <div className="mt-3">
          <ul className="list-decimal">
            <li className="font-semibold text-base">General Terms : </li>
            <p className="font-medium text-base">
              GShoppi.com is an e-commerce platform that facilitates the sale of
              electronics goods from various brands.
            </p>
            <p className="font-medium text-base">
              All users must be at least 18 years old or have parental consent
              to use the website.
            </p>
            <p className="font-medium text-base">
              By placing an order on GShoppi.com, you confirm that you have
              read, understood, and agreed to these Terms & Conditions.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Product Information :{" "}
            </li>
            <p className="font-medium text-base">
              We strive to provide accurate product descriptions,
              specifications, and images. However, we do not guarantee absolute
              accuracy and reserve the right to make changes without prior
              notice.
            </p>
            <p className="font-medium text-base">
              Colors may appear differently due to monitor settings and lighting
              conditions.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Pricing and Payments :{" "}
            </li>
            <p className="font-medium text-base">
              All prices are in the designated currency and are inclusive of
              applicable taxes and fees.
            </p>
            <p className="font-medium text-base">
              GShoppi.com reserves the right to change prices at any time
              without prior notice.
            </p>
            <p className="font-medium text-base">
              Payment methods accepted are displayed during the checkout
              process. You agree to provide accurate payment information and
              authorize the transaction.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Order Processing and Delivery :{" "}
            </li>
            <p className="font-medium text-base">
              Orders are subject to acceptance and availability. We reserve the
              right to cancel or refuse any order at our discretion.
            </p>
            <p className="font-medium text-base">
              Delivery times may vary based on location and availability of the
              product.
            </p>
            <p className="font-medium text-base">
              GShoppi.com is not responsible for any delays or issues caused by
              third-party delivery services.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Returns and Refunds :{" "}
            </li>
            <p className="font-medium text-base">
              Our Returns Policy governs the process for returning products and
              seeking refunds. Please review the policy on our website.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Intellectual Property :{" "}
            </li>
            <p className="font-medium text-base">
              All content on GShoppi.com, including images, logos, text, and
              graphics, is protected by copyright and other intellectual
              property laws.
            </p>
            <p className="font-medium text-base">
              You may not reproduce, modify, distribute, or use any content
              without our prior written consent.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              User Conduct :{" "}
            </li>
            <p className="font-medium text-base">
              You agree not to use GShoppi.com for any illegal, unauthorized, or
              harmful purposes.
            </p>
            <p className="font-medium text-base">
              You must not interfere with the website's functionality or attempt
              to gain unauthorized access to our systems.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Privacy Policy :{" "}
            </li>
            <p className="font-medium text-base">
              By using our website, you agree to the terms outlined in our
              Privacy Policy. Please review the policy for further details.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Limitation of Liability :{" "}
            </li>
            <p className="font-medium text-base">
              GShoppi.com shall not be liable for any direct, indirect,
              incidental, or consequential damages arising from your use or
              inability to use the website or any products purchased through it.
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Governing Law and Jurisdiction :{" "}
            </li>
            <p className="font-medium text-base">
              These Terms & Conditions shall be governed by and construed in
              accordance with the laws of [Jurisdiction]. Any disputes shall be
              subject to the exclusive jurisdiction of the courts in
              [Jurisdiction].
            </p>
            <li className="font-semibold text-base pb-2 pt-2">
              Modifications to Terms & Conditions :{" "}
            </li>
            <p className="font-medium text-base">
              GShoppi.com reserves the right to modify these Terms & Conditions
              at any time. Any changes will be effective upon posting on the
              website.
            </p>
            <p className="font-medium text-base">
              If you have any questions or concerns regarding these Terms &
              Conditions, please contact our Customer Support team.
            </p>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TermsConditions;
