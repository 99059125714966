import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getProductDetails,
  productWishList,
} from "../../../actions/productActions";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductDetails.css";
import Loader from "../../Loader/Loader";
import { BiPlus } from "react-icons/bi";
import { BiMinus } from "react-icons/bi";
import MetaData from "../../MetaData/MetaData";
import { addToCartItems } from "../../../actions/cartActions";
import { toast } from "react-toastify";

import { BsCart3 } from "react-icons/bs";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { IoIosArrowUp, IoIosArrowDown, IoMdHeart } from "react-icons/io";
import { FaShare } from "react-icons/fa6";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Slider from "react-slick";

// import Slider from "react-slick";

const ProductDetails = () => {
  const [fiximage, setFixedImage] = useState(false);
  const [current, setCurrent] = useState(0);
  // const [productId, setProductId] = useState('')
  const [wishListColor, setWishListColor] = useState(false);
  const [socialIcons, setSocialIcons] = useState(false);
  console.log(wishListColor);
   
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );

  // console.log(product)

  const [imageShow, setImageShow] = useState("");
  const [quentity, setQuentity] = useState(1);

  // discount percentage
  const discountPercentage = product.cutoffprice - product.price;
  const cutoffPercentage = discountPercentage / product.cutoffprice;
  const actuallPercentage = cutoffPercentage * 100;

  // whish list
  const { wishlist, error: wishListError } = useSelector(
    (state) => state.getwishList
  );

  const onHandleToggle = () =>{
    console.log("hello")
  }

  // console.log(wishlist)
  const isWishlistProduct =
    wishlist && wishlist?.some((item) => item._id === product?._id);

  const fixedImageScroll = () => {
    if (window.scrollY >= 200) {
      setFixedImage(true);
    } else {
      setFixedImage(false);
    }
  };

  window.addEventListener("scroll", fixedImageScroll);

  // image hover
  const hoverHandle = (item, index) => {
    setImageShow(item);
    refs.current[index]?.classList.add("active");
    for (var j = 0; j < product.images.length; j++) {
      if (index !== j) {
        refs.current[j]?.classList.remove("active");
      }
    }
  };

  const refs = useRef([]);
  refs.current = [];
  const addRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  // product item increase
  const decreaseItems = () => {
    if (1 >= quentity) return;
    const cartItem = quentity - 1;
    setQuentity(cartItem);
  };
  const increaseItems = () => {
    if (product.stock <= quentity) return;
    const cartItem = quentity + 1;
    setQuentity(cartItem);
  };

  // const options = {
  //   size: "large",
  //   value: product.ratings,
  //   readOnly: true,
  //   precision: 0.5,
  // };

  const addTocart = () => {
    dispatch(addToCartItems(id, quentity));
    toast.success(`item added successfull`);
  };

  //

  // const onHandleBuy = () => {
  //   navigate("/login?redirect=/shipping");
  // };

  // multiple product color

  // var settingsProductColor = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  // scroll bar

  const prev = () => {
    setCurrent((current) =>
      current === 0 ? product.images.length - 1 : current - 1
    );
  };

  const next = () => {
    setCurrent((current) =>
      current === product.images.length - 1 ? 0 : current + 1
    );
  };

  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // wishList Product
  const onHandleClick = (id) => {
    dispatch(productWishList(id));
    setWishListColor(!isWishlistProduct);
    if (!isWishlistProduct) {
      toast.success("Added in wishlist");
    } else {
      toast.success("Remove in wishlist");
    }
  };

  useEffect(() => {
    if (error) {
      // alert("product not found");
      dispatch(clearErrors());
    }

    if (wishListError) {
      dispatch(clearErrors());
    }

    dispatch(getProductDetails(id));
  }, [dispatch, id, error, navigate, wishlist, wishListError]);


  return (
    <Fragment>
      {loading ? (
        <div className="flex items-center justify-center bg-gray-light">
          <Loader />
        </div>
      ) : (
        <>
          <MetaData title={`product's details ${product?.name} `} />
          <div className="pt-10 pb-10  w-full h-full flex flex-col xl:flex-row justify-between bg-gray-light relative font-roboto space-x-2">
            <div className="product_image rounded-lg hidden sm:block">
              <div
                className={
                  fiximage
                    ? "product_img  fixed   md:ml-5 rounded-lg bg-white space-x-3"
                    : "product_img  rounded-lg bg-white space-x-3"
                }
              >
                <div className="flex flex-col space-y-3 overflow-hidden h-[70%]">
                  {product.images?.length < 6 ? (
                    ""
                  ) : (
                    <button
                      onClick={prev}
                      className="bg-white z-[10] flex items-center justify-center border rounded-tl-lg border-opacity-50"
                    >
                      <IoIosArrowUp size={30} />
                    </button>
                  )}
                  {product.images &&
                    product.images.map((item, index) => {
                      const isVideo = item.url.endsWith(".mp4");
                      const isImage =
                        item.url.endsWith(".png") ||
                        item.url.endsWith(".jpg") ||
                        item.url.endsWith(".jpeg") ||
                        item.url.endsWith(".JPG");

                      return (
                        <div
                          key={index}
                          className={
                            index === current ? "img_wrap active" : "img_wrap"
                          }
                          onMouseMove={() => hoverHandle(item, index)}
                          ref={addRefs}
                        >
                          {isVideo ? (
                            <video
                              src={item.url}
                              controls
                              className="object-contain pr-2 hidden sm:block"
                            ></video>
                          ) : isImage ? (
                            <img
                              src={item.url}
                              alt={`media content ${index}`}
                              className=" object-contain pr-2 hidden sm:block"
                            />
                          ) : (
                            <p>Unsupported media type</p>
                          )}
                        </div>
                      );
                    })}

                  {/* <div
                    className="w-[60px] h-[100%]  transition-transform ease-out duration-500 space-y-1"
                    style={{
                      transform: `translateY(-${
                        (current / product.images?.length) * 100
                      }%)`,
                    }}
                  >
                    {product.images &&
                      product.images.map((item, index) => {
                        console.log(typeof item.url);

                        const isVideo = item.url.endsWith(".mp4");
                        const isImage =
                          item.url.endsWith(".png") ||
                          item.url.endsWith(".jpg") ||
                          item.url.endsWith(".jpeg");
                        return (
                          <div
                            key={index}
                            className={
                              index === current ? "img_wrap active" : "img_wrap"
                            }
                            onMouseMove={() => hoverHandle(item, index)}
                            ref={addRefs}
                          >
                            {isVideo ? (
                              <video src={item.url} controls></video>
                            ) : isImage ? (
                              <img
                                src={item.url}
                                alt={`media content ${index}`}
                              />
                            ) : (
                              <p>Unsupported media type</p>
                            )}
                           
                          </div>
                        );
                      })}
                  </div> */}
                  {product.images && product.images?.length < 6 ? (
                    ""
                  ) : (
                    <button
                      onClick={next}
                      className="bg-white z-[20] flex items-center justify-center border rounded-bl-lg"
                    >
                      <IoIosArrowDown size={30} />
                    </button>
                  )}
                </div>

                {/* <InnerImageZoom
                  className="w-[400px] h-[400px] object-contain	pr-2 hidden sm:block"
                  src={
                    imageShow?.url
                      ? imageShow?.url
                      : product?.images && product.images[0]?.url
                  }
                  zoomSrc=""
                  alt={`${product?.name}`}
                /> */}
                {imageShow && imageShow.url ? (
                  imageShow.url.endsWith(".mp4") ? (
                    <video onClick={onHandleToggle}
                      src={imageShow.url}
                      controls
                      className="w-[400px] h-[400px] object-contain pr-2 hidden sm:block"
                      autoPlay
                      controlsList="nodownload"
                    ></video>
                  ) : (
                    <InnerImageZoom
                      className="w-[400px] h-[400px] object-contain pr-2 hidden sm:block"
                      src={
                        imageShow?.url
                          ? imageShow?.url
                          : product?.images && product.images[0]?.url
                      }
                      alt={`${product?.name}`}
                    />
                  )
                ) : (
                  <InnerImageZoom
                    className="w-[400px] h-[400px] object-contain pr-2 hidden sm:block"
                    src={
                      imageShow?.url
                        ? imageShow?.url
                        : product?.images && product.images[0]?.url
                    }
                    alt={`${product?.name}`}
                  />
                )}

                <div
                  className="absolute right-5 top-5 shadow-xl bg-white  shadow-xl rounded-full p-1 cursor-pointer"
                  onClick={() => onHandleClick(product?._id)}
                >
                  <IoMdHeart
                    size={20}
                    style={{ color: isWishlistProduct ? "red" : "gray" }}
                  />
                </div>
              
              </div>
            </div>
            <div className=" rounded-lg block sm:hidden">
              <div className="slider-container w-full">
                <Slider {...settings}>
                  {product?.images &&
                    product?.images.map((item, index) => {
                      return (
                        <div key={index}>
                          <img
                            className=""
                            src={item?.url}
                            alt={`${product?.name}`}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>

              <div
                className="absolute right-5 top-5 shadow-xl bg-white  shadow-xl rounded-full p-1 cursor-pointer"
                onClick={() => onHandleClick(product?._id)}
              >
                <IoMdHeart
                  size={20}
                  style={{ color: isWishlistProduct ? "red" : "gray" }}
                />
              </div>
            </div>

            <div className="w-full xl:w-7/12 mt-10 md:mt-0  rounded-lg md:pr-5 ">
              <div className="rounded">
                <div className="flex items-center justify-between">
                  <div className="w-full">
                    <h1 className="font-semibold text-lg capitalize">
                      {product.name}
                    </h1>
                    <h1 className="font-medium text-yellow w-1/2">
                      Deliverd within <span className="font-bold">72</span>{" "}
                      hours.
                    </h1>
                  </div>
                  <div className="flex flex-col relative">
                    <div
                      className="cursor-pointer hover:text-blue text-gray mr-3"
                      onClick={() => setSocialIcons(true)}
                    >
                      <FaShare size={22} />
                    </div>
                    {socialIcons ? (
                      <div className="absolute top-7 right-0 flex items-center justify-center space-x-2 bg-white h-[100px] w-[200px] pl-5 pr-5 shadow-xl rounded">
                        <FacebookShareButton
                          url={`https://gshoppi.com/product/${product._id}`}
                          title={product.name}
                        >
                          <FacebookIcon size={30} />
                        </FacebookShareButton>

                        <WhatsappShareButton
                          url={`https://gshoppi.com/product/${product._id}`}
                          title={product.name}
                        >
                          <WhatsappIcon size={30} />
                        </WhatsappShareButton>

                        <TwitterShareButton
                          url={`https://gshoppi.com/product/${product._id}`}
                          title={product.name}
                        >
                          <TwitterIcon size={30} />
                        </TwitterShareButton>

                        <EmailShareButton
                          url={`https://gshoppi.com/product/${product._id}`}
                          subject={product.name}
                        >
                          <EmailIcon size={30} />
                        </EmailShareButton>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* <div className="flex flex-col mb-3">
                  <Rating {...options} className="ml-[-5px]" />
                  <div className="flex  items-center mt-2">
                    <h1 className="bg-[#388E3C] text-white pl-1 pr-1 flex items-center rounded pt-[0.5px] pb-[0.5px] mr-2">
                      {product?.ratings?.toFixed(2)}
                      <span className="ml-1">
                        <AiFillStar />
                      </span>
                    </h1>
                    <h1 className="font-medium text-base opacity-75">{`${
                      product.ratings * product.numOfReviews
                    } Ratings`}</h1>
                    <span className="ml-1 mr-1 opacity-75">&</span>
                    <h1 className="font-medium text-base opacity-75">
                      {product.numOfReviews} Reviews
                    </h1>
                  </div>
                </div> */}

                <div className="flex mt-3">
                  <h1 className="font-semibold text-xl">
                    ₹ {product?.price?.toLocaleString()}
                  </h1>
                  <h1 className="font-semibold text-base ml-5 opacity-75">
                    <s> ₹ {product?.cutoffprice?.toLocaleString()}</s>
                  </h1>
                  <div className="ml-5">
                    <h1 className="font-semibold text-lg text-[#388E3C]">
                      {actuallPercentage.toFixed()} % off
                    </h1>
                  </div>
                </div>
                <h1 className="font-medium text-base">
                  Inclusive of all taxes
                </h1>
                <div className="flex items-center mt-5 border border-gray w-[150px] h-8">
                  <button
                    onClick={decreaseItems}
                    className="w-[50px] border border-white h-full flex items-center justify-center bg-gray"
                  >
                    <BiMinus size={20} color="white" />
                  </button>
                  <input
                    className="w-[50px]  h-full text-center"
                    type="number"
                    readOnly
                    value={quentity}
                  />
                  <button
                    onClick={increaseItems}
                    className="w-[50px] border border-white h-full flex items-center justify-center bg-gray"
                  >
                    <BiPlus size={20} color="white" />
                  </button>
                </div>
                <div className="flex space-x-2">
                  <button
                    disabled={product.stock < 1}
                    onClick={addTocart}
                    className="pl-4 pr-4 pt-1 pb-1  h-10 mt-3 rounded bg-[#FF9F00] font-medium text-lg text-white flex items-center justify-center"
                  >
                    <BsCart3 size={25} />
                    <span className="ml-2 ">Add to Cart </span>
                  </button>
                  {/* <button
                  onClick={onHandleBuy}
                  className="w-1/6  h-10 mt-3 rounded bg-[#FB641B] font-normal text-lg text-white"
                >
                  BUY NOW
                </button> */}
                </div>
                <div className="flex items-center mt-3">
                  <p className="mr-2 font-medium text-base capitalize">
                    status :
                  </p>
                  <p
                    className={
                      product.stock < 1
                        ? "text-red"
                        : "text-green font-semibold"
                    }
                  >
                    {product.stock < 1 ? "Out of Stock" : "InStock"}
                  </p>
                </div>
                {/* <div className="flex  mt-10">
                  <h1 className="font-semibold text-base"> {product?.color?.length > 0 ? "Colors" : " "}</h1>
                  <div className="ml-[100px] w-4/6">
                  <Slider {...settingsProductColor}>
                    {product.color &&
                      product.color.map((item, data) => {
                        // console.log(item);
                        return (
                          <div key={item._id}>                          
                            <div className="w-[100px] h-[100px] text-sm flex items-center justify-center">
                              <img className="object-fill w-full h-full "  onMouseMove={() => hoverHandle(item, data)}
                              ref={addRefs} src={item.url && item.url } alt={`${product?.name}`} />
                            </div>
                            </div>

                        );
                      })}
                      </Slider>
                  
                  </div>
                </div> */}
                <div className=" flex mt-5">
                  <h1 className="font-semibold text-base">
                    {product?.highlight?.length > 0 ? "Highlights" : ""}
                  </h1>
                  <ul className="list-disc ml-20">
                    {product?.highlight &&
                      product?.highlight?.map((item) => {
                        return (
                          <div key={item._id}>
                            <li className="font-medium text-base">
                              {item.HighLightName}
                            </li>
                          </div>
                        );
                      })}
                  </ul>
                </div>
                <div className="mt-5 font-normal text-base leading-6 flex  justify-center">
                  <p className="font-semibold text-base w-[200px]">
                    Description
                  </p>
                  <p className="font-medium text-base w-full">
                    {product.description}
                  </p>
                </div>

                <div className="mt-10 border-b rounded-b">
                  <div className="border-t border-l border-r  pl-5 pt-3 pb-3 rounded-t">
                    <h1 className="font-semibold text-2xl">Specifications</h1>
                  </div>
                  <div className="rounded-b">
                    {product?.specifications &&
                      product?.specifications.map((item) => {
                        return (
                          <div
                            key={item._id}
                            className="border-t border-l border-r pl-5 pr-5 pb-5 pt-5"
                          >
                            <h1 className="flex items-center font-semibold text-base">
                              {item.title}
                            </h1>
                            {/* <div className="w-full h-[2px] bg-gray mt-3"></div> */}
                            <div className="mt-2 space-y-3">
                              {item.productDetails.map((val) => {
                                return (
                                  <div
                                    key={val._id}
                                    className="flex justify-center"
                                  >
                                    <p className="flex  font-normal text-base w-[350px] opacity-80 capitalize">
                                      {val.specname}
                                    </p>
                                    {/* <span className="ml-2 mr-10 font-bold text-lg">:</span>  */}
                                    <p className="font-normal text-base w-full">
                                      {val.details}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* <div className="pr-5 pl-5 border mt-2 rounded pt-5 pb-10">
                <div className="flex items-center justify-between">
                  <h1 className="font-semibold text-base text-center capitalize">
                    Ratings & Reviews
                  </h1>
                  <button className="border border-lightgray pl-4 pr-4 pt-2 pb-2 rounded shadow font-medium text-base">
                    Rate Product
                  </button>
                </div>
                <div className="w-full h-[1px] bg-black mt-3 opacity-75"></div> */}
                {/* <div className="">
                  {product?.reviews && product?.reviews.length > 0 ? (
                    <div className="">
                      {product?.reviews.map((review) => {
                          if (review.verified === true) {
                            return <ReviewCard Review={review} key={review._id} />
                          }
                        })}
                    </div>
                  ) : (
                    <p className="font-medium text-lg">No review yet</p>
                  )}
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default ProductDetails;
