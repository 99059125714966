import {
  CREATE_ORDER_REQUIEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  MY_ORDER_REQUIEST,
  MY_ORDER_SUCCESS,
  MY_ORDER_FAIL,
  CLEAR_ERROR,
  ORDER_DETAILS_REQUIEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ALL_ORDERS_REQUIEST,
  ALL_ORDERS_SUCCESS,
  ALL_ORDERS_FAIL,
  UPDATE_ORDERS_FAIL,
  UPDATE_ORDERS_REQUIEST,
  UPDATE_ORDERS_SUCCESS,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_SUCCESS,
  DELIVERY_ORDER_REQUEST,
  DELIVERY_ORDER_SUCCCESS,
  DELIVERY_ORDER_FAIL,
  GET_ORDER_DELIVERED_REQUEST,
  GET_ORDER_DELIVERED_SUCCESS,
  GET_ORDER_DELIVERED_FAIL,
} from "../constants/orderConstant";
import axios from "axios";

export const createOrder = (order) => async (dispatch, getSate) => {
  try {
    dispatch({ type: CREATE_ORDER_REQUIEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/order/new", order, config);

    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: CREATE_ORDER_FAIL, payload: error.response.data.message });
  }
};

export const myOrders = () => async (dispatch) => {
  try {
    dispatch({ type: MY_ORDER_REQUIEST });

    const { data } = await axios.get("/api/v1/orders/me");

    dispatch({
      type: MY_ORDER_SUCCESS,
      payload: data.orders,
    });

    // console.log(data)
    // console.log(data)
  } catch (error) {
    dispatch({ type: MY_ORDER_FAIL, payload: error.response.data.message });
  }
}; 

export const orderDetailsById = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUIEST });

    const { data } = await axios.get(`/api/v1/order/${id}`);
    // console.log(id)

    // console.log(data);
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data.order,
    });

    // console.log(data)
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllOrders = () =>async(dispatch)=>{
  try {
    dispatch({type: ALL_ORDERS_REQUIEST})

    const {data} = await axios.get("/api/v1/admin/orders")
    dispatch({
      type: ALL_ORDERS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ALL_ORDERS_FAIL,
      payload: error.response.data.message
    })
  }
}

// get delivered order --- admin
export const getDeliveredOrder = () => async(dispatch)=>{
  try {
    dispatch({
      type: GET_ORDER_DELIVERED_REQUEST
    })

    const {data} = await axios.get('/api/v1/admin/order/delivered')
    console.log(data)
    dispatch({
      type: GET_ORDER_DELIVERED_SUCCESS,
      payload: data.deliveredOrder
    })
  } catch (error) {
      dispatch({
        type: GET_ORDER_DELIVERED_FAIL,
        payload: error.response.data.message
      })
  }
}

    
// upadte order status
export const upadteOrders = (id,order) =>async(dispatch)=>{
  try {
    dispatch({
      type: UPDATE_ORDERS_REQUIEST
    }) 

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.put(`/api/v1/admin/order/${id}`,order,config )
    // console.log(data)
    dispatch({
      type: UPDATE_ORDERS_SUCCESS,
      payload: data.success
    })
    
  } catch (error) {
    dispatch({
      type: UPDATE_ORDERS_FAIL,
      payload: error.response.data.message
    })
  }
}


export const deleteOrder = (id) =>async(dispatch)=>{
    try {
      dispatch({
        type: DELETE_ORDER_REQUEST
      })

      const {data} = await axios.delete(`/api/v1/admin/order/${id}`)
      // console.log(data)
      dispatch({
        type: DELETE_ORDER_SUCCESS,
        payload: data.success
      })

    } catch (error) {
        dispatch({
          type: DELETE_ORDER_FAIL,
          payload: error.response.data.message
        })
    }
}

export const deliveryOrder = () =>async(dispatch)=>{
  try {
    dispatch({
      type: DELIVERY_ORDER_REQUEST
    })

    const {data} = await axios.get("/api/v1/orderdashboard/orders")

    dispatch({
      type: DELIVERY_ORDER_SUCCCESS,
      payload: data
    })
    
  } catch (error) {
    dispatch({
      type: DELIVERY_ORDER_FAIL,
      payload: error.response.data.message
    })
  }
}


export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
