import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { orderDetailsById, upadteOrders } from "../../../actions/orderActions";
import { toast } from "react-toastify";
import { clearErrors } from "../../../actions/userActions";
import { UPDATE_ORDERS_RESET } from "../../../constants/orderConstant";

const UpdateOrders = () => {
  const [status, setStatus] = useState("");
  const { order } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdate } = useSelector((state) => state.order);
  console.log(order);
  const dispatch = useDispatch();
  const { id } = useParams();
  // console.log(id)

  const onHandleStatus = (e) => {
    e.preventDefault();
    // const myUpdateForm = new FormData();
    // myUpdateForm.append("status", status);
    const currentDate = new Date().toISOString();
    let updatedStatus = status;

    // If updating from "Processing" to "Shipped", capture the shippedAt date
    if (order?.orderStatus === "Processing" && status === "Shipped") {
      updatedStatus = status;
      dispatch(
        upadteOrders(id, { status: updatedStatus, shippedAt: currentDate })
      );
    }
    // If updating from "Shipped" to "Delivered", capture the deliveredAt date
    else if (order?.orderStatus === "Shipped" && status === "Delivered") {
      updatedStatus = status;
      dispatch(
        upadteOrders(id, { status: updatedStatus, deliveredAt: currentDate })
      );
    }
    // If updating from "Shipped" to "Processing" or any other status change, don't capture date
    else {
      dispatch(upadteOrders(id, { status: updatedStatus }));
    }
    // dispatch(upadteOrders(id, {status}));
  };

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdate) {
      toast.success("order Updated successfull");
      dispatch({ type: UPDATE_ORDERS_RESET });
    }

    dispatch(orderDetailsById(id));
  }, [dispatch, id, isUpdate, updateError]);

  return (
    <div className="pt-10 pl-5 pr-5 pb-10 bg-gray-light">
      <h1>Order Id : {order?._id}</h1>
      <div className="h-full border p-3">
        {order?.orderItems &&
          order?.orderItems.map((item) => {
            return (
              <div className="flex  w-full">
                <div className="w-3/6">
                  <h1>Product Id: {item.product}</h1>
                  <img className="w-[150px]" src={item.image} alt={item.name} />
                </div>
                <div className="w-full">
                  <div className="flex">
                    <h1 className="font-semibold text-lg mr-2 w-[200px]">Name</h1>
                    <span className="font-semibold w-[100px]"> : </span>
                    <h1 className="font-semibold text-lg ml-2 full">{item.name}</h1>
                  </div>
                  <div className="flex">
                    <h1 className="font-semibold text-lg mr-2 w-[200px]">Price</h1>
                    <span className="font-semibold w-[100px]"> : </span>
                    <h1 className="font-semibold text-lg ml-2 full">₹{item.price}</h1>
                  </div>
                  <div className="flex">
                    <h1 className="font-semibold text-lg mr-2 w-[200px]">Quantity</h1>
                    <span className="font-semibold w-[100px]"> : </span>
                    <h1 className="font-semibold text-lg ml-2 full">{item.quantity}</h1>
                  </div>

                </div>
              </div>
            );
          })}
      </div>
      <div className="flex justify-end border">
        <h1 className="font-bold text-lg mr-2 w-[200px]">Total paid</h1>
        <span className="font-semibold w-[250px]"> : </span>
        <h1 className="font-bold text-lg ml-2 w-[300px] text-green">₹  {(order?.totalPrice)}
        </h1>
      </div>
      <div className="w-full flex mt-5">
        <div className="w-1/2 p-3">
          <div className="">
            <h1 className="font-bold text-lg">Shipping Address : </h1>
            <div className="flex flex-wrap">
              <h1 className="font-semibold text-sm">
                {order?.shippingInfo?.address}
              </h1>,
              <h1 className="font-semibold text-sm">
                {order?.shippingInfo?.city}
              </h1>,
              <h1 className="font-semibold text-sm">
                {order?.shippingInfo?.pinCode}
              </h1>
            </div>
            <div>
              <h1 className="font-semibold text-base">
                Email : {order?.user?.email}
              </h1>
              <h1 className="font-semibold text-base">
                Name : {order?.user?.name}
              </h1>
              <h1 className="font-semibold text-base">
                Number : {order?.shippingInfo?.phoneNo}
              </h1>
            </div>
          </div>
          <div className="mt-5">
            <h1 className="font-bold text-lg">Process Order : -</h1>
            <form onSubmit={onHandleStatus} className="w-4/6">
              <select
                className="border border-1 h-10 rounded w-full"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value="">Choose Category</option>
                {order?.orderStatus === "Processing" && (
                  <option value="Shipped">Shipped</option>
                )}

                {order?.orderStatus === "Shipped" && (
                  <option value="Delivered">Delivered</option>
                )}
              </select>
              <button
                type="submit"
                className="pl-1 pr-1 pt-2 pb-2 rounded capitalize mt-5 w-full bg-blue text-white"
              >
                update
              </button>
            </form>
          </div>
        </div>

        <div className="w-1/2 border-l p-3">
          <h1 className="font-semibold text-base">
            {" "}
            <span className="w-[200px]">Payment Id</span> :{" "}
            {order?.paymentInfo?.id}
          </h1>
          <h1 className="font-semibold text-base">
            <span className="w-[200px]">Status </span>:{" "}
            {order?.paymentInfo?.status}
          </h1>

          <div className="mt-10">
            <h1 className="font-semibold">Order Status :</h1>
            <h1 className="font-semibold text-lg">
              <span className="w-[200px] font-semibold">Order</span> :{" "}
              <span
                style={{
                  color: order?.orderStatus === "Delivered" ? "green" : "gray",
                }}
              >
                {order?.orderStatus}
              </span>
            </h1>
            <h1 className="font-semibold text-lg">
              <span className="w-[200px] font-semibold">Date</span> :{" "}
              {new Date(order?.deliveredAt).toLocaleDateString()}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrders;
