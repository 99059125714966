import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearErrors, register } from "../../../actions/userActions";
import MetaData from "../../MetaData/MetaData";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone,setPhone] = useState("")
  const [password, setPassword] = useState("");
  const [image, setImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [errors, setError] = useState();

  const { loading, error } = useSelector((state) => state.user);

  const onHandleRegisterSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("password", password);
    myForm.set("image", image);

 

    if (!name || !phone || !email || !password) {
      toast.error("please fill the details");
      if(phone.length >= 10){
        toast("Enter your valid number")
      }
    } else {
      dispatch(register(myForm));
      navigate("/profile");
    }

    console.log(myForm)
  };

  const onHandleChange = (e) => {
    const image = e.target.files[0];
    if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
      setError("select valid image");
      return false;
    } else {
      setImage(image);
      setSelectedFile(image);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (!selectedFile) {
      setSelectedFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [dispatch, error, selectedFile]);

  return (
    <Fragment>
      {loading ? (
        <div className="flex items-center justify-center bg-gray-light">
        <Loader />
        </div>
      ) : (
        <>
          <MetaData title={`register`} />

          <div className="w-full bg-gray-light flex flex-col items-center justify-center pt-10 pb-20 p-2">
            {/* <h1>logo</h1> */}
            <div className="w-full lg:w-2/6 border-2 border-gray rounded-lg p-3 mt-3">
              <h1 className="font-semibold text-2xl capitalize">
                create account
              </h1>
              <form action="" onSubmit={onHandleRegisterSubmit}>
                <div className="flex flex-col mt-3">
                  <label htmlFor="name" className="font-medium text-base flex">
                    Name <span className="text-red ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="border-2 border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="email" className="font-medium text-base mt-1">
                    Email  <span className="text-red ml-1">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="border-2 border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="phone" className="font-medium text-base mt-1">
                    Mobile number <span className="text-red ml-1">*</span>
                  </label>
                  <input
                    type="phone"
                    name="phone"
                    className="border-2 border-black border-opacity-70 mt-1 rounded h-10 pl-2"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <label
                    htmlFor="password"
                    className="font-medium text-base mt-1"
                  >
                    Password <span className="text-red ml-1">*</span>
                  </label>
                  <div className="flex w-full relative">
                    <div className="w-full">
                      <input
                        type={toggle ? "text" : "password"}
                        name="password"
                        className="w-full border-2 border-black border-opacity-70 mt-1 rounded h-10 pl-2 text-sm"
                        placeholder="At least 8 characters"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <p className="font-normal text-sm mt-1">
                        Password must be at least 8 characters.
                      </p>
                    </div>
                    <div className="absolute top-4 right-8 cursor-pointer">
                      {toggle ? (
                        <AiOutlineEye
                          color="black"
                          size={25}
                          onClick={() => setToggle(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={25}
                          onClick={() => setToggle(true)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    {selectedFile ? (
                      <img
                        className="w-10 h-10 rounded-full border"
                        src={preview}
                        alt="preview"
                      />
                    ) : (
                      <img className="w-10" src="/user.png" alt="default" />
                    )}
                    <input
                      className="ml-2"
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={onHandleChange}
                    />
                  </div>
                  <div>{errors && errors}</div>
                  <button
                    type="submit"
                    className="mt-5 w-full h-10 bg-yellow hover:bg-yellow-dark rounded capitalize"
                  >
                    Continue
                  </button>
                </div>
              </form>

              <div className="pt-4">
                <h1>
                  Already have an account?
                  <Link
                    className="text-blue hover:underline hover:text-orange hover:underline-orange"
                    to="/login"
                  >
                    Sign in
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Registration;
