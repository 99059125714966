import {
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_REQUIEST,
  CREATE_ORDER_FAIL,
  MY_ORDER_REQUIEST,
  MY_ORDER_SUCCESS,
  ORDER_DETAILS_REQUIEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  MY_ORDER_FAIL,
  CLEAR_ERROR,
  ALL_ORDERS_REQUIEST,
  ALL_ORDERS_SUCCESS,
  ALL_ORDERS_FAIL,
  UPDATE_ORDERS_REQUIEST,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_ORDERS_FAIL,
  UPDATE_ORDERS_RESET,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_RESET,
  GET_ORDER_DELIVERED_REQUEST,
  GET_ORDER_DELIVERED_SUCCESS,
  GET_ORDER_DELIVERED_FAIL,
} from "../constants/orderConstant";

export const newOrderReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUIEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_ORDER_SUCCESS:
      return {
        loading: false,
        order: action.payload.order,
      };
    case CREATE_ORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const myOrderReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case MY_ORDER_REQUIEST:
      return {
        loading: true,
      };

    case MY_ORDER_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };

    case MY_ORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const orderDetailsReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUIEST:
      return {
        loading: true,
      };

    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };

    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Admin get all orders

export const getAllOrdersReducers = (state = {}, action) => {
  switch (action.type) {
    case ALL_ORDERS_REQUIEST:
      case GET_ORDER_DELIVERED_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        success: action.payload,
      };

      case GET_ORDER_DELIVERED_SUCCESS:
        return{
          ...state,
          loading: false,
          delivered: action.payload
        }

    case ALL_ORDERS_FAIL:
      case GET_ORDER_DELIVERED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// update delete order reducers
export const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDERS_REQUIEST:
    case DELETE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDelete: action.payload,
      };
    case UPDATE_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        isDelete: action.payload,
      };

    case UPDATE_ORDERS_RESET:
      return {
        ...state,
        isUpdate: false,
      };

      case DELETE_ORDER_RESET:
      return {
        ...state,
        isUpdate: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
