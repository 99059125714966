import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myOrders, clearErrors } from "../../actions/orderActions";
import MetaData from "../MetaData/MetaData";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../Loader/Loader";
import { red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import "./Order.css";
import { toast } from "react-toastify";
import { BsCart3 } from "react-icons/bs";
import Profile from "../userProfile/Profile";

const MyOrdersPage = () => {
  const dispatch = useDispatch();
  let { loading, error, orders } = useSelector((state) => state.myOrders);
  console.log(orders)
  // if (!user && localStorage.getItem("user_info")) {
  //   user = JSON.parse(localStorage.getItem("user_info")).user;
  //   isAuthenticated = JSON.parse(
  //     localStorage.getItem("user_info")
  //   ).isAuthenticated;
  // }

  // console.log(isAuthenticated);
  // console.log(orders)

  const columns = [
    { field: "items", headerName: "No.", width: 70, flex: 0.3, border: red },
    { field: "id", headerName: "Order Id", width: 150, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      flex: 0.5,
      getCellClassName: (params) => {
        // console.log(params)
        return params.row.statusColorClass || ''; // Apply the status color class
      },
    },
    {
      field: "OrderDate",
      headerName: "Order Date",
      type: "Date",
      width: 150,
      flex: 0.6,
    },
    {
      field: "itemQnty",
      headerName: "Item Qty",
      type: "Status",
      width: 50,
      flex: 0.5,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "Status",
      width: 50,
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Actions",
      type: "number",
      width: 130,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.id}`}>
            <LuExternalLink size={25} color="#2874F0" />
          </Link>
        );
      },
    },
  ];

  const rows = [];

  orders && orders?.sort((a,b)=>{
    return(
      new Date(b.createAt) -  new Date(a.createAt))
  }).forEach((item, index) => {
  
      rows.push({
        items: index,
        itemQnty: item.orderItems.map((val) => val.quantity),
        id: item._id,
        OrderDate : (new Date(item.createAt).toDateString()),
        status: item.orderStatus,
        amount: (item.totalPrice),
      });
    });

  useEffect(() => {
    if (error) {
      toast.error("some error in my orders");
      dispatch(clearErrors());
    }
    dispatch(myOrders());
  }, [dispatch, error]);

  return (
    <div className='flex flex-col md:flex-row w-full pt-5 pb-20 pl-2 md:pl-5 pr-2 md:pr-5 bg-gray-light'>
    <div className='w-full md:w-2/6 mr-5'>
    <Profile />
    </div>
    <div className="w-full h-screen bg-white shadow rounded mt-5 md:mt-0">
      <MetaData title={`Gshoppi.com - Your orders History`} />
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        
        <div className="w-auto">
          {orders?.length > 0 ? (
            <div style={{ height: "auto", width: "100%",scrollBehavior: "smooth", overflowX:"scroll"}}>
              <DataGrid
                rows={rows}
                columns={columns}
                // disableRowSelectionOnClick
                
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                
                autoHeight
                className="MuiDataGrid-columnHeadersInner"
              />
            </div>
          ) : (
            <div className="h-[400px] flex flex-col items-center justify-center ">
              <BsCart3 size={40} color="blue" />
              <h1 className="font-semibold text-lg mt-3">
                Your order list is Empty.
              </h1>
              <Link to="/">
                <button className="bg-orange  text-white font-medium text-base pl-10 pr-10 pt-2 pb-2 mt-10 flex items-center justify-center rounded transition ease-in-out delay-150 hover:-translate-x-1 hover:scale-110 duration-300 capitalize">
                  go to shope
                </button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
    </div>
  );
};

export default MyOrdersPage;
