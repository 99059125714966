import React, { useEffect, useState } from "react";
import SubAdminSidebar from "../SubAdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlog } from "../../../actions/blogAction";

const Allblogs = () => {
  const dispatch = useDispatch();
  const { Allblog } = useSelector((state) => state.createBlog);
  console.log(Allblog);

  const [title, setTitle] = useState();
  console.log(title)

  useEffect(() => {
    dispatch(getAllBlog());
  }, [dispatch]);

  return (
    <div className="pl-10 pr-10 pt-10 pb-10 bg-gray-light flex w-full">
      <SubAdminSidebar />
      <div className="ml-5 bg-white rounded pl-5 pt-3 w-full">
        {Allblog &&
          Allblog.map((item) => {
            return (
              <div>
                <input className="font-semibold "
                  type="text"
                  value={item.title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Allblogs;
