import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewProduct } from "../../../actions/productActions";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import { getAllCategory } from "../../../actions/categoryAction";
import { getAllBrands } from "../../../actions/BrandAction";
// import SelectProductColor from "./SelectProductColor";

const CreateProduct = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [gst, setGst] = useState("");
  const [stock, setStock] = useState("");
  const [description, setDescription] = useState("");
  const [brands, setBrands] = useState("");
  // console.log("brands", brands);
  const [categoryList, setCategoryList] = useState("");
  const [images, setImage] = useState("");
  const [cutoffprice, setCutOffPrice] = useState("");
  const [highLight, setHighlight] = useState([
    {
      HighLightName: "",
    },
  ]);

  // const [selectedFile, setSelectedFile] = useState("");
  // console.log(selectedFile);

  // const [preview, setPreview] = useState()
  const { allbrands } = useSelector((state) => state.brand);
  console.log(allbrands);

  const { category, error } = useSelector((state) => state.allCategory);

  // console.log(category);

  const [specificationName, setSpecificationName] = useState([
    {
      title: "",
      subItem: [
        {
          specname: "",
          details: "",
        },
      ],
    },
  ]);

  const dispatch = useDispatch();

  const onHandleSubmit = (e) => {
    e.preventDefault();

    try {
      if (
        !name ||
        !price ||
        !gst ||
        !stock ||
        !description ||
        !brands ||
        !images ||
        !cutoffprice ||
        !specificationName
      ) {
        toast.error("Fill all details");
      } else {
        const createProductForm = new FormData();
        createProductForm.append("name", name);
        createProductForm.append("price", price);
        createProductForm.append("gst", gst);
        createProductForm.append("stock", stock);
        createProductForm.append("description", description);
        createProductForm.append("brands", brands);
        createProductForm.append("category", categoryList);
        createProductForm.append("cutoffprice", cutoffprice);

        createProductForm.append("highlight", JSON.stringify(highLight));

        // multiple product color
        // for (let i = 0; i < selectedFile?.length; i++) {
        //   createProductForm.append(`color`, selectedFile[i]);
        // }

        // console.log(selectedFile)
        createProductForm.append(
          "specifications",
          JSON.stringify(specificationName)
        );

        for (let i = 0; i < images?.length; i++) {
          createProductForm.append(`images`, images[i]);
        }

        dispatch(createNewProduct(createProductForm));
        toast.success("product created successfull");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const addedMoreData = () => {
    setSpecificationName([
      ...specificationName,
      {
        title: "",
        subItem: [
          {
            specname: "",
            details: "",
          },
        ],
      },
    ]);
  };

  const onHandleChange = (e, index) => {
    let data = [...specificationName];
    data[index][e.target.name] = e.target.value;
    setSpecificationName(data);
  };

  const onHandleSubItem = (e, index, subIndex) => {
    let origal = specificationName;
    origal[index].subItem[subIndex][e.target.name] = e.target.value;
    setSpecificationName(JSON.parse(JSON.stringify(origal)));
  };

  const removeItems = (index) => {
    const remove = [...specificationName];
    remove.splice(index, 1);
    setSpecificationName(remove);
  };

  const subInputbox = (index) => {
    let origal = specificationName;
    origal[index].subItem = [
      ...origal[index].subItem,
      { specname: "", details: "" },
    ];
    setSpecificationName(JSON.parse(JSON.stringify(origal)));
  };

  // console.log(subItems)

  const subInputRemove = (index, ind) => {
    let origal = specificationName;
    origal[index].subItem.splice(ind, 1);
    setSpecificationName(origal);
  };

  // render category
  const createCategoryList = (categories, options = []) => {
    // console.log(categories)
    for (let category in categories) {
      // console.log(categories)
      options.push({
        value: categories[category]?._id,
        name: categories[category]?.name,
      });
      if (categories[category].children?.length > 0) {
        createCategoryList(categories[category].children, options);
      }
    }
    return options;
  };

  const AllCategoryList = createCategoryList(category);
  // console.log(AllCategoryList);

  // image privew
  // const onSelectFile = (e) => {
  //   const previewImage = [];
  //   const targetFile = e.target.files;
  //   const targetFileObj = [...targetFile];

  //   targetFileObj.map((file) => {
  //     return previewImage.push(URL.createObjectURL(file));
  //   });
  //   setSelectedFile(previewImage);
  // };

  // // delete image
  // const onDeleteImage = (index) => {
  //   selectedFile.splice(selectedFile.indexOf(index), 1);
  //   setSelectedFile([...selectedFile]);
  // };

  // highligt product details
  const addMoreHighLight = () => {
    setHighlight([
      ...highLight,
      {
        HighLightName: "",
      },
    ]);
  };

  const onHandleChangeHighLight = (e, index) => {
    let data = [...highLight];
    data[index][e.target.name] = e.target.value;
    setHighlight(data);
  };

  const removeHighLight = (index) => {
    const remove = [...highLight];
    remove.splice(index, 1);
    setHighlight(remove);
  };

  // useEffect(()=>{
  //   if (!selectedFile) {
  //     setPreview(undefined)
  //     return
  // }

  // const objectUrl = URL.createObjectURL(selectedFile)
  // setPreview(objectUrl)

  // // free memory when ever this component is unmounted
  // return () => URL.revokeObjectURL(objectUrl)
  // },[selectedFile])

  useEffect(() => {
    if (error) {
      toast(error);
    }
    dispatch(getAllCategory());
    dispatch(getAllBrands());
  }, [dispatch, error]);

  return (
    <div>
      <div className="antialiased bg-lightgray  w-full min-h-screen text-slate-300 relative py-4">
        <div className=" my-10 px-2 flex space-x-5">
          <div
            id="menu"
            className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
          >
            <Sidebar />
          </div>
          <div className="w-full h-full">
            <div className="border-gray rounded-lg shadow-2xl bg-white">
              <h1 className="bg-gray p-2 font-semibold text-lg rounded-t-lg text-lightgray">
                Add New Products -
              </h1>
              <form className="form p-2" onSubmit={onHandleSubmit}>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="name"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label
                      for="name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Product Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="number"
                      name="price"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <label
                      for="price"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Price
                    </label>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="number"
                      name="cutoffprice"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={cutoffprice}
                      onChange={(e) => setCutOffPrice(e.target.value)}
                    />
                    <label
                      for="price"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      MRP Price
                    </label>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="number"
                      name="gst"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                    />
                    <label
                      for="gst"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      GST
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="number"
                      name="stock"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                    />
                    <label
                      for="number"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Stock
                    </label>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  {/* <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="category"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                    <label
                      for="category"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Add Category
                    </label>
                  </div> */}
                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="category"
                      value={categoryList}
                      onChange={(e) => setCategoryList(e.target.value)}
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    >
                      <option selected>Add category</option>
                      {AllCategoryList.map((item) => {
                        return (
                          <option
                            className="m-2 capitalize "
                            key={item.name}
                            value={item.value}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/*Brands Name  */}

                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="brands"
                      id=""
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      value={brands}
                      onChange={(e) => setBrands(e.target.value)}
                    >
                      <option value="">Select your Brand</option>
                      {allbrands &&
                        allbrands.map((brand) => {
                          // console.log(brand._id)
                          return (
                            <option value={brand._id} className="uppercase">
                              {brand.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {/* <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="brands"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    >
                      <option selected>Select brands</option>
                      <option value="Gplus">Gplus</option>
                      <option value="Vyom">VYOM</option>
                      <option value="Samsung">Samsung</option>
                      <option value="whirlpool">Whirlpool</option>
                      <option value="Haier">Haier</option>
                      <option value="Crompton">Crompton</option>
                      <option value="TTK Prestige">TTK Prestige</option>
                      <option value="Butterfly">Butterfly</option>
                    </select>
                  </div> */}
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  {/* product image */}
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="file"
                      // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="images"
                      id="images"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      onChange={(e) => setImage(e.target.files)}
                      multiple
                    />
                  </div>

                  {/* multiple product colors */}
                  {/* 
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="file"
                      // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="color"
                      id="color"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="multi"
                      onChange={(e)=>setSelectedFile(e.target.files)}
                      multiple
                    />
                    {/* <div className="flex mt-2">
                      {selectedFile &&
                        selectedFile.map((item, index) => {
                          return (
                            <div
                              className=""
                              onClick={() => onDeleteImage(item, index)}
                            >
                              <img className="w-[50px]" src={item} alt="" />
                            </div>
                          );
                        })}
                    </div> */}
                  {/* <SelectProductColor
                      onSelectFile={onSelectFile}
                      selectedFile={selectedFile}
                      onDeleteImage={onDeleteImage}
                    /> */}
                  {/* </div>  */}
                </div>

                {/* high light input box */}
                <div className="">
                  {highLight.map((inputbox, index) => {
                    return (
                      <div className="flex">
                        <div className="relative z-0 w-3/6 mb-6 group">
                          <input
                            type="text"
                            name="HighLightName"
                            id="HighLightName"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=""
                            value={inputbox.HighLightName}
                            onChange={(e) => onHandleChangeHighLight(e, index)}
                          />
                          <label
                            for="heading"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            High light product details
                          </label>
                        </div>
                        <div className="flex items-center ml-3">
                          {highLight.length !== 1 && (
                            <button
                              className="pl-3 pr-3 pt-1 pb-1 bg-red text-white "
                              onClick={() => removeHighLight(index)}
                            >
                              remove
                            </button>
                          )}
                          {highLight.length - 1 === index && (
                            <button
                              className="pl-3 pr-3 pt-1 pb-1 bg-blue text-white "
                              onClick={addMoreHighLight}
                            >
                              Add More
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="relative z-0 w-full mb-6 group">
                  <textarea
                    type="text"
                    name="description"
                    id="floating_company"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <label
                    for="description"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Descripton
                  </label>
                </div>

                {/* spacification */}
                <div>
                  {specificationName.map((item, index) => (
                    <div className="mb-5 border p-2">
                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=""
                            value={item.title}
                            onChange={(e) => onHandleChange(e, index)}
                          />
                          <label
                            for="heading"
                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Specification Heading
                          </label>
                        </div>
                      </div>
                      {/* sub input box */}
                      <div
                        className="mt-2 flex flex-col w-full"
                        key={item.subItem.length}
                      >
                        {item.subItem.map((subItem, ind) => (
                          <div className="flex">
                            <div className="grid md:grid-cols-2 md:gap-6 w-full ">
                              <div className="relative z-0 w-full mb-6 group">
                                <input
                                  type="text"
                                  name="specname"
                                  id="specname"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                  placeholder=""
                                  value={subItem.specname}
                                  onChange={(e) => {
                                    onHandleSubItem(e, index, ind);
                                  }}
                                />
                                <label
                                  for="specificationName"
                                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                  Specification Name
                                </label>
                              </div>
                              <div className="relative z-0 w-full mb-6 group">
                                <input
                                  type="text"
                                  name="details"
                                  id="details"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                  placeholder=""
                                  value={subItem.details}
                                  onChange={(e) => {
                                    onHandleSubItem(e, index, ind);
                                  }}
                                />
                                <label
                                  for="details"
                                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                  Specification details
                                </label>
                              </div>
                            </div>
                            <div className="">
                              {item.subItem.length !== 1 && (
                                <button
                                  className="bg-red  pb-3 pt-3 pl-2 pr-2 text-white"
                                  onClick={() => subInputRemove(index, ind)}
                                >
                                  Remove
                                </button>
                              )}
                              {item.subItem.length - 1 === ind && (
                                <button
                                  className="bg-blue pb-3 pt-3 pl-2 pr-2 text-white"
                                  onClick={() => subInputbox(index)}
                                >
                                  Add More
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      {specificationName.length !== 1 && (
                        <button
                          className="bg-red pl-10 pr-10 pb-3 pt-3 text-white"
                          onClick={() => removeItems(index)}
                        >
                          Remove
                        </button>
                      )}
                      {specificationName.length - 1 === index && (
                        <button
                          className="bg-blue pl-10 pr-10 pb-3 pt-3 text-white"
                          onClick={addedMoreData}
                        >
                          Add More
                        </button>
                      )}
                    </div>
                  ))}
                </div>

                <button
                  type="submit"
                  className="text-white mt-5 bg-blue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-10 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
