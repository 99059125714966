import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlog } from "../../actions/blogAction";

const Blog = () => {
  const dispatch = useDispatch();
  const { Allblog } = useSelector((state) => state.createBlog);
  console.log(Allblog);

  const showMoreText = (item) => {
    if (item?.length <= 60) return item;

    if (item?.length > 60) {
      return (
        <>
          <p>{item.slice(0, 60)} ...</p>
        </>
      );
    }
  };

  useEffect(() => {
    dispatch(getAllBlog());
  }, [dispatch]);

  return (
    <div className="pl-2 pr-2 pt-10 pb-10 bg-gray-light font-roboto ">
      <div className="w-full h-[500px] overflow-hidden">
        <img
          className="w-full h-full"
          src="https://images.unsplash.com/photo-1595828744395-b09e9a301a9d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="blog"
        />
      </div>
      <div className="flex flex-wrap mt-10">
        {Allblog &&
          Allblog.map((item) => {
            return (
              <div className="space-y-3 flex flex-col w-2/6 p-5">
                <div className="w-full mt-3">
                  <img
                    className="w-auto h-[300px]"
                    src={item?.images[0] && item.images[0]?.url}
                    alt="blog"
                  />
                </div>
                <h1 className="font-semibold text-lg capitalize">
                  {item?.title}
                </h1>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Magni necessitatibus vero facilis accusamus dolor provident
                  totam incidunt illo expedita architecto...
                </p>
                <p className="font-medium text-base w-full">
                  {showMoreText(item?.description)}
                </p>
                {/* <div>
                {
                  item.content.map((con)=>{
                    return(
                      <div dangerouslySetInnerHTML={{ __html: con }} />
                    )
                  })
                }
              </div> */}
                <Link
                  to={`/blogs/${item.title}/${item._id}`}
                  className="bg-[#007BC0] text-lg text-white pl-5 pr-5 pt-2 pb-2 w-1/2"
                >
                  Read More
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Blog;
